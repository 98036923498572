/* eslint-disable no-bitwise */
import { Avatar } from '@mui/material'

type AccountAvatarProps = { name: string }
const AccountAvatar = (props: AccountAvatarProps) => {
  const stringToColor = (string: string) => {
    let hash = 0

    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }

    return color
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
    }
  }

  return <Avatar {...stringAvatar(props.name)} />
}

export default AccountAvatar
