import { useContext } from 'react'
import { SubmitHandler } from 'react-hook-form'

import {
  AlertContext,
  ErrorAlert,
  SuccessAlert,
} from '../../../Components/Alert/AlertProvider'
import { putFacilityInfo } from '../Repository/FacilityRepository'
import { FacilityInfo } from '../Types/FacilityManagementTypes'

const usePutFacility = (closeFunction: VoidFunction) => {
  const { state, dispatch } = useContext(AlertContext)

  const onSubmit: SubmitHandler<FacilityInfo> = (facilityInfo: FacilityInfo) => {
    dispatch({ type: 'CLOSE' })

    putFacilityInfo(facilityInfo)
      .then((response) => {
        SuccessAlert(dispatch, response)
        closeFunction()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
  }
  return onSubmit
}

export default usePutFacility
