import { API } from 'aws-amplify'

import { Step } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import { UserInfo } from '../../UserManagement/Types/UserManagementTypes'
import {
  InputAeonQuestionnaire,
  InputPreQuestionnaire,
  InputQuestionnaire,
} from '../Types/QuestionnaireTypes'

export const setAgreementFlag = async (userInfo: UserInfo): Promise<UserInfo> => {
  const apiName = 'Staff'
  const path = '/setAgreementFlag'
  const myInit = {
    body: { userId: userInfo.userId, agreementFlag: true },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: UserInfo) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const incrementActive = async (userInfo: UserInfo): Promise<UserInfo> => {
  const apiName = 'Staff'
  const path = '/incrementActive'
  const myInit = {
    body: { userInfo },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: UserInfo) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const putPreQuestionnaire = async (
  preQuestionnaire: InputPreQuestionnaire
): Promise<InputPreQuestionnaire> => {
  const apiName = 'Staff'
  const path = '/putPreQuestionnaire'
  const myInit = {
    body: { preQuestionnaire },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: InputPreQuestionnaire) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const putAeonQuestionnaire = async (
  postQuestionnaire: InputAeonQuestionnaire
): Promise<InputAeonQuestionnaire> => {
  const apiName = 'Staff'
  const path = '/putPostQuestionnaire'
  const myInit = {
    body: { postQuestionnaire },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: InputAeonQuestionnaire) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const putPostQuestionnaire = async (
  postQuestionnaire: InputQuestionnaire
): Promise<InputQuestionnaire> => {
  const apiName = 'Staff'
  const path = '/putPostQuestionnaire'
  const myInit = {
    body: { postQuestionnaire },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: InputQuestionnaire) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getStepByUserId = async (userId: string): Promise<Step> => {
  const apiName = 'Staff'
  const path = '/getStepByUserId'
  const myInit = {
    body: { userId },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: Step) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
