import { useAuthenticator } from '@aws-amplify/ui-react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ConfirmDialog from '../../Common/ConfirmDialog'

import StaffInfoDialog from './StaffInfoDialog'
import useActiveUserList from './useActiveuserList'

type AccountMenuProps = {
  onClose: VoidFunction
  anchorEl: Element | null
}

const AccountMenu = (props: AccountMenuProps) => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const navigate = useNavigate()
  const handleSignOut = () => {
    signOut()
    toggleDialog()
    props.onClose()

    navigate('/')
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  const toggleDialog = () => setDialogOpen(!dialogOpen)

  const [infoDialogOpen, setInfoDialogOpen] = useState(false)
  const toggleInfoDialog = () => setInfoDialogOpen(!infoDialogOpen)

  useActiveUserList()

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(props.anchorEl)}
        onClose={props.onClose}
      >
        <MenuItem onClick={toggleInfoDialog}>
          <PersonOutlineIcon sx={{ mr: 1 }} />
          {user.attributes?.email}
        </MenuItem>

        <MenuItem onClick={toggleDialog}>
          <ExitToAppIcon sx={{ mr: 1 }} />
          サインアウト
        </MenuItem>
      </Menu>

      <StaffInfoDialog open={infoDialogOpen} onClose={toggleInfoDialog} />
      <ConfirmDialog
        isOpen={dialogOpen}
        onSubmit={handleSignOut}
        onClose={toggleDialog}
        title="確認"
        text="サインアウトしますか？"
        isLoading={false}
      />
    </>
  )
}

export default AccountMenu
