import { useState, useContext } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { getDateString } from '../../../Common/TimestampConverter'
import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import { searchUserList } from '../Repository/UserDataRepository'
import { UserInfo, SearchUserInputs } from '../Types/UserManagementTypes'

export type SearchUserInfo = UserInfo & { mobilityName: string }
const useSearchUserResult = () => {
  const [searchResult, setSearchResult] = useState<SearchUserInfo[]>([])
  const { dispatch } = useContext(AlertContext)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<SearchUserInputs> = (data: SearchUserInputs) => {
    setIsLoading(true)
    searchUserList(
      data.facilityId,
      data.active,
      getDateString(data.startDay || new Date()),
      getDateString(data.endDay || new Date())
    )
      .then((response: SearchUserInfo[]) => {
        setSearchResult(response)
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return [searchResult, onSubmit, isLoading] as const
}

export default useSearchUserResult
