import { Stack } from '@mui/material'
import { useMemo, useReducer, useState } from 'react'

import AppAlert from '../../Components/Alert/AppAlert'
import AddFab from '../../Components/Common/AddFab'
import PageTitle from '../../Components/Common/PageTitle'
import { AppLogger } from '../../Logger/AppLogger'

import AddStaffDialog from './Components/AddStaffDialog'
import SearchStaffCard from './Components/SearchStaffCard'
import SearchStaffResult from './Components/SearchStaffResult'
import { initialSearchStaffState, SearchStaffContext } from './Context/StaffSearchContext'
import { SearchStaffReducer } from './Reducer/SearchStaffReducer'

const StaffManagement = () => {
  AppLogger.debug('render: StaffManagement page.')

  const [dialogOpen, setDialogOpen] = useState(false)
  const toggleDialog = () => setDialogOpen(!dialogOpen)

  const [searchStaffState, searchStaffDispatch] = useReducer(
    SearchStaffReducer,
    initialSearchStaffState
  )
  const value = useMemo(
    () => ({
      searchStaffState,
      searchStaffDispatch,
    }),
    [searchStaffState]
  )

  return (
    <div className="StaffManagement">
      <SearchStaffContext.Provider value={value}>
        <PageTitle title="担当者管理画面" />
        <Stack spacing={2}>
          <AppAlert />
          <SearchStaffCard />
          <SearchStaffResult />
        </Stack>
        <AddFab onclick={toggleDialog} />
        <AddStaffDialog isOpen={dialogOpen} onClose={toggleDialog} />
      </SearchStaffContext.Provider>
    </div>
  )
}

export default StaffManagement
