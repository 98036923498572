import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { AlertContext, ErrorAlert } from '../../Components/Alert/AlertProvider'
import BodyContainer from '../../Components/BodyContainer'
import PageTitle from '../../Components/Common/PageTitle'
import SubmitButton from '../../Components/Common/SubmitButton'
import { AppLogger } from '../../Logger/AppLogger'

import {
  incrementActive,
  putAeonQuestionnaire,
} from './Repository/QuestionnaireRepository'
import { InputAeonQuestionnaire, QuestionnaireProps } from './Types/QuestionnaireTypes'

const AeonQuestionnaire = (props: QuestionnaireProps) => {
  const initValue = {
    userId: props.userInfo.userId,
    q0_1: '',
    q0_2: '',
    q0_3: '',
    q0_4: '',
    q1_1: '',
    q1_2: '',
    q2: '',
    q3: '',
    q4: '',
    q5: '',
    q6_1: '',
    q6_2: '',
    q7_1: '',
    q7_2: '',
    q8: '',
    q9: '',
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
  } = useForm<InputAeonQuestionnaire>({
    defaultValues: initValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const handleCheck = (
    option: string,
    id: 'q1_1' | 'q7_1',

    event: React.SyntheticEvent
  ) => {
    let values: string[] = getValues(id).split(',')
    values = Array.from(values).filter((v) => v)

    let newValues: string[] = []
    if ((event.target as HTMLInputElement).checked) {
      newValues = [...(values ?? []), option]
      newValues.sort()
    } else {
      newValues = values.filter((v) => v !== option)
    }
    setValue(id, newValues.join(','))

    return newValues.join(',')
  }

  const handleCheckNotApplicable = (
    option: string,
    id: 'q0_3',
    event: React.SyntheticEvent,
    notApplicableValue: string
  ) => {
    // 該当idのvalueから文字列を生成
    let values: string[] = getValues(id).split(',')

    // 配列valuesから空文字の削除
    values = Array.from(values).filter((v) => v)

    let newValues: string[] = []

    // 該当なしが押されたかチェック
    if (option === notApplicableValue) {
      if (!values.includes(option)) {
        newValues = [option]
      } else {
        newValues = []
      }
      // 該当なしが押されていない場合
      // 該当なしがvalueに含まれているかチェック
    } else if (!values.includes(notApplicableValue)) {
      // valueにチェックしたoptionが含まれていない場合、newValuesに追加する
      if (!values.includes(option)) {
        newValues = [...(values ?? []), option]
        newValues.sort()
      } else {
        // 含まれていた場合は、newValuesから削除する
        newValues = values.filter((v) => v !== option)
      }
    } else {
      // 該当なしが含まれている場合
      newValues = [option]
    }

    // 該当idのvalueにnewValueをセットする
    setValue(id, newValues.join(','))

    AppLogger.debug(newValues)

    return newValues.join(',')
  }

  const { dispatch } = useContext(AlertContext)

  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<InputAeonQuestionnaire> = (
    inputs: InputAeonQuestionnaire
  ) => {
    setIsLoading(true)
    AppLogger.debug(inputs)
    putAeonQuestionnaire(inputs)
      .then((response) => {
        incrementActive(props.userInfo)
          .then(() => {
            AppLogger.debug('complete pre questionnaire.')
          })
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
          })
          .finally(() => {
            setIsLoading(false)
            props.onClose()
          })
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        props.onClose()
      })
  }

  return (
    <div className="PreQuestionnaire">
      <BodyContainer>
        <Card
          sx={{
            paddingTop: '3vh',
            paddingBottom: '3vh',
            paddingLeft: '3vw',
            paddingRight: '3vw',
            marginTop: '3vh',
          }}
        >
          <PageTitle title="アンケート" />
          <Stack spacing={5}>
            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q0_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  年齢
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q0_1?.message}
                </FormHelperText>
                <Controller
                  name="q0_1"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>〜20代</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>30代</Typography>}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>40代</Typography>}
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>50代</Typography>}
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>60代</Typography>}
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>70代</Typography>}
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>80代〜</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              <FormControl required error={'q0_2' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    marginTop: '5vh',
                  }}
                >
                  性別
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q0_2?.message}
                </FormHelperText>
                <Controller
                  name="q0_2"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>男性</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>女性</Typography>}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>回答しない</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              <FormControl required error={'q0_3' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    marginTop: '5vh',
                  }}
                >
                  お身体のうち、何処か調子の優れない場所はありますか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q0_3?.message}
                </FormHelperText>
                <FormGroup row>
                  <Controller
                    name="q0_3"
                    control={control}
                    rules={{
                      required: '必須項目です。',
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={<Typography sx={{ fontSize: '1.2rem' }}>腕</Typography>}
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('0', 'q0_3', event, '6')
                            )
                          }
                          checked={getValues('q0_3').split(',').includes('0')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={<Typography sx={{ fontSize: '1.2rem' }}>肘</Typography>}
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('1', 'q0_3', event, '6')
                            )
                          }
                          checked={getValues('q0_3').split(',').includes('1')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={<Typography sx={{ fontSize: '1.2rem' }}>手</Typography>}
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('2', 'q0_3', event, '6')
                            )
                          }
                          checked={getValues('q0_3').split(',').includes('2')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={<Typography sx={{ fontSize: '1.2rem' }}>腹</Typography>}
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('3', 'q0_3', event, '6')
                            )
                          }
                          checked={getValues('q0_3').split(',').includes('3')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={<Typography sx={{ fontSize: '1.2rem' }}>腿</Typography>}
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('4', 'q0_3', event, '6')
                            )
                          }
                          checked={getValues('q0_3').split(',').includes('4')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={<Typography sx={{ fontSize: '1.2rem' }}>膝</Typography>}
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('5', 'q0_3', event, '6')
                            )
                          }
                          checked={getValues('q0_3').split(',').includes('5')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>なし</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('6', 'q0_3', event, '6')
                            )
                          }
                          checked={getValues('q0_3').split(',').includes('6')}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>

              <FormControl required error={'q0_4' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    marginTop: '5vh',
                  }}
                >
                  今回のご来店はどなたとされましたか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q0_4?.message}
                </FormHelperText>
                <Controller
                  name="q0_4"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            ご家族(同居)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            ご家族(別居)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            ご家族(同居＋別居)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            ご友人・知人
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            お連れ様なし
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q1_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  1. KUPOを今回なぜ利用しようと思いましたか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q1_1?.message}
                </FormHelperText>
                <FormGroup row>
                  <Controller
                    name="q1_1"
                    control={control}
                    rules={{
                      required: '必須項目です。',
                      onChange: () => {
                        if (!watch('q1_1').split(',').includes('5')) {
                          setValue('q1_2', '')
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              疲れを軽減したかったから
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('0', 'q1_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('0')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              便利そうだったから
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('1', 'q1_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('1')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              Webサイトや店内看板
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('2', 'q1_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('2')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              知人・友人の紹介
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('3', 'q1_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('3')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              KUPOに興味があった
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('4', 'q1_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('4')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('5', 'q1_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('4')}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>

              {watch('q1_1').split(',').includes('5') ? (
                <FormControl required error={'q1_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「その他」と答えた方へお伺いします。理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q1_2?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q1_2' in errors}
                    {...register('q1_2', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q2' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                >
                  2. KUPOについて、良いと感じた時と、その理由を教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q2?.message}
                </FormHelperText>
                <TextField
                  sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                  label="理由を入力"
                  InputProps={{ style: { fontSize: '1.2rem' } }}
                  InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                  required
                  error={'q2' in errors}
                  {...register('q2', { required: '必須項目です。' })}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q3' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                >
                  3.
                  KUPOについて、悪いと感じた時(特に”危ない”と感じた時)と、その理由を教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q3?.message}
                </FormHelperText>
                <TextField
                  sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                  label="理由を入力"
                  InputProps={{ style: { fontSize: '1.2rem' } }}
                  InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                  required
                  error={'q3' in errors}
                  {...register('q3', { required: '必須項目です。' })}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q4' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  4.
                  KUPOをレンタルしたことで、普段のお買い物と行動量、範囲にどのように変化がありましたか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q4?.message}
                </FormHelperText>
                <Controller
                  name="q4"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            普段より訪れた場所が大幅に増えた
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            普段より訪れた場所が増えた
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            普段と変わらない
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            普段より訪れる場所が減った
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            普段より訪れる場所が大幅に減った
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q5' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  5. KUPOをレンタルしたい時間はどのくらいですか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q5?.message}
                </FormHelperText>
                <Controller
                  name="q5"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>〜1時間</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            1時間〜3時間
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>半日</Typography>}
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>1日</Typography>}
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>複数日</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q6_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  6. 次回ご来店時も、KUPOを使ってみたいと思いますか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q6_1?.message}
                </FormHelperText>
                <Controller
                  name="q6_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q6_1') !== '1') {
                        setValue('q6_2', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            無料なら使う
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            有料でも使う
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>使わない</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q6_1') === '1' ? (
                <FormControl required error={'q6_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    このサービスを利用する際、いくらまでなら払いますか？1日あたりの利用料としてここまでなら払えるという金額を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q6_2?.message}
                  </FormHelperText>
                  <Controller
                    name="q6_2"
                    control={control}
                    rules={{ required: '必須項目です。' }}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              100〜500円未満
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              500〜1,000円未満
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              1,000〜2,000円未満
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              2,000円以上
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q7_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  7. どこでKUPOを借りられたら良かったですか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q7_1?.message}
                </FormHelperText>
                <FormGroup row>
                  <Controller
                    name="q7_1"
                    control={control}
                    rules={{
                      required: '必須項目です。',
                      onChange: () => {
                        if (!watch('q7_1').split(',').includes('5')) {
                          setValue('q7_2', '')
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              1Fスーパーの近く
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('0', 'q7_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('0')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              1Fバス乗り場の近く
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('1', 'q7_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('1')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              2F専門店街
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('2', 'q7_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('2')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              3F専門店街
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('3', 'q7_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('3')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>駐車場</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('4', 'q7_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('3')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('5', 'q7_1', event))
                          }
                          // checked={getValues('q5_1').split(',').includes('4')}
                        />
                      </>
                    )}
                  />
                </FormGroup>

                {watch('q7_1').split(',').includes('5') ? (
                  <FormControl required error={'q7_2' in errors} fullWidth>
                    <FormLabel
                      sx={{
                        color: 'black',
                        marginTop: '5vh',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      上記で「その他」と答えた方へお伺いします。場所を教えてください。
                    </FormLabel>
                    <FormHelperText sx={{ fontSize: '1.1rem' }}>
                      {errors.q7_2?.message}
                    </FormHelperText>
                    <TextField
                      sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                      label="理由を入力"
                      InputProps={{ style: { fontSize: '1.2rem' } }}
                      InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                      required
                      defaultValue=""
                      error={'q7_2' in errors}
                      {...register('q7_2', { required: '必須項目です。' })}
                    />
                  </FormControl>
                ) : (
                  ''
                )}
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q8' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  8. 駐車場まで自動運転でお迎えと返却をする機能があれば、欲しいですか？
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q8?.message}
                </FormHelperText>
                <Controller
                  name="q8"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>ほしい</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>不要</Typography>}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            どちらともいえない・わからない
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                >
                  9. その他ご感想があれば教えてください。
                </FormLabel>
                <TextField
                  sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                  label="感想を入力"
                  InputProps={{ style: { fontSize: '1.2rem' } }}
                  InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                  {...register('q9', { required: '必須項目です。' })}
                />
              </FormControl>
            </Card>

            <div style={{ textAlign: 'center' }}>
              <SubmitButton
                text="回答する"
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
              />
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button
                variant="text"
                sx={{ borderRadius: 10, width: '10em' }}
                onClick={props.onClose}
              >
                キャンセル
              </Button>
            </div>
          </Stack>
        </Card>
      </BodyContainer>
    </div>
  )
}

export default AeonQuestionnaire
