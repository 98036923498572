import { useReducer, useMemo } from 'react'

import {
  initialPrefectureListState,
  PrefectureListContext,
  PrefectureListReducer,
} from '../Context/PrefectureListContext'

const PrefectureListProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [prefectureListState, prefectureListDispatch] = useReducer(
    PrefectureListReducer,
    initialPrefectureListState
  )
  const value = useMemo(
    () => ({ prefectureListState, prefectureListDispatch }),
    [prefectureListState]
  )

  return (
    <PrefectureListContext.Provider value={value}>
      {children}
    </PrefectureListContext.Provider>
  )
}

export default PrefectureListProvider
