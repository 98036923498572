import { useContext } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { useAppAlert } from '../../../Components/Alert/AlertProvider'
import { MobilityManagementContext } from '../Context/MobilityManagementContext'
import {
  addMobility,
  updateMobility,
  disableMobility,
  getCommunicationDeviceInfoList,
} from '../Repository/MobilityRepository'
import { CommunicationDeviceInfo, MobilityInfo } from '../Types/MobilityManagementTypes'

const useMobilityManagement = () => {
  const { mobilityManagementState, mobilityManagementDispatch } = useContext(
    MobilityManagementContext
  )

  const [openSuccessAlert, openErrorAlert, closeAlert] = useAppAlert()

  const onAdd: SubmitHandler<MobilityInfo> = (inputs: MobilityInfo) => {
    closeAlert()
    mobilityManagementDispatch({ type: 'ADD', inputs })

    addMobility(inputs)
      .then((result) => {
        mobilityManagementDispatch({ type: 'SUCCESS' })
        openSuccessAlert('車両を登録しました。')
      })
      .catch((error: Error) => {
        mobilityManagementDispatch({ type: 'FAILD', error })
        openErrorAlert(error)
      })
  }

  const onUpdate: SubmitHandler<MobilityInfo> = (inputs: MobilityInfo) => {
    closeAlert()
    mobilityManagementDispatch({ type: 'UPDATE', inputs })

    updateMobility(inputs)
      .then((result) => {
        mobilityManagementDispatch({ type: 'SUCCESS' })
        openSuccessAlert('車両を更新しました。')
      })
      .catch((error: Error) => {
        mobilityManagementDispatch({ type: 'FAILD', error })
        openErrorAlert(error)
      })
  }

  const onDisable = (inputs: MobilityInfo) => {
    closeAlert()
    mobilityManagementDispatch({ type: 'DISABLE', inputs })

    disableMobility(inputs)
      .then((result) => {
        mobilityManagementDispatch({ type: 'SUCCESS' })
        openSuccessAlert('車両を削除しました。')
      })
      .catch((error: Error) => {
        mobilityManagementDispatch({ type: 'FAILD', error })
        openErrorAlert(error)
      })
  }

  const toggleAddDialog = () => mobilityManagementDispatch({ type: 'TOGGLE_ADD_DIALOG' })

  const toggleUpdateDialog = () =>
    mobilityManagementDispatch({ type: 'TOGGLE_UPDATE_DIALOG' })

  const toggleDisableialog = () =>
    mobilityManagementDispatch({ type: 'TOGGLE_DISABLE_DIALOG' })

  const stopFetch = () => mobilityManagementDispatch({ type: 'STOP_FETCH' })

  const fetchCommunicationDeviceList = () => {
    mobilityManagementDispatch({ type: 'FETCH_DEVICE' })

    getCommunicationDeviceInfoList()
      .then((result: CommunicationDeviceInfo[]) => {
        mobilityManagementDispatch({ type: 'FETCH_DEVICE_SUCCESS', deviceList: result })
      })
      .catch((error: Error) => {
        mobilityManagementDispatch({ type: 'FAILD', error })
        openErrorAlert(error)
      })
  }

  return [
    mobilityManagementState,
    onAdd,
    onUpdate,
    onDisable,
    toggleAddDialog,
    toggleUpdateDialog,
    toggleDisableialog,
    stopFetch,
    fetchCommunicationDeviceList,
  ] as const
}

export default useMobilityManagement
