import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import { useContext } from 'react'

import {
  AlertContext,
  ErrorAlert,
  SuccessAlert,
} from '../../../Components/Alert/AlertProvider'
import useSearchStaffResult from '../Hooks/useSearchStaffResult'
import { disableStaff } from '../Repository/StaffRepository'
import { DisableStaffConfirmDialogProps } from '../Types/StaffManagementTypes'

const DisableStaffConfirmDialog = (props: DisableStaffConfirmDialogProps) => {
  const { state, dispatch } = useContext(AlertContext)
  const [searchState, onSearch, fetchResult] = useSearchStaffResult()

  const onSubmit = () => {
    dispatch({ type: 'CLOSE' })

    disableStaff(props.staffInfo)
      .then((response) => {
        SuccessAlert(dispatch, '担当者を削除しました。')
        props.onClose()
        props.onUpdateDialogClose()
        fetchResult()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>削除確認</DialogTitle>
      <DialogContent>
        <DialogContentText>本当に削除してもよろしいですか？</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisableStaffConfirmDialog
