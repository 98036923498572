import { useReducer, useMemo } from 'react'

import {
  FacilityListContext,
  FacilityListReducer,
  initialFacilityListState,
} from '../Context/FacilityListContext'

const FacilityListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [facilityListState, facilityListDispatch] = useReducer(
    FacilityListReducer,
    initialFacilityListState
  )
  const value = useMemo(
    () => ({ facilityListState, facilityListDispatch }),
    [facilityListState]
  )

  return (
    <FacilityListContext.Provider value={value}>{children}</FacilityListContext.Provider>
  )
}

export default FacilityListProvider
