import { createContext, Dispatch } from 'react'

import { MobilityInfo, SearchMobilityInputs } from '../Types/MobilityManagementTypes'

export type SearchMobilityAction =
  | { type: 'SEARCH'; searchInputs: SearchMobilityInputs }
  | { type: 'SUCCESS'; result: MobilityInfo[] }
  | { type: 'FAILD'; error: Error }

export type SearchMobilityState = {
  searchInputs: SearchMobilityInputs
  searchResult: MobilityInfo[]
  isLoading: boolean
  error: Error | null
}

export const initialSearchMobilityState: SearchMobilityState = {
  searchInputs: { facilityId: '' },
  searchResult: [],
  isLoading: false,
  error: null,
}

export const SearchMobilityContext = createContext(
  {} as {
    searchMobilityState: SearchMobilityState
    searchMobilityDispatch: Dispatch<SearchMobilityAction>
  }
)

export const SearchMobilityReducer = (
  state: SearchMobilityState,
  action: SearchMobilityAction
): SearchMobilityState => {
  switch (action.type) {
    case 'SEARCH':
      return { ...state, isLoading: true, searchInputs: action.searchInputs }
    case 'SUCCESS':
      return { ...state, isLoading: false, searchResult: action.result }
    case 'FAILD':
      return { ...state, isLoading: false, error: action.error }
    default:
      return state
  }
}
