import { SubmitHandler } from 'react-hook-form'

import { StaffFacilityRelation } from '../../StaffManagement/Types/StaffManagementTypes'
import { SearchUserInfo } from '../Hooks/useSearchUserResult'

export type UserInfo = {
  userId: string
  lastName: string
  firstName: string
  email: string
  facilityId: string
  mobilityId: string
  reserveDay: string
  scheduledStartTime: string
  scheduledEndTime: string
  startTime: string
  endTime: string
  active: number
  agreementFlag: boolean
  emergencyFlag: boolean
  dangerousAreaFlag: boolean
  stepCount: number
  areaId: number
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  available: boolean
}

export const initialUserInfo: UserInfo = {
  userId: '',
  lastName: '',
  firstName: '',
  email: '',
  facilityId: '',
  mobilityId: '',
  reserveDay: '',
  scheduledStartTime: '',
  scheduledEndTime: '',
  startTime: '',
  endTime: '',
  active: 0,
  agreementFlag: false,
  emergencyFlag: false,
  dangerousAreaFlag: false,
  stepCount: 0,
  areaId: 0,
  createdBy: '',
  createdAt: '',
  updatedBy: '',
  updatedAt: '',
  available: true,
}

export type SearchUserInputs = {
  facilityId: string
  active: number
  startDay: Date | null
  endDay: Date | null
}

export type SearchUserCardProps = {
  onSearchUserSubmit: SubmitHandler<SearchUserInputs>
  facilityList: StaffFacilityRelation[]
  isLoading: boolean
}

export type SearchUserResultProps = {
  searchUserResult: SearchUserInfo[]
}

export type AddUserDialogProps = {
  isOpen: boolean
  onClose: VoidFunction
  onSubmit: SubmitHandler<UserInfo>
  facilityList: StaffFacilityRelation[]
}

export type UpdateUserDialogProps = {
  isOpen: boolean
  onClose: VoidFunction
  userInfo: UserInfo
  userId: string
}

export type UpdateUserInfo = {
  userId: string
  mobilityId: string
  reserveDay: string
  scheduledStartTime: string
  scheduledEndTime: string
  updatedBy: string
  updatedAt: string
}
