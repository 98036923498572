import { Stack } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AppAlert from '../../Components/Alert/AppAlert'
import AddFab from '../../Components/Common/AddFab'
import ConfirmDialog from '../../Components/Common/ConfirmDialog'
import PageTitle from '../../Components/Common/PageTitle'
import { useFacilityList } from '../../Context/useFacilityList'

import SearchUserCard from './Components/SearchUserCard'
import SearchUserResult from './Components/SearchUserResult'
import useSearchUserResult from './Hooks/useSearchUserResult'

const UserManagement = () => {
  const facilityState = useFacilityList()
  const [userList, onSearchUserResult, isLoading] = useSearchUserResult()

  const [isOpen, setIsOpen] = useState(false)
  const toggleDialog = () => setIsOpen(!isOpen)

  const navigate = useNavigate()
  const onAdd = () => {
    navigate('/Reservation')
  }

  return (
    <div className="UserManagement">
      <PageTitle title="利用者管理画面" />
      <Stack spacing={2}>
        <AppAlert />
        <SearchUserCard
          onSearchUserSubmit={onSearchUserResult}
          facilityList={facilityState.facilityList}
          isLoading={isLoading}
        />
        <SearchUserResult searchUserResult={userList} />

        {/* TODO: 追加は予約画面からお願いする。 */}
        <AddFab onclick={toggleDialog} />
        <ConfirmDialog
          isOpen={isOpen}
          onSubmit={onAdd}
          onClose={toggleDialog}
          title="予約の追加"
          text="予約画面に移動します。"
          isLoading={false}
        />
      </Stack>
    </div>
  )
}

export default UserManagement
