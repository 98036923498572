import { Button, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'

import { AlertContext, ErrorAlert } from '../../Components/Alert/AlertProvider'
import { Step } from '../MobilityMonitoring/Types/MobilityMonitoringTypes'

import { getStepByUserId } from './Repository/QuestionnaireRepository'
import { PostRideProps } from './Types/QuestionnaireTypes'

const FinishRide = (props: PostRideProps) => {
  const { dispatch } = useContext(AlertContext)
  const [step, setStep] = useState<Step>({ mobilityId: 'none', step: 1234 })

  useEffect(() => {
    getStepByUserId(props.userInfo.userId)
      .then((response: Step) => {
        setStep(response)
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
  }, [])

  return (
    <Stack spacing={6} sx={{ padding: 6 }}>
      <Typography variant="h5">ご利用ありがとうございました。</Typography>

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography variant="h5">
          {props.userInfo.lastName}様が、KUPOを押して歩いた距離は
        </Typography>

        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          <span style={{ display: 'inline-block', width: '0.5em' }} />
          {step.mobilityId !== 'none' ? Math.trunc(step.step) : ''}
          <span style={{ display: 'inline-block', width: '0.5em' }} />
        </Typography>

        <Typography variant="h5">歩でした。</Typography>
      </div>

      <Typography variant="h5">
        お疲れ様でした。フラワーパーク入口にてアンケートに回答いただくと粗品のプレゼントがありますのでぜひお立ち寄りください。
      </Typography>
      <Button sx={{ position: 'fixed', bottom: 20, right: 20 }} onClick={props.onClose}>
        閉じる
      </Button>
    </Stack>
  )
}

export default FinishRide
