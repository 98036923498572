import { CircularProgress, Stack } from '@mui/material'
import { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useInterval from 'use-interval'

import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { useFacilityList } from '../../../Context/useFacilityList'
import useMobilityMonitoring from '../Hooks/useMobilityMonitoring'
import { MobilityMonitoringInputs } from '../Types/MobilityMonitoringTypes'

import MobilityList from './MobilityList'

const SearchMobilityMonitoring = memo(() => {
  const facilityState = useFacilityList()
  const {
    register,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<MobilityMonitoringInputs>({
    defaultValues: { facilityId: facilityState.facilityList[0]?.facilityId || '' },
  })

  // Push通知から飛んできた場合に施設を初期設定
  const urlParams = useParams()
  useEffect(() => {
    if (urlParams.facilityId) setValue('facilityId', urlParams.facilityId)
  }, [])

  const [, setMonitorInterval, , , isLoading] = useMobilityMonitoring()

  // 施設を変更した際に即データを取り直す
  useEffect(() => {
    if (getValues().facilityId !== '') {
      setMonitorInterval(getValues().facilityId)
    }
  }, [getValues().facilityId])

  // 10秒ごとに定期的にデータを取得する処理
  useInterval(
    () => {
      if (getValues().facilityId !== '') {
        setMonitorInterval(getValues().facilityId)
      }
    },
    10000,
    true
  )

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <SelectFacilityForm
          value={watch('facilityId')}
          error={'facilityId' in errors}
          helperText={errors.facilityId?.message}
          register={register('facilityId', validationRules.facilityId)}
        />
        {isLoading ? <CircularProgress /> : <div />}
      </Stack>

      <MobilityList />
    </Stack>
  )
})

export default SearchMobilityMonitoring
