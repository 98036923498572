import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'

import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import AeonQuestionnaire from '../../Questionnaire/AeonQuestionnaire'
import PostRide from '../../Questionnaire/PostRide'
import PreRide from '../../Questionnaire/PreRide'
import Questionnaire from '../../Questionnaire/Questionnaire'
import { getUserInfo } from '../Repository/UserDataRepository'
import { UserInfo } from '../Types/UserManagementTypes'

import { getActiveName } from './activeStatusList'
import FullScreenDialog from './FullScreenDialog'
import UpdateUserDialog from './UpdateUserDialog'

export type UserFlowDialogProps = {
  isOpen: boolean
  onClose: VoidFunction
  userInfo: UserInfo
  userId: string
}
const UserFlowDialog = (props: UserFlowDialogProps) => {
  const [userInfo, setUserInfo] = useState(props.userInfo)

  const [agreementIsOpen, setAgreementIsOpen] = useState(false)
  const [watchingIsOpen, setWatchingIsOpen] = useState(false)
  const [questionaireIsOpen, setQuestionaireIsOpen] = useState(false)
  const [updateIsOpen, setUpdateIsOpen] = useState(false)

  const { dispatch } = useContext(AlertContext)

  useEffect(() => {
    if (props.isOpen) {
      // TODO: 暫定対応

      getUserInfo(props.userId)
        .then((response: UserInfo) => {
          setUserInfo(response)
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })
    }
  }, [props.isOpen, agreementIsOpen, watchingIsOpen, questionaireIsOpen, updateIsOpen])

  const toggleQuestionaireDialog = () => setQuestionaireIsOpen(!questionaireIsOpen)
  const toggleAgreementDialog = () => setAgreementIsOpen(!agreementIsOpen)
  const toggleWatchingDialog = () => setWatchingIsOpen(!watchingIsOpen)
  const toggleUpdateDialog = () => setUpdateIsOpen(!updateIsOpen)

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="lg">
        <DialogTitle>利用の流れ</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography>{`利用者：${userInfo.lastName} ${userInfo.firstName}`}</Typography>
            <Typography>{`利用ステータス：${getActiveName(userInfo.active)}`}</Typography>
            <Stack spacing={2}>
              <Button
                variant="contained"
                onClick={toggleAgreementDialog}
                disabled={userInfo.active !== 0}
                sx={{ borderRadius: 10, padding: 1, width: '17em' }}
              >
                同意確認
              </Button>
              <Button
                variant="contained"
                onClick={toggleWatchingDialog}
                disabled={userInfo.active !== 1}
                sx={{ borderRadius: 10, padding: 1, width: '17em' }}
              >
                利用開始
              </Button>
              <Button
                variant="contained"
                onClick={toggleQuestionaireDialog}
                disabled={userInfo.active !== 2}
                sx={{ borderRadius: 10, padding: 1, width: '17em' }}
              >
                試乗後アンケート
              </Button>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ marginBottom: 1, marginX: 1 }}>
          <Stack direction="row" spacing={2}>
            <Button color="secondary" onClick={props.onClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={toggleUpdateDialog}>
              編集
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <FullScreenDialog
        isOpen={agreementIsOpen}
        onClose={toggleAgreementDialog}
        components={<PreRide userInfo={userInfo} onClose={toggleAgreementDialog} />}
      />
      <FullScreenDialog
        isOpen={watchingIsOpen}
        onClose={toggleWatchingDialog}
        components={<PostRide userInfo={userInfo} onClose={toggleWatchingDialog} />}
      />
      {/* TODO: 暫定対応 */}
      <FullScreenDialog
        isOpen={questionaireIsOpen}
        onClose={toggleQuestionaireDialog}
        components={
          userInfo.facilityId === 'FlowerPark' ? (
            <Questionnaire userInfo={userInfo} onClose={toggleQuestionaireDialog} />
          ) : (
            <AeonQuestionnaire userInfo={userInfo} onClose={toggleQuestionaireDialog} />
          )
        }
      />
      <UpdateUserDialog
        isOpen={updateIsOpen}
        onClose={toggleUpdateDialog}
        userInfo={userInfo}
        userId={userInfo.userId}
      />
    </>
  )
}

export default UserFlowDialog
