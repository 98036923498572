import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { usePrefectureList } from '../../../Context/usePrefectureList'
import usePutFacility from '../Hooks/usePutFacility'
import {
  UpdateFacilityDialogProps,
  FacilityInfo,
  Prefecture,
} from '../Types/FacilityManagementTypes'

import DisableFacilityDialog from './DisableFacilityDialog'

const UpdateFacilityDialog = (props: UpdateFacilityDialogProps) => {
  const { register, handleSubmit, reset } = useForm<FacilityInfo>()
  const prefectureListState = usePrefectureList()

  const onSubmit = usePutFacility(props.onClose)

  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(!isOpen)

  useEffect(() => {
    reset(props.facilityInfo)
  }, [props.facilityInfo, reset])

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.onClose} fullWidth maxWidth="md">
        <DialogTitle>施設更新</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ padding: 1 }}>
            <TextField
              disabled
              label="施設ID"
              helperText="施設IDは変更できません"
              defaultValue={props.facilityInfo.facilityId}
              {...register('facilityId', {})}
            />
            <TextField
              label="施設名"
              helperText="施設名を入力してください"
              defaultValue={props.facilityInfo.facilityName}
              {...register('facilityName', { required: true })}
            />
            <TextField
              select
              label="都道府県"
              helperText="都道府県を選択してください"
              defaultValue={props.facilityInfo.prefCode}
              {...register('prefCode', { required: true })}
            >
              {prefectureListState.prefectureList.map((prefecture: Prefecture) => {
                return (
                  <MenuItem value={prefecture.prefCode} key={prefecture.prefCode}>
                    {prefecture.prefecture}
                  </MenuItem>
                )
              })}
            </TextField>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit(onSubmit)} autoFocus>
            Update
          </Button>
          <Button color="primary" onClick={onClose}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DisableFacilityDialog
        isOpen={isOpen}
        onClose={onClose}
        onUpdateClose={props.onClose}
        facilityInfo={props.facilityInfo}
      />
    </>
  )
}

export default UpdateFacilityDialog
