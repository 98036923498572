import { Polygon } from 'react-leaflet'

import { areaColor } from '../../../Components/Map/Area'
import useMobilityMonitoring from '../Hooks/useMobilityMonitoring'

const EmergencyAreas = () => {
  const [mobilityMonitoringState] = useMobilityMonitoring()
  return (
    <>
      {mobilityMonitoringState.areaInfoList.map((data, index) => (
        <Polygon
          // pathOptions={{ color: areaColor(data.areaType), stroke: false }}
          pathOptions={{ color: areaColor(data), stroke: false }}
          positions={data.coordinateList}
          key={index}
        />
      ))}
    </>
  )
}

export default EmergencyAreas
