import { createContext, Dispatch } from 'react'

import { SearchStaffActionType, SearchStaffState } from '../Types/StaffManagementTypes'

export const initialSearchStaffState: SearchStaffState = {
  searchInputs: { facilityId: '', userGroup: 'Staff' },
  searchResult: [],
  isLoading: false,
  error: Error(''),
}

export const SearchStaffContext = createContext(
  {} as {
    searchStaffState: SearchStaffState
    searchStaffDispatch: Dispatch<SearchStaffActionType>
  }
)
