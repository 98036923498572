import { LatLngExpression, LatLngTuple } from 'leaflet'

import { initialWatchingLocation } from '../Hooks/useUserWatching'

export type UserMapProps = {
  position: LatLngExpression
  height: string
  mobilityId: string
  facilityId: string
  userId: string
  scheduledEndTime: string
}

export type UserMarkerProps = {
  userId: string
  mobilityId: string
  facilityId: string
  scheduledEndTime: string
}

export type LocationData = {
  latitude: number
  longitude: number
  speed: number | null
  timestamp: Date
  accuracy: number
  altitude: number | null
  altitudeAccuracy: number | null
  heading: number | null
}

export const initialLocationData: LocationData = {
  latitude: (initialWatchingLocation as LatLngTuple)[0],
  longitude: (initialWatchingLocation as LatLngTuple)[1],
  speed: null,
  timestamp: new Date(),
  accuracy: 0,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
}
