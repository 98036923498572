import { DataGrid, GridColDef, GridEventListener, jaJP } from '@mui/x-data-grid'
import { useState } from 'react'

import { useFacilityList } from '../../../Context/useFacilityList'
import {
  UserInfo,
  SearchUserResultProps,
  initialUserInfo,
} from '../Types/UserManagementTypes'

import { getActiveName } from './activeStatusList'
import AeonUserFlowDialog from './AeonUserFlowDialog'
import UserFlowDialog from './UserFlowDialog'

const SearchUserResult = (props: SearchUserResultProps) => {
  const columns: GridColDef[] = [
    { field: 'userId', headerName: '予約番号', width: 150 },
    { field: 'userName', headerName: '氏名', width: 150 },
    { field: 'mobilityName', headerName: '車両名', width: 180 },
    { field: 'activeName', headerName: 'ステータス', width: 100 },
    { field: 'facilityName', headerName: '施設名', width: 150 },
    { field: 'reserveDay', headerName: '予約日', width: 100 },
    { field: 'startHour', headerName: '開始予定時間', width: 100 },
    { field: 'endHour', headerName: '終了予定時間', width: 100 },
    { field: 'stepCount', headerName: '歩数', width: 100 },
  ]

  const UserListForDataGrid = []
  const facilityListState = useFacilityList()

  for (const user of props.searchUserResult) {
    UserListForDataGrid.push({
      ...user,
      activeName: getActiveName(user.active),
      userName: `${user.lastName} ${user.firstName}`,
      facilityName: facilityListState.facilityList.find((facility) => {
        return facility.facilityId === user.facilityId
      })?.facilityName,
      reserveDay: `${user.reserveDay.slice(0, 4)}/${user.reserveDay.slice(
        4,
        6
      )}/${user.reserveDay.slice(6, 8)}`,
      startHour: `${user.scheduledStartTime
        .split('T')[1]
        .slice(0, 2)}:${user.scheduledStartTime.split('T')[1].slice(2, 4)}`,
      endHour: `${user.scheduledEndTime.split('T')[1].slice(0, 2)}:${user.scheduledEndTime
        .split('T')[1]
        .slice(2, 4)}`,
    })
  }

  const [isOpen, setIsOpen] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo)

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    const info = params.row as UserInfo
    setUserInfo(info)
    setIsOpen(!isOpen)
  }

  const onClose = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <DataGrid
        autoHeight
        rows={UserListForDataGrid}
        columns={columns}
        getRowId={(row: UserInfo) => row.userId}
        hideFooterSelectedRowCount
        onRowClick={onRowClick}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />

      {userInfo.facilityId === 'AeonShitoro' ? (
        <AeonUserFlowDialog
          isOpen={isOpen}
          onClose={onClose}
          userInfo={userInfo}
          userId={userInfo.userId}
        />
      ) : (
        <UserFlowDialog
          isOpen={isOpen}
          onClose={onClose}
          userInfo={userInfo}
          userId={userInfo.userId}
        />
      )}
    </>
  )
}

export default SearchUserResult
