import { LoadingButton } from '@mui/lab'
import { MenuItem, TextField } from '@mui/material'
import { UseFormRegisterReturn } from 'react-hook-form'

import useMobilityManagement from '../../Pages/MobilityManagement/Hooks/useMobilityManagement'

type SelectImeiFormProps = {
  value: string
  error: boolean
  helperText: string | undefined
  register: UseFormRegisterReturn
  disabled?: boolean
}
const SelectImeiForm = (props: SelectImeiFormProps) => {
  const [mobilityManagementState] = useMobilityManagement()

  return mobilityManagementState.isLoading ? (
    <LoadingButton loading variant="contained" disabled>
      Fetch ImeiList
    </LoadingButton>
  ) : (
    <TextField
      select
      label="IMEI"
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      {...props.register}
      disabled={props.disabled}
    >
      {mobilityManagementState.deviceList.map((device) => (
        <MenuItem key={device.imei} value={device.imei}>
          {device.imei}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectImeiForm
