import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { timestampToDate } from '../../../Common/TimestampConverter'
import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import ConfirmDialog from '../../../Components/Common/ConfirmDialog'
import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import TextFieldForm from '../../../Components/Form/TextFieldForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import useDisableUserInfo from '../Hooks/useDisableUserInfo'
import useUpdateUserInfo from '../Hooks/useUpdateUserInfo'
import { getUserInfo } from '../Repository/UserDataRepository'
import { UpdateUserDialogProps, UserInfo } from '../Types/UserManagementTypes'

import { activeStatusList } from './activeStatusList'

const UpdateUserDialog = (props: UpdateUserDialogProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
    setValue,
  } = useForm<
    UserInfo & {
      startDate: Date | null
      endDate: Date | null
      actualStartDate: Date | null
      actualEndDate: Date | null
    }
  >({
    defaultValues: {
      ...props.userInfo,
      startDate: null,
      endDate: null,
      actualStartDate: null,
      actualEndDate: null,
    },
  })

  const { dispatch } = useContext(AlertContext)

  useEffect(() => {
    if (props.isOpen) {
      getUserInfo(props.userId)
        .then((response: UserInfo) => {
          reset(response)
          const { scheduledStartTime } = response
          const { scheduledEndTime } = response
          const { startTime } = response
          const { endTime } = response

          setValue('startDate', timestampToDate(scheduledStartTime))
          setValue('endDate', timestampToDate(scheduledEndTime))
          setValue('actualStartDate', timestampToDate(startTime))
          setValue('actualEndDate', timestampToDate(endTime))
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })
    }
  }, [props.isOpen])

  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const toggleDisableDialog = () => setIsDisableOpen(!isDisableOpen)
  const onDisable = useDisableUserInfo(toggleDisableDialog, props.onClose, props.userInfo)

  const [updateConfirmIsOpen, setUpdateConfirmIsOpen] = useState(false)
  const toggleUpdateConfirm = () => {
    setUpdateConfirmIsOpen(!updateConfirmIsOpen)
  }

  const completeUpdate = () => {
    props.onClose()
    toggleUpdateConfirm()
  }

  const [onUpdateSubmit, isLoading] = useUpdateUserInfo(completeUpdate)

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="md" fullWidth>
        <DialogTitle>利用者情報</DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ padding: 1 }}>
            <TextFieldForm
              label="氏（カナ）"
              value={watch('lastName')}
              error={'lastName' in errors}
              helperText={errors.lastName?.message}
              register={register('lastName', validationRules.lastName)}
            />
            <TextFieldForm
              label="名（カナ）"
              value={watch('firstName')}
              error={'firstName' in errors}
              helperText={errors.firstName?.message}
              register={register('firstName', validationRules.firstName)}
            />

            <SelectFacilityForm
              value={watch('facilityId')}
              error={'facilityId' in errors}
              helperText={errors.facilityId?.message}
              register={register('facilityId', validationRules.facilityId)}
              disabled
            />
            <TextField disabled label="車両" value={watch('mobilityId')} />

            <TextField disabled label="予約日" value={watch('reserveDay')} />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="開始予定時間"
                value={watch('startDate')}
                {...register('startDate', {
                  ...validationRules.startTime,
                  validate: (value: Date | null) => {
                    if (Number.isNaN(value?.getHours())) {
                      return '入力形式が間違っています。'
                    }

                    const startHours = value?.getHours() || 0
                    const endHours = watch('endDate')?.getHours() || 0
                    const startMinutes = value?.getMinutes() || 0
                    const endMinutes = watch('endDate')?.getMinutes() || 0

                    const result =
                      startHours < endHours ||
                      (startHours === endHours && startMinutes < endMinutes)

                    return (
                      result || '開始時間は終了時間より30分以上早く設定してください。'
                    )
                  },
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={'startDate' in errors}
                    helperText={errors.startDate?.message}
                  />
                )}
                onChange={(newValue) => {
                  setValue('startDate', newValue)
                }}
                minTime={new Date(0, 0, 0, 9)}
                maxTime={new Date(0, 0, 0, 16, 30)}
                minutesStep={30}
                ampm={false}
              />

              <TimePicker
                label="終了予定時間"
                value={watch('endDate')}
                {...register('endDate', validationRules.endTime)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={'endDate' in errors}
                    helperText={errors.endDate?.message}
                  />
                )}
                onChange={(newValue) => {
                  setValue('endDate', newValue)
                }}
                minTime={new Date(0, 0, 0, 9, 30)}
                maxTime={new Date(0, 0, 0, 17)}
                minutesStep={30}
                ampm={false}
              />

              {/* 実際の時間 */}
              <TimePicker
                label="実際の開始時間"
                value={watch('actualStartDate')}
                {...register('actualStartDate')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={'actualStartDate' in errors}
                    helperText={errors.actualStartDate?.message}
                  />
                )}
                onChange={(newValue) => {
                  setValue('actualStartDate', newValue)
                }}
                minTime={new Date(0, 0, 0, 9)}
                maxTime={new Date(0, 0, 0, 16, 30)}
                minutesStep={10}
                ampm={false}
              />

              <TimePicker
                label="実際の終了時間"
                value={watch('actualEndDate')}
                {...register('actualEndDate')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={'actualEndDate' in errors}
                    helperText={errors.actualEndDate?.message}
                  />
                )}
                onChange={(newValue) => {
                  setValue('actualEndDate', newValue)
                }}
                minTime={new Date(0, 0, 0, 9, 30)}
                maxTime={new Date(0, 0, 0, 17)}
                minutesStep={10}
                ampm={false}
              />
            </LocalizationProvider>

            <TextField
              select
              label="ステータス"
              value={watch('active')}
              error={'active' in errors}
              helperText={errors.active?.message}
              {...register('active', validationRules.active)}
            >
              {activeStatusList.map((activeStatus) => (
                <MenuItem key={activeStatus.active} value={activeStatus.active}>
                  {activeStatus.activeName}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ marginBottom: 1, marginX: 1 }}>
          <Stack direction="row" spacing={2}>
            <Button color="secondary" onClick={props.onClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={toggleUpdateConfirm}>
              Update
            </Button>
            <Button color="primary" onClick={toggleDisableDialog}>
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        title="削除確認"
        text="本当に削除してもよろしいですか？"
        isOpen={isDisableOpen}
        isLoading={false}
        onClose={toggleDisableDialog}
        onSubmit={onDisable}
      />

      <ConfirmDialog
        title="更新確認"
        text="利用者情報を更新します。"
        isOpen={updateConfirmIsOpen}
        isLoading={isLoading}
        onClose={toggleUpdateConfirm}
        onSubmit={handleSubmit(onUpdateSubmit)}
      />
    </>
  )
}
export default UpdateUserDialog
