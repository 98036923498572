import { API } from 'aws-amplify'

import { AppLogger } from '../../../Logger/AppLogger'
import { MobilityInfo } from '../../MobilityManagement/Types/MobilityManagementTypes'
import {
  AreaInfo,
  AreaJudgement,
  EmergencyAreaData,
} from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import { UserInfo } from '../../UserManagement/Types/UserManagementTypes'

export const getCurrentUserEmergencyArea = async (
  facilityId: string
): Promise<EmergencyAreaData[]> => {
  AppLogger.debug('call Api: getCurrentUserEmergencyArea')

  const apiName = 'Staff'
  const path = '/getEmergencyAreaData'

  const myInit = {
    body: {
      facilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: EmergencyAreaData[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getCurrentUserAreaInfo = async (facilityId: string): Promise<AreaInfo[]> => {
  AppLogger.debug('call Api: getCurrentUserAreaInfo')

  const apiName = 'Staff'
  const path = '/getAreaInfoByFacilityId'

  const myInit = {
    body: {
      facilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: AreaInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const setEmergencyFlag = async (userId: string, emergencyFlag: boolean) => {
  AppLogger.debug('call Api: setEmergencyFlag')
  const apiName = 'Staff'
  const path = '/setEmergencyFlag'

  const myInit = {
    body: {
      userId,
      emergencyFlag,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

// リクエストをまとめたAPIを叩く関数

export type MobileData = {
  userId: string
  facilityId: string
  mobilityId: string
  latitude: number
  longitude: number
  timestamp: string
  accuracy: number
}

export const initialMobileData: MobileData = {
  userId: '',
  facilityId: '',
  mobilityId: '',
  latitude: 0,
  longitude: 0,
  timestamp: '',
  accuracy: 0,
}

export const putMobileDataDangerousAreaDetection = async (
  mobileData: MobileData
): Promise<AreaJudgement[]> => {
  AppLogger.debug('call Api: putMobileDataDangerousAreaDetection')
  const apiName = 'Staff'
  const path = '/putMobileDataDangerousAreaDetection'
  const myInit = {
    body: {
      mobileData,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: AreaJudgement[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getReturnAreaJudgement = async (
  mobileData: MobileData
): Promise<AreaJudgement[]> => {
  AppLogger.debug('call Api: getReturnAreaJudgement')
  const apiName = 'Staff'
  const path = '/getReturnAreaJudgement'
  const myInit = {
    body: {
      mobileData,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: AreaJudgement[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const setReturnArea = async (userId: string, areaId: number) => {
  AppLogger.debug('call Api: setReturnArea')
  const apiName = 'Staff'
  const path = '/setReturnArea'
  const myInit = {
    body: {
      userId,
      areaId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const setStartEndTime = async (type: string, userId: string) => {
  const apiName = 'Staff'
  const path = '/setStartEndTime'

  const now = new Date()
  now.setTime(now.getTime())

  const datetime = `${
    String(now.getFullYear()) +
    `0${String(now.getMonth() + 1)}`.slice(-2) +
    `0${String(now.getDate())}`.slice(-2)
  }T${`0${String(now.getHours())}`.slice(-2)}${`0${String(now.getMinutes())}`.slice(
    -2
  )}${`0${String(now.getSeconds())}`.slice(-2)}${String(now.getMilliseconds())}`

  const timeData = {
    type,
    userId,
    datetime,
  }

  const myInit = {
    body: {
      timeData,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getCurrentUserMobilityInfo = async (
  mobilityId: string
): Promise<MobilityInfo[]> => {
  AppLogger.debug('call Api: getCurrentUserMobilityInfo')

  const apiName = 'Staff'
  const path = '/getMobilityInfoByMobilityId'

  const myInit = {
    body: {
      mobilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: MobilityInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const checkMobilityInUse = async (userInfo: UserInfo): Promise<boolean> => {
  AppLogger.debug('call Api: checkMobilityInUse')

  const apiName = 'Staff'
  const path = '/checkMobilityInUse'

  const myInit = {
    body: { userInfo },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: boolean) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
