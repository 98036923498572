import { useReducer, useMemo } from 'react'

import {
  initialUserGroupState,
  UserGroupContext,
  UserGroupReducer,
} from '../Context/UserGroupContext'

const UserGroupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userGroupState, userGroupDispatch] = useReducer(
    UserGroupReducer,
    initialUserGroupState
  )
  const value = useMemo(() => ({ userGroupState, userGroupDispatch }), [userGroupState])

  return <UserGroupContext.Provider value={value}>{children}</UserGroupContext.Provider>
}

export default UserGroupProvider
