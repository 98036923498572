import { createContext, Dispatch } from 'react'

type HeaderAction = { type: 'VISIBLE' } | { type: 'INVISIBLE' }

type HeaderState = { isVisible: boolean }

export const initialHeaderState: HeaderState = { isVisible: false }

export const HeaderContext = createContext(
  {} as {
    headerState: HeaderState
    headerDispatch: Dispatch<HeaderAction>
  }
)

export const HeaderReducer = (state: HeaderState, action: HeaderAction): HeaderState => {
  switch (action.type) {
    case 'VISIBLE':
      return { isVisible: true }
    case 'INVISIBLE':
      return { isVisible: false }
    default:
      return state
  }
}
