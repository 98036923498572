import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import GroupIcon from '@mui/icons-material/Group'
import HomeIcon from '@mui/icons-material/Home'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import LoginIcon from '@mui/icons-material/Login'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import MenuIcon from '@mui/icons-material/Menu'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import PlaceIcon from '@mui/icons-material/Place'
import { IconButton, Box, List, Drawer, Divider, Typography, Stack } from '@mui/material'
import { JSXElementConstructor, ReactElement, useContext, useState } from 'react'

// import kupoImage from '../../../Assets/KupoYellowPush.png'
import logoImage from '../../../Assets/hanahakuLogo.png'
import { UserGroupContext } from '../../../Context/UserGroupContext'

import AppMenuListItem from './AppMenuListItem'

export type MenuLink = {
  to: string
  label: string
  icon: ReactElement<unknown, string | JSXElementConstructor<unknown>>
}

type AppMenuProps = { mr: number }
const AppMenu = (props: AppMenuProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const toggleDrawer = () => setDrawerOpen(!drawerOpen)

  const { userGroupState } = useContext(UserGroupContext)
  const groupList = userGroupState.userGroupList

  const openMenuLinkList = (): MenuLink[] => {
    return [
      { to: '/', label: 'ホーム', icon: <HomeIcon /> },
      { to: '/UserManual', label: '試乗予約', icon: <CalendarMonthIcon /> },
    ]
  }
  const closedMenuLinkList = (): MenuLink[] => {
    if (groupList.includes('SuzukiAdmin')) {
      return [
        { to: '/User', label: '利用者', icon: <GroupIcon /> },
        { to: '/Management', label: '見守り', icon: <PlaceIcon /> },
        { to: '/Staff', label: '担当者', icon: <PersonSearchIcon /> },
        { to: '/Mobility', label: '車両', icon: <DirectionsCarIcon /> },
        { to: '/Facility', label: '施設', icon: <HomeWorkIcon /> },
        { to: '/Analytics', label: '分析', icon: <ManageSearchIcon /> },
      ]
    }
    if (groupList.includes('Admin')) {
      return [
        { to: '/User', label: '利用者', icon: <GroupIcon /> },
        { to: '/Management', label: '見守り', icon: <PlaceIcon /> },
        { to: '/Staff', label: '担当者', icon: <PersonSearchIcon /> },
      ]
    }
    if (groupList.includes('Staff')) {
      return [
        // { to: '/User', label: '利用者', icon: <GroupIcon /> },
        { to: '/Management', label: '見守り', icon: <PlaceIcon /> },
      ]
    }

    return [{ to: '/Management', label: '管理者向け', icon: <LoginIcon /> }]
  }

  const sx = { mr: props.mr }
  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={sx}
        onClick={toggleDrawer}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: 250 }}>
          <Stack paddingX={3} spacing={1} paddingTop={4} paddingBottom={2}>
            <img src={logoImage} alt="kupoImage" style={{ width: '5em' }} />
            <Typography variant="h6" paddingLeft={1}>
              メニュー
            </Typography>
          </Stack>

          <Divider variant="middle" />

          {/* <List>
            {openMenuLinkList().map((item) => (
              <AppMenuListItem key={item.label} menuItem={item} onclick={toggleDrawer} />
            ))}
          </List>
          <Divider variant="middle" /> */}
          <List>
            {closedMenuLinkList().map((item) => (
              <AppMenuListItem key={item.label} menuItem={item} onclick={toggleDrawer} />
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  )
}

export default AppMenu
