import { API } from 'aws-amplify'

import { FacilityListState } from '../../Context/FacilityListContext'
import { AppLogger } from '../../Logger/AppLogger'
import { UserInfo } from '../../Pages/UserManagement/Types/UserManagementTypes'

export type ActiveUserList = {
  facilityId: string
  activeUserList: UserInfo[]
}

export const getActiveUserList = async (
  facilityListState: FacilityListState
): Promise<ActiveUserList[]> => {
  AppLogger.debug('call Api: getActiveUserList')

  const facilityIdList = []

  if (facilityListState.facilityList.length !== 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const facility of facilityListState.facilityList) {
      facilityIdList.push(facility.facilityId)
    }
  }

  const apiName = 'Staff'
  const path = '/getActiveUserList'
  const myInit = {
    body: {
      facilityIdList,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        const activeUserList = response as ActiveUserList[]
        resolve(activeUserList)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
