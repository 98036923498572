import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// import logoImage from '../../Assets/kupoLogoWhite.png'
import logoImage from '../../Assets/hanahakuTitleLogo.png'

const AppLogo = () => {
  const navigate = useNavigate()
  const onClick = () => {
    navigate('/')
  }
  return (
    <Button onClick={onClick}>
      <img src={logoImage} alt="logo" style={{ height: 56, verticalAlign: 'middle' }} />
    </Button>
  )
}

export default AppLogo
