import { API } from 'aws-amplify'

import {
  dateToTimestamp,
  getDateString,
  getTimestampString,
} from '../../Common/TimestampConverter'
import { AppLogger } from '../../Logger/AppLogger'
import { UpdateUserInfo, UserInfo } from '../UserManagement/Types/UserManagementTypes'

import {
  ReservableMobility,
  ReservationInputs,
  ReserveConfirmationInfo,
  ReserveResult,
  UpdateReservationInputs,
} from './ReservationTypes'

export const getReservationInfo = async (
  userId: string,
  facilityId: string,
  mobilityId: string
): Promise<ReserveConfirmationInfo> => {
  AppLogger.debug('call Api: getReservationInfo')
  const apiName = 'OpenApi'
  const path = '/confirmReservation'
  const myInit = {
    body: {
      userId,
      facilityId,
      mobilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: ReserveConfirmationInfo) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const cancelReservation = async (
  userId: string,
  facilityId: string,
  mobilityId: string
): Promise<{ reservationNumber: string }> => {
  AppLogger.debug('call Api: cancelReservation')
  const apiName = 'OpenApi'
  const path = '/cancelReservation'
  const myInit = {
    body: {
      userId,
      facilityId,
      mobilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: { reservationNumber: string }) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const checkReservation = (
  reserveDay: string,
  scheduledStartTime: string,
  scheduledEndTime: string,
  facilityId: string
): Promise<ReservableMobility[]> => {
  const apiName = 'OpenApi'
  const path = '/checkReservation'
  const myInit = {
    body: { reserveDay, scheduledStartTime, scheduledEndTime, facilityId },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: ReservableMobility[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const addReservation = (inputs: ReservationInputs): Promise<ReserveResult> => {
  AppLogger.debug('call Api: addReservation')

  const dateString = getDateString(inputs.reserveDay || new Date())
  const currentTimestamp = dateToTimestamp(new Date())

  const userInfo: UserInfo = {
    mobilityId: inputs.reserveMobility,
    scheduledStartTime: getTimestampString(
      inputs.reserveDay || new Date(),
      inputs.startTime || new Date()
    ),
    scheduledEndTime: getTimestampString(
      inputs.reserveDay || new Date(),
      inputs.endTime || new Date()
    ),
    facilityId: inputs.facilityId,
    lastName: inputs.lastName,
    firstName: inputs.firstName,
    available: true,
    userId: '',
    email: inputs.email,
    reserveDay: dateString,
    startTime: '',
    endTime: '',
    emergencyFlag: false,
    agreementFlag: false,
    active: 0,
    dangerousAreaFlag: false,
    stepCount: 0,
    areaId: 0,
    createdBy: '',
    createdAt: currentTimestamp,
    updatedBy: '',
    updatedAt: currentTimestamp,
  }

  const apiName = 'OpenApi'
  const path = '/addReservation'
  const myInit = {
    body: { userInfo },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: ReserveResult) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const updateReservation = (
  inputs: UpdateReservationInputs
): Promise<ReserveResult> => {
  AppLogger.debug('call Api: updateReservation')

  const dateString = getDateString(inputs.reserveDay || new Date())
  const currentTimestamp = dateToTimestamp(new Date())

  const userInfo: UpdateUserInfo = {
    mobilityId: inputs.reserveMobility,
    scheduledStartTime: getTimestampString(
      inputs.reserveDay || new Date(),
      inputs.startTime || new Date()
    ),
    scheduledEndTime: getTimestampString(
      inputs.reserveDay || new Date(),
      inputs.endTime || new Date()
    ),
    userId: inputs.reservationNumber,
    reserveDay: dateString,
    updatedBy: '',
    updatedAt: currentTimestamp,
  }

  const apiName = 'OpenApi'
  const path = '/updateReservation'
  const myInit = {
    body: { userInfo },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: ReserveResult) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
