import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Fab } from '@mui/material'
import { MouseEventHandler } from 'react'

type AddFabProps = {
  onclick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

const AddFab = (props: AddFabProps) => {
  return (
    <Fab
      variant="extended"
      color="secondary"
      sx={{ position: 'fixed', bottom: 20, right: 20 }}
      disabled={props.disabled ?? false}
      onClick={props.onclick}
    >
      <AddCircleIcon sx={{ mr: 1 }} />
      追加
    </Fab>
  )
}

export default AddFab
