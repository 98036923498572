import { Container } from '@mui/material'
import { useContext } from 'react'

import { HeaderContext } from '../Context/HeaderContext'

const BodyContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { headerState } = useContext(HeaderContext)
  return (
    <div className="BodyContainer">
      {headerState.isVisible ? (
        <Container maxWidth="xl" sx={{ paddingTop: '1vh', paddingBottom: '3vh' }}>
          {children}
        </Container>
      ) : (
        <div className="no container">{children}</div>
      )}
    </div>
  )
}

export default BodyContainer
