import styled from '@emotion/styled'
import { useTheme, useMediaQuery } from '@mui/material'
import { Icon, LatLngExpression, Map as LeafletMap } from 'leaflet'
import { memo, ReactNode, Ref } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'

import {
  iconImage,
  initialZoomLevel,
  scrollWheelZoom,
  tileAttribution,
  tileUrl,
  maxZoomLevel,
} from '../../UserWatching/MapSettings'

type MapCardProps = {
  children?: ReactNode
  position: LatLngExpression
  height?: string
  zoom?: number
  mapRef?: Ref<LeafletMap>
}

const MapCard = memo((props: MapCardProps) => {
  const theme = useTheme()

  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'))

  const defaultMapHeight = isMobileSize ? '50vh' : '70vh'

  Icon.Default.imagePath = iconImage
  const CustomMapContainer = styled(MapContainer)({
    height: props.height ?? defaultMapHeight,
    borderRadius: 6,
    boxShadow: ' 0 5px 5px 0 rgba(0, 0, 0, .15)',
  })

  return (
    <CustomMapContainer
      center={props.position}
      zoom={props.zoom ?? initialZoomLevel}
      scrollWheelZoom={scrollWheelZoom}
      closePopupOnClick={false}
      ref={props.mapRef}
    >
      <TileLayer
        attribution={tileAttribution}
        url={tileUrl}
        maxZoom={maxZoomLevel}
        maxNativeZoom={maxZoomLevel}
      />
      {props.children}
    </CustomMapContainer>
  )
})

export default MapCard
