import { useAuthenticator } from '@aws-amplify/ui-react'
import { AccountCircle } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useState } from 'react'

import { updateAppEndpoint } from '../../../AppTrack'

import AccountAvatar from './AccountAvatar'
import AccountMenu from './AccountMenu'

const AccountMenuButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { user } = useAuthenticator((context) => [context.user])
  if (user.attributes?.email) updateAppEndpoint(user.attributes?.email)

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {user.attributes?.email ? (
          <AccountAvatar name={user.attributes?.email} />
        ) : (
          <AccountCircle />
        )}
      </IconButton>
      <AccountMenu onClose={handleClose} anchorEl={anchorEl} />
    </>
  )
}

export default AccountMenuButton
