/* eslint-disable no-param-reassign */
import { createContext, Dispatch } from 'react'

import { AreaJudgement } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import { initialLocationData, LocationData } from '../Types/UserWatchingTypes'

export type UserWatchingState = {
  locationData: LocationData
  heading: number | null
  returnAreaJudgement: AreaJudgement
  isLoading: boolean
  error: Error | null
}

export const initialUserWatchingState: UserWatchingState = {
  locationData: initialLocationData,
  returnAreaJudgement: { mobilityId: '', result: 0 },
  isLoading: false,
  error: null,
  heading: null,
}

export type UserWatchingAction =
  | { type: 'SET_LOCATION'; locationData: LocationData }
  | { type: 'SET_HEADING'; heading: number | null }
  | { type: 'SET_RETURN_AREA'; returnArea: AreaJudgement }

export const UserWatchingReducer = (
  state: UserWatchingState,
  action: UserWatchingAction
): UserWatchingState => {
  switch (action.type) {
    case 'SET_LOCATION':
      return { ...state, locationData: action.locationData }
    case 'SET_HEADING':
      return { ...state, heading: action.heading }
    case 'SET_RETURN_AREA':
      return { ...state, returnAreaJudgement: action.returnArea }
    default:
      return state
  }
}

export const UserWatchingContext = createContext(
  {} as {
    userWatchingState: UserWatchingState
    userWatchingDispatch: Dispatch<UserWatchingAction>
  }
)
