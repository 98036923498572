import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { KeyboardEventHandler, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { EventType, recordEvent } from '../../AppTrack'
import {
  getDateString,
  getFormatTime,
  getTimestampString,
} from '../../Common/TimestampConverter'
import { useAppAlert } from '../../Components/Alert/AlertProvider'
import AppAlert from '../../Components/Alert/AppAlert'
import PageTitle from '../../Components/Common/PageTitle'
import { validationRules } from '../../Components/Form/ValidationRules'
import { AppLogger } from '../../Logger/AppLogger'

import { addReservation, checkReservation } from './ReservationRepository'
import { ReservableMobility, ReserveResult, ReservationInputs } from './ReservationTypes'

const Reservation = () => {
  recordEvent(EventType.open_reservation_page)
  const [openSuccessAlert, openErrorAlert, closeAlert] = useAppAlert()
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()

  const dateStep = 1
  const mobilityStep = 2
  const userInfoStep = 3

  const [mobilityList, setMobilityList] = useState<ReservableMobility[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  // TODO: APIで情報取得するように後ほどする
  const facilityList = [
    { facilityId: 'AeonShitoro', facilityName: 'イオン志都呂' },
    { facilityId: 'FlowerPark', facilityName: 'フラワーパーク' },
  ]

  const handleNext = async () => {
    const errorList = []

    if (activeStep === dateStep) {
      errorList.push(await trigger('reserveDay'))
      errorList.push(await trigger('startTime'))
      errorList.push(await trigger('endTime'))

      if (!errorList.includes(false)) {
        setMobilityList([])
        setValue('reserveMobility', 'none')
        setLoading(true)
        checkReservation(
          getDateString(watch('reserveDay') || new Date()),
          getTimestampString(
            watch('reserveDay') || new Date(),
            watch('startTime') || new Date()
          ),
          getTimestampString(
            watch('reserveDay') || new Date(),
            watch('endTime') || new Date()
          ),
          watch('facilityId')
        )
          .then((response) => {
            setMobilityList(response)
          })
          .catch((error: Error) => {
            openErrorAlert(error)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }

    if (activeStep === mobilityStep) {
      errorList.push(await trigger('reserveMobility'))
    }

    if (activeStep === userInfoStep) {
      errorList.push(await trigger('firstName'))
      errorList.push(await trigger('lastName'))
      errorList.push(await trigger('email'))
    }

    if (!errorList.includes(false)) setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm<ReservationInputs>({
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      facilityId: 'AeonShitoro',
      reserveMobility: 'none',
      reserveDay: new Date(),
      startTime: new Date(0, 0, 0, 9, 0),
      endTime: new Date(0, 0, 0, 11, 0),
    },
  })

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
  }

  const steps = [
    {
      number: 0,
      label: '利用施設の選択',
      optional: facilityList.find((facility) => {
        return facility.facilityId === watch('facilityId')
      })?.facilityName,
      content: (
        <TextField
          select
          label="施設"
          value={watch('facilityId')}
          error={'facilityId' in errors}
          helperText={errors.facilityId?.message}
          {...register('facilityId', validationRules.facilityId)}
        >
          {facilityList.map((facility) => (
            <MenuItem key={facility.facilityId} value={facility.facilityId}>
              {facility.facilityName}
            </MenuItem>
          ))}
        </TextField>
      ),
    },

    {
      number: 1,
      label: '日程の選択',
      optional: `${watch('reserveDay')?.toLocaleDateString() || ''} ${
        getFormatTime(watch('startTime')) || ''
      } ~ ${getFormatTime(watch('endTime')) || ''}`,
      content: (
        <Stack direction="column" sx={{ width: '40vw' }} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="利用日"
              value={watch('reserveDay')}
              inputFormat="yyyy/MM/dd"
              mask="____/__/__"
              {...register('reserveDay', validationRules.reserveDay)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'reserveDay' in errors}
                  helperText={errors.reserveDay?.message}
                  onKeyDown={onKeyDown}
                />
              )}
              onChange={(newValue) => {
                setValue('reserveDay', newValue)
              }}
              disablePast
              maxDate={new Date(2023, 3 - 1, 31)}
              // shouldDisableDate={(day: Date): boolean => {
              //   return day.getDate() === new Date(2022, 8 - 1, 31).getDate()
              // }}
            />

            <TimePicker
              label="開始時間"
              value={watch('startTime')}
              {...register('startTime', {
                ...validationRules.startTime,
                validate: (value: Date | null) => {
                  if (Number.isNaN(value?.getHours())) {
                    return '入力形式が間違っています。'
                  }

                  const startHours = value?.getHours() || 0
                  const endHours = watch('endTime')?.getHours() || 0
                  const startMinutes = value?.getMinutes() || 0
                  const endMinutes = watch('endTime')?.getMinutes() || 0

                  const result =
                    startHours < endHours ||
                    (startHours === endHours && startMinutes < endMinutes)

                  if (!result)
                    return '開始時間は終了時間より30分以上早く設定してください。'

                  const selectYear = watch('reserveDay')?.getFullYear() || 0
                  const selectMonth = watch('reserveDay')?.getMonth() || 0
                  const selectDay = watch('reserveDay')?.getDate() || 0
                  const reserveDate = new Date(
                    selectYear,
                    selectMonth,
                    selectDay,
                    startHours,
                    startMinutes
                  )
                  const now = new Date()

                  AppLogger.debug(reserveDate)
                  AppLogger.debug(reserveDate.getTime())

                  if (reserveDate.getTime() < now.getTime())
                    return '過去の日時は指定できません。'

                  return true
                },
              })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'startTime' in errors}
                  helperText={errors.startTime?.message}
                  onKeyDown={onKeyDown}
                />
              )}
              onChange={(newValue) => {
                setValue('startTime', newValue)
              }}
              minTime={new Date(0, 0, 0, 9)}
              maxTime={new Date(0, 0, 0, 16, 30)}
              minutesStep={30}
              ampm={false}
            />

            <TimePicker
              disableMaskedInput
              label="終了時間"
              value={watch('endTime')}
              {...register('endTime', validationRules.endTime)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'endTime' in errors}
                  helperText={errors.endTime?.message}
                  onKeyDown={onKeyDown}
                />
              )}
              onChange={(newValue) => {
                setValue('endTime', newValue)
              }}
              minTime={new Date(0, 0, 0, 9, 30)}
              maxTime={new Date(0, 0, 0, 17)}
              minutesStep={30}
              ampm={false}
            />
          </LocalizationProvider>
          <Typography variant="subtitle2">※30分単位で指定してください。</Typography>
        </Stack>
      ),
    },
    {
      number: 2,
      label: '車両の選択',
      optional: mobilityList.find((mobility) => {
        return mobility.mobilityId === watch('reserveMobility')
      })?.mobilityName,
      content:
        // eslint-disable-next-line no-nested-ternary
        loading ? (
          <div />
        ) : mobilityList.length !== 0 ? (
          <TextField
            select
            label="車両"
            sx={{ width: '30vw' }}
            value={watch('reserveMobility')}
            error={'reserveMobility' in errors}
            helperText={errors.reserveMobility?.message}
            {...register('reserveMobility', validationRules.reserveMobility)}
          >
            {mobilityList.map((mobility) => (
              <MenuItem key={mobility.mobilityId} value={mobility.mobilityId}>
                {mobility.mobilityName}
              </MenuItem>
            ))}
            <MenuItem
              key="none"
              value="none"
              sx={{ display: mobilityList.length !== 0 ? 'none' : 'initial' }}
            >
              車両を選択してください
            </MenuItem>
          </TextField>
        ) : (
          <Typography sx={{ color: 'red' }}>
            予約可能な車両がありません。日程の選択からやり直してください。
          </Typography>
        ),
    },
    {
      number: 3,
      label: 'お客様情報の入力',
      optional: watch('email'),
      content: (
        <Stack direction="column" sx={{ width: '50vw' }} spacing={1}>
          <TextField
            label="氏（カナ）"
            defaultValue=""
            error={'lastName' in errors}
            helperText={errors.lastName?.message}
            {...register('lastName', validationRules.lastName)}
          />
          <TextField
            label="名（カナ）"
            defaultValue=""
            error={'firstName' in errors}
            helperText={errors.firstName?.message}
            {...register('firstName', validationRules.firstName)}
          />

          <TextField
            label="Emailアドレス"
            defaultValue=""
            error={'email' in errors}
            helperText={errors.email?.message}
            {...register('email', validationRules.email)}
          />
        </Stack>
      ),
    },
    {
      number: 4,
      label: '最終確認',
      optional: '',
      content: (
        <>
          <Typography>内容をご確認のうえ、予約完了ボタンを押してください。</Typography>
          <Typography>
            <br />
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>利用場所</span>：{' '}
            {
              facilityList.find((facility) => {
                return facility.facilityId === watch('facilityId')
              })?.facilityName
            }
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>予約車両</span>：{' '}
            {
              mobilityList.find((mobility) => {
                return mobility.mobilityId === watch('reserveMobility')
              })?.mobilityName
            }
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>予約日</span>：{' '}
            {watch('reserveDay')?.toLocaleDateString('ja')}
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>開始時間</span>：{' '}
            {getFormatTime(watch('startTime'))}
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>終了時間</span>：{' '}
            {getFormatTime(watch('endTime'))}
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>氏名</span>：{' '}
            {watch('lastName')} {watch('firstName')}
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>E-Mail</span>：{' '}
            {watch('email')}
          </Typography>
          <br />
        </>
      ),
    },
  ]

  const onSubmit: SubmitHandler<ReservationInputs> = (inputs: ReservationInputs) => {
    closeAlert()
    setLoading(true)

    recordEvent(EventType.click_reservation)

    addReservation(inputs)
      .then((response: ReserveResult) => {
        if (response.reservationNumber === '') {
          openErrorAlert(Error('予約期間が重複しています。'))
        } else {
          navigate(`/ReservationComplete/${response.reservationNumber}`)
        }
      })
      .catch((error: Error) => {
        openErrorAlert(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="Reservation">
      <PageTitle title="車両予約" />
      <AppAlert />
      <Box sx={{ padding: 3 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.number}>
              <StepLabel optional={activeStep > step.number ? step.optional : ''}>
                <Typography>{step.label}</Typography>
              </StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {step.content}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      onClick={
                        index === steps.length - 1 ? handleSubmit(onSubmit) : handleNext
                      }
                      sx={{ mt: 1, mr: 1 }}
                      disabled={
                        !!(loading || (activeStep === 2 && mobilityList.length === 0))
                      }
                    >
                      {index === steps.length - 1 ? '予約完了' : '次へ'}
                    </LoadingButton>
                    <Button
                      disabled={index === 0 || loading}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      戻る
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  )
}

export default Reservation
