export const activeStatusList = [
  { active: 0, activeName: '試乗前' },
  { active: 1, activeName: '試乗中' },
  { active: 2, activeName: '返却済み' },
  { active: 3, activeName: '試乗終了' },
  { active: 4, activeName: 'キャンセル済み' },
]

export const getActiveName = (active: number): string => {
  return (
    activeStatusList.find((activeState) => activeState.active === active)?.activeName ||
    ''
  )
}
