import { LatLng } from 'leaflet'
import { useMemo } from 'react'
import { useMapEvents, Polygon } from 'react-leaflet'

import useAreaManagement from '../Hook/useAreaManagement'
import { AreaVertex } from '../Types/AreaManagement'

import AreaVertexMarker from './AreaVertexMarker'

const AreaVertexMarkers = () => {
  const { areaManagementState, addVertex, updateVertex, deleteVertex } =
    useAreaManagement()

  const latLngForPolygon = useMemo(() => {
    const newLatLng: LatLng[] = areaManagementState.editingArea.map((marker) => {
      return new LatLng(marker.latitude, marker.longitude)
    })
    return newLatLng
  }, [areaManagementState.editingArea])

  const isValidArea = useMemo(() => {
    return isValidVertexes(areaManagementState.editingArea)
  }, [areaManagementState.editingArea])

  useMapEvents({
    click: (event) => {
      addVertex({ latitude: event.latlng.lat, longitude: event.latlng.lng })
    },
  })

  return (
    <>
      {areaManagementState.editingArea.map((marker, index) => {
        return (
          <AreaVertexMarker
            key={index}
            index={index}
            position={new LatLng(marker.latitude, marker.longitude)}
            onDrag={updateVertex}
            onDelete={deleteVertex}
          />
        )
      })}
      <Polygon
        pathOptions={{ color: isValidArea ? 'purple' : 'red' }}
        positions={latLngForPolygon}
      />
    </>
  )
}

export default AreaVertexMarkers

// エリア範囲判定ロジックとして、別の場所に切り出したい
export const isValidVertexes = (vertexes: AreaVertex[]) => {
  // 最低でも3点以上ないと、エリア構成できない
  if (vertexes.length <= 2) {
    return false
  }

  // 線分のリスト(特に、配列の先頭と末尾の線分の表現追加)
  const lines = vertexes.map((x, index) => {
    return [index, (index + 1) % vertexes.length]
  })

  // 線分の総当たり判定
  for (let x = 0; x < lines.length - 1; x += 1) {
    for (let y = x + 1; y < lines.length; y += 1) {
      // 隣接している線は判定しない(以下判定式だと、端点が重ねっていると交差と判定されてしまうため)
      if (lines[x].includes(lines[y][0]) || lines[x].includes(lines[y][1])) {
        // eslint-disable-next-line no-continue
        continue
      }

      // 直線ABと直線CDとの交差判定
      const a = vertexes[lines[x][0]]
      const b = vertexes[lines[x][1]]
      const c = vertexes[lines[y][0]]
      const d = vertexes[lines[y][1]]

      const pointCinLineAB =
        (a.latitude - b.latitude) * (c.longitude - a.longitude) -
        (a.longitude - b.longitude) * (c.latitude - a.latitude)
      const pointDinLineAB =
        (a.latitude - b.latitude) * (d.longitude - a.longitude) -
        (a.longitude - b.longitude) * (d.latitude - a.latitude)

      const pointAinLineCD =
        (c.latitude - d.latitude) * (a.longitude - c.longitude) -
        (c.longitude - d.longitude) * (a.latitude - c.latitude)
      const pointBinLineCD =
        (c.latitude - d.latitude) * (b.longitude - c.longitude) -
        (c.longitude - d.longitude) * (b.latitude - c.latitude)

      if (pointCinLineAB * pointDinLineAB < 0 && pointAinLineCD * pointBinLineCD < 0)
        return false
    }
  }

  return true
}
