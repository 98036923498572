import { Stack, Card, TextField, MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'

import SubmitButton from '../../../Components/Common/SubmitButton'
import {
  FacilitySearchCardProps,
  Prefecture,
  SearchFacilityInputs,
} from '../Types/FacilityManagementTypes'

const FacilitySearchCard = (props: FacilitySearchCardProps) => {
  const shizuoka = '22'
  const { register, handleSubmit, watch } = useForm<SearchFacilityInputs>({
    defaultValues: { prefCode: shizuoka },
  })

  props.prefectureList.splice(47, 1)

  return (
    <Card sx={{ padding: 5 }}>
      <Stack direction="column" spacing={3} alignItems="center">
        <TextField
          select
          label="都道府県"
          helperText="都道府県を選択してください"
          value={watch('prefCode')}
          {...register('prefCode', {})}
        >
          {props.prefectureList.map((prefecture: Prefecture) => {
            return (
              <MenuItem value={prefecture.prefCode} key={prefecture.prefCode}>
                {prefecture.prefecture}
              </MenuItem>
            )
          })}
        </TextField>

        <SubmitButton
          onClick={handleSubmit(props.handleSubmit)}
          isLoading={props.isLoading}
          text="検索"
        />
      </Stack>
    </Card>
  )
}

export default FacilitySearchCard
