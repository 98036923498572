import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'

import { AlertContext, ErrorAlert } from '../../Components/Alert/AlertProvider'
import BodyContainer from '../../Components/BodyContainer'
import PageTitle from '../../Components/Common/PageTitle'
import SubmitButton from '../../Components/Common/SubmitButton'

import { setAgreementFlag } from './Repository/QuestionnaireRepository'
import { AgreementProps } from './Types/QuestionnaireTypes'

const Agreement = (props: AgreementProps) => {
  const { dispatch } = useContext(AlertContext)

  const [isLoading, setIsLoading] = useState(false)

  const onClick = () => {
    if (!termsOfServiceChecked || !agreementSignChecked || !explained) return

    setIsLoading(true)
    setAgreementFlag(props.userInfo)
      .then((response) => {
        props.nextScreen()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        props.onClose()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const [termsOfServiceChecked, setTermsOfService] = useState(false)
  const handleChangeTermsOfService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsOfService(event.target.checked)
  }

  const [agreementSignChecked, setAgreementSign] = useState(false)
  const handleChangeAgreementSign = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreementSign(event.target.checked)
  }

  const [explained, setExplained] = useState(false)
  const handleChangeExplained = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExplained(event.target.checked)
  }

  return (
    <BodyContainer>
      <PageTitle title="利用前の確認事項" />
      <Stack spacing={3} padding={3}>
        <Stack spacing={2} padding={2}>
          <Typography variant="h6">サービス利用規約</Typography>
          <TextareaAutosize
            contentEditable={false}
            defaultValue="コネクテッドサービス（見守りサービス）利用規約

スズキ株式会社（以下、スズキといいます。）は、はままつフラワーパークで実施する「KUPO」の実証実験（以下、本実証実験といいます。）試験運用（以下、本試験運用といいます）においてスズキが提供する以下のコネクテッドサービス（見守りサービス）（以下、本サービスといいます。）の利用等に関し、以下の利用規約（以下「本利用規約」といいます。）を定めております。


【コネクテッドサービス（見守りサービス）の内容】
スズキは、車両に搭載された通信機を利用し、ユーザー（※）の走行データ（位置情報を含みます。）を取得します。スズキは、取得したデータを利用して、ユーザの現在位置を確認したり、車両のエラーや転倒を検知します。ユーザは、車両に付属するタブレット端末、又はスマートフォン端末に表示されたWebアプリケーションを利用して、走行ルートを確認したり、緊急時にスズキの実証実験運用担当者へ通知を送ることができます。

（本サービスの利用）
第１条　本サービスを利用する方（以下、ユーザーといいます。）は、本利用規約及び別途スズキから指示された事項に従うことを条件に本サービスを利用することができます。
　②　本サービスは、第５条第２項に該当する場合その他緊急を要する場合又はスズキが事前に通知した場合、その稼働が停止し、利用することができない期間又は時間帯が生じることがあります。

（知的財産権等）
第２条　本サービスにかかる知的財産権その他一切の権利は、スズキ又は第三者に帰属し、本サービスの利用によってユーザーへ移転するものではありません。

（非保証）
第３条　ユーザーは、次の各号に定めることを承諾するものとします。
（１）本サービスにバグ等の欠陥や動作上の不具合が一切存在しないことについてスズキは何らの保証もしないこと
（２）本サービスが第三者の知的財産権その他の権利を一切侵害していないことについてスズキは何らの保証もしないこと

（禁止事項）
第４条　ユーザーは、次の各号の行為をしてはいけません。
（１）本サービスを第三者に利用させ、また、本実証実験に参加させる行為
（２）スズキ又は第三者の権利（第２条によりスズキ又は第三者に帰属するものを含みます。）を侵害又は侵害する恐れのある行為
（３）逆コンパイル、逆アセンブル又はリバースエンジニアリング等により本サービスを解析する行為
（４）本サービスの全部又は一部の再頒布、再販売、貸与又は転売を行う行為
（５）本利用規約、別途スズキから指示された事項又は法令等の定めに違反する行為
（６）その他スズキが不適切と判断する行為

（本サービスの変更・停止等）
第５条　本サービスは、ユーザーに事前に通知されることなく、その全部又は一部が変更されることがあります。
②　本サービスは、以下の各号のいずれかの場合、ユーザーに事前に通知されることなく、その提供が一時的に停止されることがあります。
（１）本サービスのメンテナンスのために必要なとき
（２）コンピュータ、通信回線等の事故、障害によるとき
（３）地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの提供ができなくなったとき
（４）その他の第三者の利益を保護するためにスズキが必要と判断したとき

（本サービスの利用終了）
第６条　次の各号のいずれかに該当した場合、本サービスへのアクセス権が制限される等の方法により、本サービスの利用は終了するものとします。
　（１）ユーザーが本利用規約に定める禁止事項に違反し、スズキが利用権限を取消したとき
　（２）本サービスの運用方法の見直し等により、スズキがユーザーによる利用を中止したとき

（情報の取扱い）
第７条　スズキは、本サービス及び本実証実験を通じて得られる車両の位置情報や走行距離等の情報（ユーザーを特定できる個人情報を含みます。）を、以下の目的の達成に必要な範囲内で、利用します。さらに、スズキは、当該情報につきまして、個人を特定できない形で加工したうえで、公益財団法人浜松市花みどり振興財団に対して、本実証実験の実施・運用・管理やはままつフラワーパークの運営・管理の目的で、国立研究開発法人情報通信研究機構に対して、同機構と実施している情報プラットフォームにかかる共同研究の目的で、その他「KUPO」の実証実験の協力先に対して、「KUPO」の実証実験の実施の目的で、書面又は電子データの形態で提供させていただく場合があります。
　　・本実証実験の実施、運用、管理、評価
　　・スズキの商品開発又は自動車に関連するサービス（本サービスを含みます。）の開発・向上
　②　スズキは、本サービス及び本実証実験を通じて得られる情報のうち、ユーザーの情報については、個人情報の保護に関する法律その他関係法令・ガイドラインに基づいて、適切に管理します。

（本利用規約の改定）
第８条　本利用規約は、スズキにより必要に応じて随時改定されるものとします。
②　前項による本利用規約の改定は、改定後の本利用規約の施行時期及びその内容をユーザーに本サービス又は電子メール等で周知した後、施行時期にその効力を生じるものとします。なお、ユーザーが、効力発生日以降に本サービスを利用したときは、本利用規約の改定に同意したものとみなします。

以上"
          />
        </Stack>

        <Stack spacing={2} padding={2}>
          <Typography variant="h6">同意事項</Typography>
          <TextareaAutosize
            contentEditable={false}
            defaultValue="「「KUPO」実証実験」へのご協力のお願い（兼同意書）
この度は、弊社がはままつフラワーパークで実施する「KUPO」の実証実験（以下、本実証実験といいます。）試験運用（以下、本試験運用といいます）にご協力いただき有難うございます。
本実証実験にご参加いただくに当たり、大変お手数をおかけしますが、下記の【ご留意事項】をお読みいただき、ご同意いただける場合は各項目にチェックのうえ、下記の所定欄にご署名いただきます様お願い申し上げます。
【ご留意事項】
　□　　①年齢が６５歳未満である方、②十分な睡眠をとっていない、または体調不良である方、
③現在、酒気が残った状態である方、④電動車いすの使用に支障を及ぼす身体障害又は健康障害がある方（ペースメーカーを付けている方を含みます。）、⑤医師から電動車いすの使用を控えるよう言われている方につきましては、本実証実験には、ご参加いただけません。
　□　　「説明資料（●●）」の内容を十分ご理解いただいたうえで、お客様自身のご意思によって本実証実験にご参加いただきます。
　□　　本実証実験で使用する弊社の指定する電動車いす「KUPO」、通信機器やコネクテッドサービス（見守りサービス）については、弊社が示す取扱説明書、利用規約、説明資料その他係員の指示事項に従って取り扱います。"
          />
        </Stack>

        <FormControlLabel
          control={
            <Checkbox
              checked={termsOfServiceChecked}
              onChange={handleChangeTermsOfService}
            />
          }
          label="利用規約を読みましたか？"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={agreementSignChecked}
              onChange={handleChangeAgreementSign}
            />
          }
          label="同意書にサインしましたか？"
        />
        <FormControlLabel
          control={<Checkbox checked={explained} onChange={handleChangeExplained} />}
          label="スタッフからKUPOについて説明を受けましたか？"
        />
        {termsOfServiceChecked && agreementSignChecked && explained ? (
          <Typography>OK</Typography>
        ) : (
          <Typography>上記で未チェックのものをご確認ください。</Typography>
        )}
        <div style={{ textAlign: 'center' }}>
          <SubmitButton text="アンケートに進む" onClick={onClick} isLoading={isLoading} />
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button variant="text" sx={{ width: 180 }} onClick={props.onClose}>
            キャンセル
          </Button>
        </div>
      </Stack>
    </BodyContainer>
  )
}

export default Agreement
