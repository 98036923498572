import { useReducer, useMemo } from 'react'

import {
  initialMobilityManagementState,
  MobilityManagementContext,
  MobilityManagementReducer,
} from '../Context/MobilityManagementContext'
import {
  initialSearchMobilityState,
  SearchMobilityContext,
  SearchMobilityReducer,
} from '../Context/SearchMobilityContext'

const MobilityManagementProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchMobilityState, searchMobilityDispatch] = useReducer(
    SearchMobilityReducer,
    initialSearchMobilityState
  )
  const searchMobilityValue = useMemo(
    () => ({ searchMobilityState, searchMobilityDispatch }),
    [searchMobilityState]
  )

  const [mobilityManagementState, mobilityManagementDispatch] = useReducer(
    MobilityManagementReducer,
    initialMobilityManagementState
  )
  const mobilityManagementValue = useMemo(
    () => ({ mobilityManagementState, mobilityManagementDispatch }),
    [mobilityManagementState]
  )

  return (
    <MobilityManagementContext.Provider value={mobilityManagementValue}>
      <SearchMobilityContext.Provider value={searchMobilityValue}>
        {children}
      </SearchMobilityContext.Provider>
    </MobilityManagementContext.Provider>
  )
}

export default MobilityManagementProvider
