import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { LatLngTuple } from 'leaflet'
import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { validationRules } from '../../../Components/Form/ValidationRules'
import { AreaInfo } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import useAreaManagement from '../Hook/useAreaManagement'

import AreaVertexMarkers, { isValidVertexes } from './AreaVertexMarkers'
import { AreaViewsWithoutSelectedArea } from './AreaViews'
import MapCard from './MapCard'

const EditAreaDialog = () => {
  const { areaManagementState, closeEditDialog, updateArea } = useAreaManagement()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<AreaInfo>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      facilityId: '',
      areaId: -1,
      areaName: '',
      areaType: 'danger',
      available: true,
      coordinateList: [],
      previousUpdatedAtForDbVersion: '',
    },
  })

  const [isValidAreaVertexes, setIsValidAreaVertexes] = useState<boolean | null>(null)

  useEffect(() => {
    if (areaManagementState.selectedArea) {
      reset(areaManagementState.selectedArea)
    }
    setIsValidAreaVertexes(null)
  }, [areaManagementState.selectedArea])

  const onUpdate: SubmitHandler<AreaInfo> = (inputs: AreaInfo) => {
    // エリア範囲のバリデーションは単純なフォーマットチェックではなく特殊なため、useFormとは別に独自に実施
    if (!isValidVertexes(areaManagementState.editingArea)) {
      setIsValidAreaVertexes(false)
      return
    }
    setIsValidAreaVertexes(true)

    const newCoordinateList = areaManagementState.editingArea.map((x) => {
      return [x.latitude, x.longitude] as LatLngTuple
    })

    updateArea({
      ...inputs,
      coordinateList: newCoordinateList,
    })
  }

  const memoMap = useMemo(() => {
    return (
      <MapCard
        position={areaManagementState.editingMapSetting.center}
        zoom={areaManagementState.editingMapSetting.zoom}
      >
        <AreaViewsWithoutSelectedArea isEditable={false} />
        <AreaVertexMarkers />
      </MapCard>
    )
  }, [areaManagementState.editingMapSetting])

  return (
    <Dialog open={areaManagementState.isOpenEditDialog} fullWidth maxWidth="lg">
      <DialogTitle>エリア編集</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={7}>
            {memoMap}
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <Stack spacing={2} sx={{ padding: 1 }}>
              <TextField
                label="エリア名"
                value={watch('areaName')}
                error={'areaName' in errors}
                helperText={errors.areaName?.message}
                {...register('areaName', validationRules.areaName)}
              />
              <TextField
                select
                label="タイプ"
                value={watch('areaType')}
                {...register('areaType')}
              >
                {
                  // FIXME:エリアタイプのハードコード
                  ['danger', 'return', 'highlight'].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))
                }
              </TextField>

              {isValidAreaVertexes === false ? (
                // FIXME: MUIのエラー色と同じにしたいが、現状ハードコード
                <Typography color="#d32f2f">
                  エリア範囲を正しく設定してください
                </Typography>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={closeEditDialog}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSubmit(onUpdate)}
          disabled={areaManagementState.isProcessingCommand}
          loading={areaManagementState.isProcessingCommand}
        >
          OK
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditAreaDialog
