import { ListItemText, ListItemIcon, ListItem, ListItemButton, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { MenuLink } from './AppMenu'

type AppMenuListItemProps = {
  menuItem: MenuLink
  onclick: React.MouseEventHandler<HTMLDivElement>
}

const AppMenuListItem = (props: AppMenuListItemProps) => {
  return (
    <ListItem>
      <Link
        component={RouterLink}
        to={props.menuItem.to}
        sx={{ textDecoration: 'none', color: 'gray' }}
      >
        <ListItemButton onClick={props.onclick}>
          <ListItemIcon>{props.menuItem.icon}</ListItemIcon>
          <ListItemText primary={props.menuItem.label} />
        </ListItemButton>
      </Link>
    </ListItem>
  )
}

export default AppMenuListItem
