import { Dialog } from '@mui/material'

type FullScreenDialogProps = {
  isOpen: boolean
  onClose: VoidFunction
  components: React.ReactNode
}
const FullScreenDialog = (props: FullScreenDialogProps) => {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullScreen>
      {props.components}
    </Dialog>
  )
}

export default FullScreenDialog
