/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import driveVideo from '../../Assets/Movies/driveMode.mp4'
import transformationVideo2 from '../../Assets/Movies/driveToPush.mp4'
import pushVideo from '../../Assets/Movies/pushMode.mp4'
import transformationVideo1 from '../../Assets/Movies/pushToDrive.mp4'
import PageTitle from '../../Components/Common/PageTitle'
import { AppLogger } from '../../Logger/AppLogger'

const UserManual = () => {
  const navigate = useNavigate()
  const videoRef = useRef<HTMLVideoElement>(null)

  const onClick = () => {
    if (videoRef.current?.currentTime) {
      AppLogger.debug(`playTime:${String(videoRef.current.currentTime)}`)
    }
    navigate('/Reservation')
  }

  const theme = useTheme()
  const isXsSize = useMediaQuery(theme.breakpoints.down('sm'))
  const videoWidth = isXsSize ? '90%' : '98%'

  return (
    <>
      <PageTitle title="KUPOのご紹介" />
      <Stack
        spacing={4}
        sx={{
          alignItems: 'center',
          paddingBottom: 2,
          paddingTop: 2,
          paddingX: 1,
        }}
      >
        <Typography>
          KUPOは、長時間歩くのに不安を感じるような方の移動をサポートします。
        </Typography>
        <Stack alignItems="center" spacing={4}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={3}>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6">歩行モード</Typography>
              <video
                controls
                playsInline
                ref={videoRef}
                width={videoWidth}
                onClick={() => {
                  AppLogger.debug('movie play!!')
                }}
                style={{ borderRadius: 8 }}
              >
                <source src={pushVideo} type="video/mp4" />
              </video>
            </Stack>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6">乗車モード</Typography>
              <video
                controls
                playsInline
                ref={videoRef}
                width={videoWidth}
                onClick={() => {
                  AppLogger.debug('movie play!!')
                }}
                style={{ borderRadius: 8 }}
              >
                <source src={driveVideo} type="video/mp4" />
              </video>
            </Stack>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={3}>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6">変形①（歩行→乗車）</Typography>
              <video
                controls
                playsInline
                ref={videoRef}
                width={videoWidth}
                onClick={() => {
                  AppLogger.debug('movie play!!')
                }}
                style={{ borderRadius: 8 }}
              >
                <source src={transformationVideo1} type="video/mp4" />
              </video>
            </Stack>

            <Stack alignItems="center" spacing={1}>
              <Typography variant="h6">変形②（乗車→歩行）</Typography>
              <video
                controls
                playsInline
                ref={videoRef}
                width={videoWidth}
                onClick={() => {
                  AppLogger.debug('movie play!!')
                }}
                style={{ borderRadius: 8 }}
              >
                <source src={transformationVideo2} type="video/mp4" />
              </video>
            </Stack>
          </Stack>
        </Stack>

        <Typography variant="body2">
          ※動画は旧モデルのため形状が多少異なります。
        </Typography>
        <Button
          onClick={onClick}
          variant="contained"
          sx={{ borderRadius: 10, width: '20em' }}
        >
          <Typography variant="h6">予約に進む</Typography>
        </Button>
      </Stack>
    </>
  )
}

export default UserManual
