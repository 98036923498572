import { Authenticator } from '@aws-amplify/ui-react'
import { CssBaseline } from '@mui/material'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'

import awsExports from '../aws-exports'

import { recordPageView } from './AppTrack'
import AppAuthenticator from './Components/Authenticator/AppAuthenticator'
import BodyContainer from './Components/BodyContainer'
import AppFooter from './Components/Footer/AppFooter'
import AppHeader from './Components/Header/AppHeader'
import { AppLogger } from './Logger/AppLogger'
import AppProvider from './Provider/AppProvider'

import 'leaflet/dist/leaflet.css'

Amplify.configure(awsExports)

recordPageView()

const App = () => {
  AppLogger.debug('render: App component.')

  return (
    <div className="App">
      <CssBaseline />
      <Authenticator.Provider>
        <AppProvider>
          <AppHeader />
          <BodyContainer>
            <AppAuthenticator />
          </BodyContainer>
          <AppFooter />
        </AppProvider>
      </Authenticator.Provider>
    </div>
  )
}

export default App
