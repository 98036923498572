import { styled } from '@mui/material'
import { Icon } from 'leaflet'
import { memo } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'

import {
  iconImage,
  initialZoomLevel,
  maxZoomLevel,
  scrollWheelZoom,
  tileAttribution,
  tileUrl,
} from '../../UserWatching/MapSettings'
import { MapProps } from '../Types/MobilityMonitoringTypes'

import EmergencyAreas from './EmergencyAreas'
import MapMarkers from './MapMarkers'

const Map = memo((props: MapProps) => {
  Icon.Default.imagePath = iconImage
  const CustomMapContainer = styled(MapContainer)({
    height: props.height,
    borderRadius: 6,
    boxShadow: ' 0 5px 5px 0 rgba(0, 0, 0, .15)',
  })

  return (
    <CustomMapContainer
      center={props.position}
      zoom={initialZoomLevel}
      scrollWheelZoom={scrollWheelZoom}
    >
      <TileLayer
        attribution={tileAttribution}
        url={tileUrl}
        maxZoom={maxZoomLevel}
        maxNativeZoom={maxZoomLevel}
      />
      <MapMarkers />
      <EmergencyAreas />
    </CustomMapContainer>
  )
})

export default Map
