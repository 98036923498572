import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

import useAreaManagement from '../Hook/useAreaManagement'

const DisableAreaDialog = () => {
  const { areaManagementState, closeDisableDialog, disableArea } = useAreaManagement()

  const onDisable = () => {
    if (areaManagementState.selectedArea === null) return

    disableArea(areaManagementState.selectedArea)
  }

  return (
    <Dialog
      open={areaManagementState.isOpenDisableDialog}
      onClose={closeDisableDialog}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        削除確認
      </DialogTitle>
      <DialogContent>
        <DialogContentText>本当に削除してもよろしいですか？</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeDisableDialog} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          onClick={onDisable}
          color="primary"
          disabled={areaManagementState.isProcessingCommand}
          loading={areaManagementState.isProcessingCommand}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DisableAreaDialog
