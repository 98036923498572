import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

import { AppLogger } from '../../Logger/AppLogger'

type ConfirmDialogProps = {
  isOpen: boolean
  onSubmit: VoidFunction
  onClose: VoidFunction
  title: string
  text: string
  isLoading: boolean
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  AppLogger.debug(`render: ConfirmDialog Component.: ${props.title}`)

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          CANCEL
        </Button>
        <LoadingButton
          onClick={props.onSubmit}
          color="primary"
          disabled={props.isLoading}
          loading={props.isLoading}
        >
          OK
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
