import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import { useContext, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

import { AppLogger } from '../../../Logger/AppLogger'
import {
  getStaffInfo,
  updateStaffInfo,
} from '../../../Pages/StaffManagement/Repository/StaffRepository'
import { StaffInfo } from '../../../Pages/StaffManagement/Types/StaffManagementTypes'
import { AlertContext, ErrorAlert, SuccessAlert } from '../../Alert/AlertProvider'
import { validationRules } from '../../Form/ValidationRules'

type Props = {
  open: boolean
  onClose: () => void
}

const StaffInfoDialog = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<StaffInfo>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      staffName: '',
    },
  })
  useEffect(() => {
    getStaffInfo()
      .then((response) => {
        reset(response)
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
  }, [])

  const { dispatch } = useContext(AlertContext)
  const onSubmit: SubmitHandler<StaffInfo> = (inputs: StaffInfo) => {
    dispatch({ type: 'CLOSE' })

    AppLogger.debug(inputs)
    updateStaffInfo(inputs)
      .then((response) => {
        SuccessAlert(dispatch, 'ユーザ情報を更新しました。')
        props.onClose()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        props.onClose()
      })
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>ユーザ情報</DialogTitle>
      <DialogContent>
        <Stack sx={{ padding: 3 }}>
          <TextField
            label="担当者名"
            value={watch('staffName')}
            error={'staffName' in errors}
            helperText={errors.staffName?.message}
            {...register('staffName', validationRules.staffName)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default StaffInfoDialog
