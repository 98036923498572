/* eslint-disable no-param-reassign */
import { useContext, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'

import {
  AlertContext,
  ErrorAlert,
  SuccessAlert,
} from '../../../Components/Alert/AlertProvider'
import { updateUserInfo } from '../Repository/UserDataRepository'
import { UserInfo } from '../Types/UserManagementTypes'

const useUpdateUserInfo = (onClose: VoidFunction) => {
  const { dispatch } = useContext(AlertContext)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<
    UserInfo & {
      startDate: Date | null
      endDate: Date | null
      actualStartDate: Date | null
      actualEndDate: Date | null
    }
  > = (
    inputs: UserInfo & {
      startDate: Date | null
      endDate: Date | null
      actualStartDate: Date | null
      actualEndDate: Date | null
    }
  ) => {
    setIsLoading(true)
    dispatch({ type: 'CLOSE' })

    const scheduledStartTime = inputs.startDate
      ? getTimestampString(inputs.reserveDay, inputs.startDate)
      : ''

    const scheduledEndTime = inputs.endDate
      ? getTimestampString(inputs.reserveDay, inputs.endDate)
      : ''

    const startTime = inputs.actualStartDate
      ? getTimestampString(inputs.reserveDay, inputs.actualStartDate)
      : ''

    const endTime = inputs.actualEndDate
      ? getTimestampString(inputs.reserveDay, inputs.actualEndDate)
      : ''

    const userInfo: UserInfo = {
      ...inputs,
      scheduledStartTime,
      scheduledEndTime,
      startTime,
      endTime,
    }

    updateUserInfo(userInfo)
      .then((response) => {
        const text = { text: '利用者を更新しました。' }
        SuccessAlert(dispatch, text.text)
        onClose()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        onClose()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return [onSubmit, isLoading] as const
}

export default useUpdateUserInfo

const getTimestampString = (dateString: string, time: Date): string => {
  return `${dateString}T${`0${String(time.getHours())}`.slice(-2)}${`0${String(
    time.getMinutes()
  )}`.slice(-2)}${`0${String(time.getSeconds())}`.slice(-2)}${String(
    time.getMilliseconds()
  )}`
}
