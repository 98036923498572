import { Dispatch } from 'react'

import { AlertAction } from '../../../Components/Alert/AlertProvider'
import { searchStaff } from '../Repository/StaffRepository'
import {
  SearchStaffInputs,
  StaffInfo,
  SearchStaffActionType,
} from '../Types/StaffManagementTypes'

export const StaffSearchAction = (
  staffSearchDispatch: Dispatch<SearchStaffActionType>,
  searchInputs: SearchStaffInputs,
  alertDispatch: Dispatch<AlertAction>
) => {
  staffSearchDispatch({ type: 'SEARCH', searchInputs })

  searchStaff(searchInputs)
    .then((searchResult: StaffInfo[]) => {
      staffSearchDispatch({ type: 'SUCCESS', result: searchResult })
    })
    .catch((error: Error) => {
      staffSearchDispatch({ type: 'FAILD', error })
      alertDispatch({
        type: 'OPEN',
        severity: 'error',
        title: error.name,
        text: error.message,
      })
    })
}
