import { useContext } from 'react'

import {
  AlertContext,
  ErrorAlert,
  SuccessAlert,
} from '../../../Components/Alert/AlertProvider'
import { disableUserInfo } from '../Repository/UserDataRepository'
import { UserInfo } from '../Types/UserManagementTypes'

const useDisableUserInfo = (
  onClose: VoidFunction,
  onUpdateClose: VoidFunction,
  inputs: UserInfo
) => {
  const { dispatch } = useContext(AlertContext)

  const userInfo: UserInfo = {
    ...inputs,
  }

  const onSubmit = () => {
    dispatch({ type: 'CLOSE' })
    disableUserInfo(userInfo)
      .then(() => {
        const text = { text: '利用者を削除しました。' }
        SuccessAlert(dispatch, text.text)
        onClose()
        onUpdateClose()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        onClose()
        onUpdateClose()
      })
  }

  return onSubmit
}

export default useDisableUserInfo
