import { useEffect, useState } from 'react'

import Agreement from './Agreement'
import PreQuestionnaire from './PreQuestionnaire'
import { PreRideProps } from './Types/QuestionnaireTypes'

const PreRide = (props: PreRideProps) => {
  const [agree, setAgree] = useState(false)
  const updateAgreement = () => {
    setAgree(!agree)
  }

  useEffect(() => {
    if (props.userInfo.agreementFlag) updateAgreement()
  }, [props.userInfo.agreementFlag])

  return agree ? (
    <PreQuestionnaire onClose={props.onClose} userInfo={props.userInfo} />
  ) : (
    <Agreement
      userInfo={props.userInfo}
      nextScreen={updateAgreement}
      onClose={props.onClose}
    />
  )
}

export default PreRide
