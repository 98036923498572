import { Button, Typography } from '@mui/material'
import { Polygon as PolygonLeaflet } from 'leaflet'
import { useEffect, useMemo, useRef } from 'react'
import { Polygon, Popup } from 'react-leaflet'

import { areaColor } from '../../../Components/Map/Area'
import { AreaInfo } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import useAreaManagement from '../Hook/useAreaManagement'

type AreaViewsProps = {
  isEditable: boolean
  // HACK: 使用しているはずなのに、なぜかlintエラーになる
  // eslint-disable-next-line react/no-unused-prop-types
  onEdit?: (area: AreaInfo) => void
  // eslint-disable-next-line react/no-unused-prop-types
  onDelete?: (area: AreaInfo) => void
}

export const AreaViews = (props: AreaViewsProps) => {
  const { areaManagementState } = useAreaManagement()
  return (
    <AreaViewsContent
      isEditable={props.isEditable}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      areaInfoList={areaManagementState.areaInfoList}
      selectedArea={areaManagementState.selectedArea}
    />
  )
}

export const AreaViewsWithoutSelectedArea = (props: AreaViewsProps) => {
  const { areaManagementState } = useAreaManagement()

  const displayedAreaInfoList = useMemo(() => {
    return areaManagementState.areaInfoList.filter(
      (x) => x.areaId !== areaManagementState.selectedArea?.areaId
    )
  }, [areaManagementState.areaInfoList, areaManagementState.selectedArea])

  return (
    <AreaViewsContent
      isEditable={props.isEditable}
      areaInfoList={displayedAreaInfoList}
      selectedArea={null}
    />
  )
}

type AreaViewsContentProps = AreaViewsProps & {
  areaInfoList: AreaInfo[]
  selectedArea: AreaInfo | null
}

const AreaViewsContent = (props: AreaViewsContentProps) => {
  return (
    <>
      {props.areaInfoList.map((data) => (
        <AreaView
          areaInfo={data}
          key={data.areaId}
          isOpenPopup={data.areaId === props.selectedArea?.areaId}
          isEditable={props.isEditable}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
        />
      ))}
    </>
  )
}

type AreaViewProps = {
  areaInfo: AreaInfo
  isOpenPopup: boolean
  isEditable: boolean
  onEdit?: (area: AreaInfo) => void
  onDelete?: (area: AreaInfo) => void
}

const AreaView = (props: AreaViewProps) => {
  const polygonRef = useRef<PolygonLeaflet | null>(null)

  useEffect(() => {
    if (props.isOpenPopup) {
      polygonRef.current?.openPopup()
    }
  }, [props.isOpenPopup])

  return (
    <Polygon
      // pathOptions={{ color: areaColor(props.areaInfo.areaType) }}
      pathOptions={{ color: areaColor(props.areaInfo) }}
      positions={props.areaInfo.coordinateList}
      key={props.areaInfo.areaId}
      ref={polygonRef}
    >
      <Popup>
        <Typography variant="body2">{`${props.areaInfo.areaId}: ${props.areaInfo.areaName}`}</Typography>
        {!props.isEditable ? null : (
          <>
            <Button
              onClick={() => {
                if (props.onEdit) {
                  props.onEdit(props.areaInfo)
                }
              }}
            >
              EDIT
            </Button>
            <Button
              onClick={() => {
                if (props.onDelete) {
                  props.onDelete(props.areaInfo)
                }
              }}
              color="secondary"
            >
              DELETE
            </Button>
          </>
        )}
      </Popup>
    </Polygon>
  )
}
