/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-1',
  aws_mobile_analytics_app_id: '9b9fafeaecab411999e7e34a8122756c',
  aws_mobile_analytics_app_region: 'ap-northeast-1',
  Analytics: {
    AWSPinpoint: {
      appId: '9b9fafeaecab411999e7e34a8122756c',
      region: 'ap-northeast-1',
    },
  },
  aws_cloud_logic_custom: [
    {
      name: 'Admin',
      endpoint: 'https://m4q73yklp4.execute-api.ap-northeast-1.amazonaws.com/production',
      region: 'ap-northeast-1',
    },
    {
      name: 'AdminQueries',
      endpoint: 'https://809ztfx5o9.execute-api.ap-northeast-1.amazonaws.com/production',
      region: 'ap-northeast-1',
    },
    {
      name: 'AppUser',
      endpoint: 'https://i0zyxpebi1.execute-api.ap-northeast-1.amazonaws.com/production',
      region: 'ap-northeast-1',
    },
    {
      name: 'OpenApi',
      endpoint: 'https://bg4cm2zu29.execute-api.ap-northeast-1.amazonaws.com/production',
      region: 'ap-northeast-1',
    },
    {
      name: 'Staff',
      endpoint: 'https://wmo3j5plx5.execute-api.ap-northeast-1.amazonaws.com/production',
      region: 'ap-northeast-1',
    },
    {
      name: 'SuzukiAdmin',
      endpoint: 'https://3zt6ym6jh7.execute-api.ap-northeast-1.amazonaws.com/production',
      region: 'ap-northeast-1',
    },
  ],
  aws_cognito_identity_pool_id: 'ap-northeast-1:bddf666e-7100-4adc-b6fc-a89a1f56693e',
  aws_cognito_region: 'ap-northeast-1',
  aws_user_pools_id: 'ap-northeast-1_lliZrsYEf',
  aws_user_pools_web_client_id: '6sob5asf3k7jacqad7ucss3lnl',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_dynamodb_all_tables_region: 'ap-northeast-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'AppSettings-production',
      region: 'ap-northeast-1',
    },
    {
      tableName: 'AppUsage-production',
      region: 'ap-northeast-1',
    },
  ],
  aws_user_files_s3_bucket: 'mimo-assets133124-production',
  aws_user_files_s3_bucket_region: 'ap-northeast-1',
}

export default awsmobile
