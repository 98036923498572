import { Typography } from '@mui/material'

import { UserInfo } from '../../UserManagement/Types/UserManagementTypes'
import useMobilityMonitoring from '../Hooks/useMobilityMonitoring'

const AreaJudgeResult = () => {
  const [mobilityMonitoringState] = useMobilityMonitoring()

  return (
    <>
      {mobilityMonitoringState.userDataList
        .filter((item) => {
          return item.dangerousAreaFlag === true
        })
        .map((item: UserInfo) => {
          return (
            <Typography variant="body1" key={item.mobilityId}>
              車両ID:{item.mobilityId}
              が危険エリアに立ち入りました!
            </Typography>
          )
        })}
    </>
  )
}

export default AreaJudgeResult
