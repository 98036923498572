/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { Routes, Route } from 'react-router-dom'

import useUserGroup from '../../Context/useUserGroup'
import { AppLogger } from '../../Logger/AppLogger'
import AppAnalytics from '../../Pages/Analytics/AppAnalytics'
import AreaManagement from '../../Pages/AreaManagement/AreaManagement'
import FacilityManagement from '../../Pages/FacilityManagement/FacilityManagement'
import MobilityManagement from '../../Pages/MobilityManagement/MobilityManagement'
import MobilityMonitoring from '../../Pages/MobilityMonitoring/MobilityMonitoring'
import NoGroup from '../../Pages/NoGroup'
// import UserManual from '../../Pages/Portal/UserManual'
// import Reservation from '../../Pages/Reservation/Reservation'
// import ReservationComplete from '../../Pages/Reservation/ReservationComplete'
// import ReservationUpdateComplete from '../../Pages/Reservation/ReservationUpdateComplete'
// import ReserveConfirmation from '../../Pages/Reservation/ReserveConfirmation'
import StaffManagement from '../../Pages/StaffManagement/StaffManagement'
import UserManagement from '../../Pages/UserManagement/UserManagement'

const AuthenticatedRouter = () => {
  AppLogger.debug('render: AuthenticatedRouter Component.')
  const groupList = useUserGroup()

  return (
    <Routes>
      {groupList.includes('SuzukiAdmin') ? (
        <>
          <Route path="/" element={<UserManagement />} />
          <Route path="/Management" element={<MobilityMonitoring />} />
          <Route path="/Management/:facilityId" element={<MobilityMonitoring />} />
          <Route path="/Staff" element={<StaffManagement />} />
          <Route path="/Mobility" element={<MobilityManagement />} />
          <Route path="/Facility" element={<FacilityManagement />} />
          <Route path="/User" element={<UserManagement />} />
          <Route path="/Area" element={<AreaManagement />} />
          <Route path="/Analytics" element={<AppAnalytics />} />
        </>
      ) : groupList.includes('Admin') ? (
        <>
          <Route path="/" element={<UserManagement />} />
          <Route path="/Management" element={<MobilityMonitoring />} />
          <Route path="/Management/:facilityId" element={<MobilityMonitoring />} />
          <Route path="/Staff" element={<StaffManagement />} />
          <Route path="/User" element={<UserManagement />} />
        </>
      ) : groupList.includes('Staff') ? (
        <>
          <Route path="/" element={<MobilityMonitoring />} />
          <Route path="/Management" element={<MobilityMonitoring />} />
          <Route path="/Management/:facilityId" element={<MobilityMonitoring />} />
          {/* <Route path="/User" element={<UserManagement />} /> */}
        </>
      ) : (
        <Route path="/*" element={<NoGroup />} />
      )}

      {/* <Route path="/" element={<KupoPortal />} /> */}

      {/* <Route path="/Reservation" element={<Reservation />} />
      <Route path="/UserManual" element={<UserManual />} />
      <Route
        path="/ReservationComplete/:reservationNumber"
        element={<ReservationComplete />}
      />
      <Route
        path="/ReservationUpdateComplete/:reservationNumber"
        element={<ReservationUpdateComplete />}
      />
      <Route
        path="/ReserveConfirmation/:reservationNumber/:facilityId/:mobilityId"
        element={<ReserveConfirmation />}
      /> */}
      <Route path="/*" element={<NoGroup />} />
    </Routes>
  )
}

export default AuthenticatedRouter
