import { useReducer, useMemo } from 'react'

import {
  HeaderContext,
  HeaderReducer,
  initialHeaderState,
} from '../Context/HeaderContext'

const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [headerState, headerDispatch] = useReducer(HeaderReducer, initialHeaderState)
  const value = useMemo(() => ({ headerState, headerDispatch }), [headerState])

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
}

export default HeaderProvider
