import { useState } from 'react'

import UserWatchingProvider from '../UserWatching/Provider/UserWatchingProvider'
import UserWatchingContent from '../UserWatching/UserWatchingContent'

import FinishRide from './FinishRide'
import { PostRideProps } from './Types/QuestionnaireTypes'

const PostRide = (props: PostRideProps) => {
  const [rideStatus, setRideStatus] = useState(0)
  const incrimentStatus = () => {
    setRideStatus(rideStatus + 1)
  }

  if (rideStatus === 0)
    return (
      <UserWatchingProvider>
        <UserWatchingContent
          userInfo={props.userInfo}
          nextScreen={incrimentStatus}
          onClose={props.onClose}
        />
      </UserWatchingProvider>
    )

  return <FinishRide userInfo={props.userInfo} onClose={props.onClose} />
}

export default PostRide
