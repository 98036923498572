import { Skeleton } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, jaJP } from '@mui/x-data-grid'
import { useState } from 'react'

import { AppLogger } from '../../../Logger/AppLogger'
import useMobilityManagement from '../Hooks/useMobilityManagement'
import useSearchMobility from '../Hooks/useSearchMobility'
import { initialMobilityInfo, MobilityInfo } from '../Types/MobilityManagementTypes'

import UpdateMobilityDialog from './UpdateMobilityDialog'

const SearchMobilityResult = () => {
  AppLogger.debug('render: SearchMobilityResult Component.')
  const [searchMobilityState] = useSearchMobility()

  const columns: GridColDef[] = [
    { field: 'mobilityId', headerName: '車両ID', width: 100 },
    { field: 'mobilityName', headerName: '車両名', width: 100 },
    { field: 'facilityId', headerName: '施設ID', width: 120 },
    { field: 'imei', headerName: 'IMEI', width: 150 },
    { field: 'available', headerName: '利用可能', width: 100 },
  ]

  // TODO:この情報もFLUX管理で良さそう？
  const [mobilityInfo, setMobilityInfo] = useState<MobilityInfo>(initialMobilityInfo)

  const [, , , , , toggleUpdateDialog, , ,] = useMobilityManagement()

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    const info = params.row as MobilityInfo
    setMobilityInfo(info)
    toggleUpdateDialog()
  }

  return (
    <>
      {searchMobilityState.isLoading ? (
        <Skeleton height="25vh" />
      ) : (
        <DataGrid
          autoHeight
          rows={searchMobilityState.searchResult}
          columns={columns}
          getRowId={(row: MobilityInfo) => row.mobilityId}
          hideFooterSelectedRowCount
          onRowClick={onRowClick}
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
      )}
      <UpdateMobilityDialog mobilityInfo={mobilityInfo} />
    </>
  )
}

export default SearchMobilityResult
