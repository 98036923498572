import { DataGrid, GridColDef, GridEventListener, jaJP } from '@mui/x-data-grid'

import {
  convertTimestampForDisplay,
  getFormatTime,
  timestampToDate,
} from '../../../Common/TimestampConverter'
import { MobilityInfoForDataGrid } from '../../MobilityManagement/Types/MobilityManagementTypes'
import { getActiveName } from '../../UserManagement/Components/activeStatusList'
import useMobilityMonitoring from '../Hooks/useMobilityMonitoring'

import { ControllerErrorList } from './ControllerErrorList'
import { checkLocationDataCondition, calculateUsageTime } from './MapMarkers'

const MobilityList = () => {
  const [mobilityMonitoringState, , checkUser, selectMobility] = useMobilityMonitoring()

  const MobilityListForDataGrid = []
  for (const mobility of mobilityMonitoringState.mobilityInfoList) {
    MobilityListForDataGrid.push({
      ...mobility,
      socString: mobility.soc == null ? '-' : `${mobility.soc}%`,
      updateTimeStamp: mobility.timestamp
        ? convertTimestampForDisplay(mobility.timestamp, false)
        : '',
      errorString: ControllerErrorList.find((error) => {
        return error.id === mobility.errorPriority1
      })?.value,
      userStatus: getActiveName(checkUser(mobility.mobilityId)?.active || -2),
      endTimeString: getFormatTime(
        timestampToDate(checkUser(mobility.mobilityId)?.scheduledEndTime)
      ),
      gpsFlagString: mobility.gpsFlag ? '×' : '○',
      locationDataCondition: checkLocationDataCondition(mobility.timestamp)
        ? ''
        : '位置ズレあり',
      usageTime: calculateUsageTime(mobility.startedAt),
      publisher: mobility.publisher == null ? '-' : `${mobility.publisher}`,
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'displayedId',
      headerName: 'ID',
      width: 40,
      headerAlign: 'center',
      align: 'center',
      renderHeader(params) {
        return <strong>{params.colDef.headerName}</strong>
      },
      renderCell(params) {
        return <strong>{params.value}</strong>
      },
    },
    {
      field: 'mobilityName',
      headerName: '車両名',
      width: 160,
      headerAlign: 'center',
      renderHeader(params) {
        return <strong>{params.colDef.headerName}</strong>
      },
      renderCell(params) {
        return <strong>{params.value}</strong>
      },
    },
    // { field: 'userStatus', headerName: 'ステータス', width: 90 },
    // { field: 'endTimeString', headerName: '返却予定', width: 80 },
    {
      field: 'locationDataCondition',
      headerName: '位置情報',
      width: 112,
      headerAlign: 'center',
      align: 'center',
      renderHeader(params) {
        return <strong>{params.colDef.headerName}</strong>
      },
      renderCell(params) {
        return <strong>{params.value}</strong>
      },
    },
    {
      field: 'usageTime',
      headerName: '利用時間',
      width: 112,
      headerAlign: 'center',
      align: 'center',
      renderHeader(params) {
        return <strong>{params.colDef.headerName}</strong>
      },
      renderCell(params) {
        return <strong>{params.value}</strong>
      },
    },
    {
      field: 'updateTimeStamp',
      headerName: 'データ発生時間',
      width: 144,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'publisher',
      headerName: '送信元',
      width: 88,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'socString',
      headerName: '充電残量',
      width: 88,
      headerAlign: 'center',
      align: 'center',
    },
    // { field: 'gpsFlagString', headerName: 'GPS', width: 80 },
  ]

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    const mobility = params.row as MobilityInfoForDataGrid
    selectMobility(mobility)
  }

  return (
    <DataGrid
      autoHeight
      rows={MobilityListForDataGrid}
      columns={columns}
      getRowId={(row: MobilityInfoForDataGrid) => row.mobilityId}
      onRowClick={onRowClick}
      hideFooterSelectedRowCount
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
    />
  )
}

export default MobilityList
