import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'

import {
  AlertContext,
  ErrorAlert,
  SuccessAlert,
} from '../../../Components/Alert/AlertProvider'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { useFacilityList } from '../../../Context/useFacilityList'
import useUserGroup, { UserGroup } from '../../../Context/useUserGroup'
import useSearchStaffResult from '../Hooks/useSearchStaffResult'
import { updateStaff } from '../Repository/StaffRepository'
import { UpdateStaffDialogProps, StaffUpdateInputs } from '../Types/StaffManagementTypes'

import DisableStaffConfirmDialog from './DisableStaffConfirmDialog'

const UpdateStaffDialog = (props: UpdateStaffDialogProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<StaffUpdateInputs>({
    mode: 'onBlur',
    defaultValues: {
      staffName: '',
      email: '',
      facilityIdList: [],
      groupList: [],
    },
  })

  const facilityState = useFacilityList()
  const groupList = useUserGroup()

  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    reset({
      staffId: props.staffInfo.staffId,
      staffName: props.staffInfo.staffName,
      email: props.staffInfo.email,
      facilityIdList: [],
      groupList: [],
    })
  }, [props.staffInfo])

  const { dispatch } = useContext(AlertContext)
  const [, , fetchResult] = useSearchStaffResult()

  const onSubmit: SubmitHandler<StaffUpdateInputs> = (inputs: StaffUpdateInputs) => {
    dispatch({ type: 'CLOSE' })

    updateStaff(inputs, facilityState.facilityList)
      .then((response) => {
        SuccessAlert(dispatch, '担当者を更新しました。')
        props.onClose()
        fetchResult()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
  }

  const getMultipleSelectErrorMessage = (errorList: FieldError[] | undefined): string => {
    if (!errorList) return ''

    if (!Array.isArray(errorList)) {
      const error = errorList as FieldError
      return error.message || ''
    }

    for (const error of errorList) {
      if (error.message) return error.message
    }
    return ''
  }

  const handleFacilityListChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    setValue(
      'facilityIdList',
      typeof value === 'string' ? value.split(',') : (value as string[])
    )
  }

  const handleGroupListChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    setValue(
      'groupList',
      typeof value === 'string' ? value.split(',') : (value as string[])
    )
  }

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.onClose} fullWidth maxWidth="md">
        <DialogTitle>担当者更新</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ padding: 1 }}>
            <TextField
              label="担当者名"
              value={watch('staffName')}
              error={'staffName' in errors}
              helperText={errors.staffName?.message}
              {...register('staffName', validationRules.staffName)}
            />
            <TextField
              disabled
              value={watch('email')}
              error={'email' in errors}
              helperText={errors.email?.message}
              {...register('email', validationRules.email)}
            />

            <TextField
              select
              SelectProps={{
                multiple: true,
                value: watch('facilityIdList'),
                onChange: handleFacilityListChange,
              }}
              label="施設"
              value={watch('facilityIdList')}
              error={'facilityIdList' in errors}
              helperText={getMultipleSelectErrorMessage(errors.facilityIdList)}
              {...register('facilityIdList', validationRules.facilityIdList)}
            >
              {facilityState.facilityList.map((facility) => (
                <MenuItem key={facility.facilityId} value={facility.facilityId}>
                  {facility.facilityName}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              SelectProps={{
                multiple: true,
                value: watch('groupList'),
                onChange: handleGroupListChange,
              }}
              label="グループ"
              value={watch('groupList')}
              error={'groupList' in errors}
              helperText={getMultipleSelectErrorMessage(errors.groupList)}
              {...register('groupList', validationRules.groupList)}
            >
              {groupList.map((group: UserGroup) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit(onSubmit)} autoFocus>
            Update
          </Button>
          <Button color="primary" onClick={onClose}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <DisableStaffConfirmDialog
        isOpen={isOpen}
        onClose={onClose}
        staffInfo={props.staffInfo}
        onUpdateDialogClose={props.onClose}
      />
    </>
  )
}

export default UpdateStaffDialog
