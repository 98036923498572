import { API } from 'aws-amplify'

import { AppLogger } from '../../../Logger/AppLogger'
import {
  FacilityInfo,
  SearchFacilityInputs,
  Prefecture,
} from '../Types/FacilityManagementTypes'

export const putFacilityInfo = async (facilityInfo: FacilityInfo): Promise<string> => {
  AppLogger.debug('call Api: putFacilityInfo')

  const apiName = 'SuzukiAdmin'
  const path = '/putFacility'
  const myInit = {
    body: {
      facilityInfo,
    },
  }

  return new Promise((resolve, reject) => {
    API.put(apiName, path, myInit)
      .then((response) => {
        resolve('施設を登録しました。')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getPrefectureList = async (): Promise<Prefecture[]> => {
  AppLogger.debug('call Api: getPrefectureList')

  const apiName = 'SuzukiAdmin'
  const path = '/getAllPrefectureList'
  const myInit = {}

  return new Promise((resolve, reject) => {
    API.get(apiName, path, myInit)
      .then((response: Prefecture[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const searchFacilityList = async (
  inputs: SearchFacilityInputs
): Promise<FacilityInfo[]> => {
  AppLogger.debug('call Api: searchFacilityList')

  const apiName = 'SuzukiAdmin'
  const path = '/searchFacilityList'
  const myInit = { body: inputs }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: FacilityInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const disableFacilityInfo = async (
  facilityInfo: FacilityInfo
): Promise<string> => {
  AppLogger.debug('call Api: disableFacilityInfo')

  const apiName = 'SuzukiAdmin'
  const path = '/disableFacility'
  const myInit = {
    body: {
      facilityInfo: {
        ...facilityInfo,
        available: false,
      },
    },
  }

  return new Promise((resolve, reject) => {
    API.put(apiName, path, myInit)
      .then((response) => {
        if (response !== 0) {
          reject(new Error('施設で利用中の車両が存在する為、削除できません。'))
        }
        resolve('施設を削除しました。')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
