import { LatLngExpression } from 'leaflet'

export const iconImage = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

export const tileAttribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

export const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

export const initialZoomLevel = 13
export const maxZoomLevel = 19

export const scrollWheelZoom = true

// 初期値はフラワーパーク
export const initialLocation: LatLngExpression = [34.693347, 137.686701]
