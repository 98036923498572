import { Auth } from 'aws-amplify'

import { UserGroup } from '../Context/useUserGroup'
import { AppLogger } from '../Logger/AppLogger'

export const getAuthorizationToken = async (): Promise<string> => {
  return `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
}

export const getCurrentUserPoolGroupList = async (): Promise<UserGroup[]> => {
  AppLogger.debug('call function: getCurrentUserPoolGroupList')
  try {
    const appUser = await Auth.currentSession()
    const { payload } = appUser.getIdToken()
    return payload['cognito:groups'] as UserGroup[]
  } catch {
    return []
  }
}
