import { useContext } from 'react'

import { UserGroupContext } from './UserGroupContext'

export type UserGroup = 'Admin' | 'Staff' | 'SuzukiAdmin'

const useUserGroup = () => {
  const { userGroupState } = useContext(UserGroupContext)

  return userGroupState.userGroupList
}

export default useUserGroup
