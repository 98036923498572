import { createContext, Dispatch } from 'react'

import {
  MobilityInfo,
  MobilityInfoForDataGrid,
} from '../../MobilityManagement/Types/MobilityManagementTypes'
import { UserInfo } from '../../UserManagement/Types/UserManagementTypes'
import { AreaJudgement, Step, AreaInfo } from '../Types/MobilityMonitoringTypes'

export type MobilityMonitoringState = {
  mobilityInfoList: MobilityInfoForDataGrid[]
  areaInfoList: AreaInfo[]
  userDataList: UserInfo[]
  stepList: Step[]
  areaJudgementList: AreaJudgement[]
  isLoading: boolean
  error: Error | null
  selectedMobiliytOnList: MobilityInfo | null
}

export const initialMobilityMonitoringState: MobilityMonitoringState = {
  mobilityInfoList: [],
  areaInfoList: [],
  userDataList: [],
  stepList: [],
  areaJudgementList: [],
  isLoading: false,
  error: null,
  selectedMobiliytOnList: null,
}

export type MobilityMonitoringAction =
  | { type: 'FETCH' }
  | { type: 'MOBILITY'; result: MobilityInfoForDataGrid[] }
  | { type: 'EMERGENCY'; result: AreaInfo[] }
  | { type: 'USER'; result: UserInfo[] }
  | { type: 'STEP'; result: Step[] }
  | { type: 'AREAJUDGE'; result: AreaJudgement[] }
  | { type: 'FAILD'; error: Error }
  | { type: 'SELECT'; mobilityInfo: MobilityInfo }

export const MobilityMonitoringContext = createContext(
  {} as {
    mobilityMonitoringState: MobilityMonitoringState
    mobilityMonitoringDispatch: Dispatch<MobilityMonitoringAction>
  }
)

export const MobilityMonitoringReducer = (
  state: MobilityMonitoringState,
  action: MobilityMonitoringAction
): MobilityMonitoringState => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, isLoading: true }
    case 'MOBILITY':
      return { ...state, isLoading: false, mobilityInfoList: action.result }
    case 'EMERGENCY':
      return { ...state, isLoading: false, areaInfoList: action.result }
    case 'USER':
      return { ...state, isLoading: false, userDataList: action.result }
    case 'STEP':
      return { ...state, isLoading: false, stepList: action.result }
    case 'AREAJUDGE':
      return { ...state, isLoading: false, areaJudgementList: action.result }
    case 'FAILD':
      return { ...state, isLoading: false, error: action.error }
    case 'SELECT':
      return { ...state, isLoading: false, selectedMobiliytOnList: action.mobilityInfo }
    default:
      return state
  }
}
