import { SearchStaffState, SearchStaffActionType } from '../Types/StaffManagementTypes'

export const SearchStaffReducer = (
  state: SearchStaffState,
  action: SearchStaffActionType
): SearchStaffState => {
  switch (action.type) {
    case 'SEARCH':
      return { ...state, isLoading: true, searchInputs: action.searchInputs }
    case 'SUCCESS':
      return { ...state, isLoading: false, searchResult: action.result }
    case 'FAILD':
      return { ...state, isLoading: false, error: action.error }
    default:
      return state
  }
}
