import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'

import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import AeonQuestionnaire from '../../Questionnaire/AeonQuestionnaire'
import { incrementActive } from '../../Questionnaire/Repository/QuestionnaireRepository'
import { setStartEndTime } from '../../UserWatching/Repository/UserWatchingRepository'
import { getUserInfo } from '../Repository/UserDataRepository'
import { UserInfo } from '../Types/UserManagementTypes'

import { getActiveName } from './activeStatusList'
import FullScreenDialog from './FullScreenDialog'
import UpdateUserDialog from './UpdateUserDialog'
import { UserFlowDialogProps } from './UserFlowDialog'

const AeonUserFlowDialog = (props: UserFlowDialogProps) => {
  const [userInfo, setUserInfo] = useState(props.userInfo)
  const { dispatch } = useContext(AlertContext)
  const [isLoading, setIsLoading] = useState(false)
  const [questionaireIsOpen, setQuestionaireIsOpen] = useState(false)
  const [updateIsOpen, setUpdateIsOpen] = useState(false)

  const toggleQuestionaireDialog = () => setQuestionaireIsOpen(!questionaireIsOpen)
  const toggleUpdateDialog = () => setUpdateIsOpen(!updateIsOpen)

  useEffect(() => {
    if (props.isOpen) {
      getUserInfo(props.userId)
        .then((response: UserInfo) => {
          setUserInfo(response)
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })
    }
  }, [props.isOpen, questionaireIsOpen, updateIsOpen])

  const onStartClicked = () => {
    // ステータスを更新する
    setIsLoading(true)
    incrementActive(userInfo)
      .then(() => {
        // 最新のUserInfo取得
        getUserInfo(props.userId)
          .then((response: UserInfo) => {
            setUserInfo(response)
          })
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
          })
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
      .finally(() => {
        setIsLoading(false)
      })

    // 開始時間保存する
    setStartEndTime('start', props.userId).catch((error: Error) => {
      ErrorAlert(dispatch, error)
      props.onClose()
    })
  }

  const onFinishClicked = () => {
    // ステータスを更新する
    setIsLoading(true)
    incrementActive(userInfo)
      .then(() => {
        // 最新のUserInfo取得
        getUserInfo(props.userId)
          .then((response: UserInfo) => {
            setUserInfo(response)
          })
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
          })
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
      .finally(() => {
        setIsLoading(false)
      })

    // 終了時間保存する
    setStartEndTime('end', props.userId).catch((error: Error) => {
      ErrorAlert(dispatch, error)
      props.onClose()
    })
  }

  const onQuestionnaireClicked = () => {
    // アンケート画面を開く
    toggleQuestionaireDialog()
    // アンケート終わると、アンケートがDBに保存されて、ステータスが更新されます
  }

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="lg">
        <DialogTitle>利用の流れ</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography>{`利用者：${userInfo.lastName} ${userInfo.firstName}`}</Typography>
            <Typography>{`利用ステータス：${getActiveName(userInfo.active)}`}</Typography>

            <LoadingButton
              variant="contained"
              sx={{ borderRadius: 10, padding: 1, width: '17em' }}
              onClick={onStartClicked}
              disabled={userInfo.active !== 0}
              loading={isLoading && userInfo.active === 0}
            >
              利用開始
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ borderRadius: 10, padding: 1, width: '17em' }}
              onClick={onFinishClicked}
              disabled={userInfo.active !== 1}
              loading={isLoading && userInfo.active === 1}
            >
              利用終了
            </LoadingButton>
            <Button
              variant="contained"
              sx={{ borderRadius: 10, padding: 1, width: '17em' }}
              onClick={onQuestionnaireClicked}
              disabled={userInfo.active !== 2}
            >
              アンケート回答
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ marginBottom: 1, marginX: 1 }}>
          <Stack direction="row" spacing={2}>
            <Button color="secondary" onClick={props.onClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={toggleUpdateDialog}>
              編集
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <FullScreenDialog
        isOpen={questionaireIsOpen}
        onClose={toggleQuestionaireDialog}
        components={
          <AeonQuestionnaire userInfo={userInfo} onClose={toggleQuestionaireDialog} />
        }
      />
      <UpdateUserDialog
        isOpen={updateIsOpen}
        onClose={toggleUpdateDialog}
        userInfo={userInfo}
        userId={userInfo.userId}
      />
    </>
  )
}

export default AeonUserFlowDialog
