import { Stack, Card, TextField, MenuItem } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useForm } from 'react-hook-form'

import SubmitButton from '../../../Components/Common/SubmitButton'
import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { useFacilityList } from '../../../Context/useFacilityList'
import { SearchUserCardProps, SearchUserInputs } from '../Types/UserManagementTypes'

import { activeStatusList } from './activeStatusList'

const SearchUserCard = (props: SearchUserCardProps) => {
  const facilityState = useFacilityList()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<SearchUserInputs>({
    defaultValues: {
      facilityId: facilityState.facilityList[0]?.facilityId || '',
      active: 0,
      startDay: new Date(),
      endDay: new Date(),
    },
  })

  return (
    <Card sx={{ padding: 5 }}>
      <Stack direction="column" spacing={3} alignItems="center">
        <SelectFacilityForm
          value={watch('facilityId')}
          error={'facilityId' in errors}
          helperText={errors.facilityId?.message}
          register={register('facilityId', validationRules.facilityId)}
        />

        <TextField
          select
          label="ステータス"
          value={watch('active')}
          error={'active' in errors}
          helperText={errors.active?.message}
          {...register('active', validationRules.active)}
        >
          <MenuItem key={-1} value={-1}>
            すべて
          </MenuItem>
          {activeStatusList.map((activeStatus) => (
            <MenuItem key={activeStatus.active} value={activeStatus.active}>
              {activeStatus.activeName}
            </MenuItem>
          ))}
        </TextField>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="日付選択（〜日から）"
              value={watch('startDay')}
              inputFormat="yyyy/MM/dd"
              mask="____/__/__"
              {...register('startDay', validationRules.reserveDay)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'reserveDay' in errors}
                  helperText={errors.startDay?.message}
                />
              )}
              onChange={(newValue) => {
                setValue('startDay', newValue)
              }}
              maxDate={new Date(2023, 3 - 1, 31)}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="日付選択（〜日まで）"
              value={watch('endDay')}
              inputFormat="yyyy/MM/dd"
              mask="____/__/__"
              {...register('endDay', validationRules.reserveDay)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'endDay' in errors}
                  helperText={errors.endDay?.message}
                />
              )}
              onChange={(newValue) => {
                setValue('endDay', newValue)
              }}
              maxDate={new Date(2023, 3 - 1, 31)}
            />
          </LocalizationProvider>
        </Stack>
        <SubmitButton
          isLoading={props.isLoading}
          onClick={handleSubmit(props.onSearchUserSubmit)}
          text="検索"
        />
      </Stack>
    </Card>
  )
}

export default SearchUserCard
