import { createContext, Dispatch } from 'react'

import { Prefecture } from '../Pages/FacilityManagement/Types/FacilityManagementTypes'

export type PrefectureListState = {
  prefectureList: Prefecture[]
  isLoading: boolean
  error: Error | null
}

export const initialPrefectureListState: PrefectureListState = {
  prefectureList: [{ prefCode: '22', prefecture: '静岡県' }],
  isLoading: false,
  error: null,
}

export type PrefectureListAction =
  | { type: 'FETCH' }
  | { type: 'SUCCESS'; result: Prefecture[] }
  | { type: 'FAILD'; error: Error }

export const PrefectureListContext = createContext(
  {} as {
    prefectureListState: PrefectureListState
    prefectureListDispatch: Dispatch<PrefectureListAction>
  }
)

export const PrefectureListReducer = (
  state: PrefectureListState,
  action: PrefectureListAction
): PrefectureListState => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, isLoading: true }
    case 'SUCCESS':
      return { ...state, isLoading: false, prefectureList: action.result }
    case 'FAILD':
      return { ...state, isLoading: false, error: action.error }
    default:
      return state
  }
}
