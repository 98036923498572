import { Authenticator, translations } from '@aws-amplify/ui-react'
import { Stack, Typography } from '@mui/material'
import { I18n } from 'aws-amplify'
import { Routes, Route } from 'react-router-dom'

import NoGroup from '../../Pages/NoGroup'
import UserManual from '../../Pages/Portal/UserManual'
import Reservation from '../../Pages/Reservation/Reservation'
import ReservationComplete from '../../Pages/Reservation/ReservationComplete'
import ReservationUpdateComplete from '../../Pages/Reservation/ReservationUpdateComplete'
import ReserveConfirmation from '../../Pages/Reservation/ReserveConfirmation'

I18n.putVocabularies(translations)
I18n.setLanguage('ja')

const NotAuthenticatedRouter = () => {
  return (
    <Routes>
      <Route path="/Management" element={<CustomAuthenticator />} />
      {/* <Route path="/" element={<KupoPortal />} /> */}
      <Route path="/" element={<CustomAuthenticator />} />
      <Route path="/Reservation" element={<Reservation />} />
      <Route path="/UserManual" element={<UserManual />} />
      <Route
        path="/ReservationComplete/:reservationNumber"
        element={<ReservationComplete />}
      />
      <Route
        path="/ReservationUpdateComplete/:reservationNumber"
        element={<ReservationUpdateComplete />}
      />
      <Route
        path="/ReserveConfirmation/:reservationNumber/:facilityId/:mobilityId"
        element={<ReserveConfirmation />}
      />
      <Route path="/*" element={<NoGroup />} />
    </Routes>
  )
}

export default NotAuthenticatedRouter

const CustomAuthenticator = () => {
  return (
    <Stack spacing={6} sx={{ marginTop: '6vh', textAlign: 'center' }}>
      <Typography variant="h6">こちらの画面は施設管理者向けです。</Typography>
      <Authenticator hideSignUp />
    </Stack>
  )
}
