import { API } from 'aws-amplify'

import { SearchUserInfo } from '../Hooks/useSearchUserResult'
import { UserInfo } from '../Types/UserManagementTypes'

export const searchUserList = async (
  facilityId: string,
  active: number,
  startDay: string,
  endDay: string
): Promise<SearchUserInfo[]> => {
  const apiName = 'Staff'
  const path = '/searchUserList'

  const myInit = {
    body: {
      facilityId,
      active,
      startDay,
      endDay,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: SearchUserInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getUserInfo = async (userId: string): Promise<UserInfo> => {
  const apiName = 'Staff'
  const path = '/getUserInfo'
  const myInit = {
    body: { userId },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: UserInfo) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const putUserInfo = async (userInfo: UserInfo): Promise<number> => {
  const apiName = 'Staff'
  const path = '/putUserInfo'

  const myInit = {
    body: { userInfo },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: number) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const updateUserInfo = async (userInfo: UserInfo): Promise<number> => {
  const apiName = 'Staff'
  const path = '/updateUserInfo'

  const myInit = {
    body: {
      userInfo,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: number) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const disableUserInfo = (userInfo: UserInfo) => {
  const apiName = 'Staff'
  const path = '/disableUserInfo'

  const myInit = {
    body: {
      userInfo,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
