import { indigo, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const AppTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: indigo[600],
    },
    secondary: {
      main: red.A400,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Noto Sans JP', 'Roboto', 'sans-serif', 'Yu Gothic'].join(','),
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          maxWidth: 300,
          width: '60vw',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          maxWidth: 300,
        },
      },
    },
  },
})

export default AppTheme
