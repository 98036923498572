import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Button,
  DialogActions,
} from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { AppLogger } from '../../../Logger/AppLogger'
import useMobilityManagement from '../Hooks/useMobilityManagement'
import { UpdateMobilityDialogProps, MobilityInfo } from '../Types/MobilityManagementTypes'

import DisableMobilityDialog from './DisableMobilityDialog'

const UpdateMobilityDialog = (props: UpdateMobilityDialogProps) => {
  AppLogger.debug('render: UpdateMobilityDialog Component.')

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<MobilityInfo>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: props.mobilityInfo,
  })

  useEffect(() => {
    reset(props.mobilityInfo)
  }, [reset, props])

  const [
    mobilityManagementState,
    ,
    onUpdate,
    ,
    ,
    toggleUpdateDialog,
    toggleDisableialog,
    ,
  ] = useMobilityManagement()

  return (
    <>
      <Dialog
        open={mobilityManagementState.updateDialog}
        onClose={toggleUpdateDialog}
        maxWidth="md"
      >
        <DialogTitle>車両情報編集</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ padding: 1 }}>
            <TextField
              disabled
              label="車両ID"
              value={watch('mobilityId')}
              error={'mobilityId' in errors}
              helperText={errors.mobilityId?.message}
              {...register('mobilityId', validationRules.mobilityId)}
            />
            <TextField
              label="車両名"
              value={watch('mobilityName')}
              error={'mobilityName' in errors}
              helperText={errors.mobilityName?.message}
              {...register('mobilityName', validationRules.mobilityName)}
            />
            <TextField
              label="IMEI"
              value={watch('imei')}
              error={'imei' in errors}
              helperText={errors.imei?.message}
              {...register('imei', validationRules.imei)}
            />

            <SelectFacilityForm
              value={watch('facilityId')}
              error={'facilityId' in errors}
              helperText={errors.facilityId?.message}
              register={register('facilityId', validationRules.facilityId)}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={toggleUpdateDialog}>
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSubmit(onUpdate)}
            disabled={mobilityManagementState.isLoading}
            loading={mobilityManagementState.isLoading}
          >
            Update
          </LoadingButton>
          <Button
            color="primary"
            onClick={toggleDisableialog}
            disabled={mobilityManagementState.isLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <DisableMobilityDialog mobilityInfo={props.mobilityInfo} />
    </>
  )
}

export default UpdateMobilityDialog
