import { styled } from '@mui/material'
import { Icon } from 'leaflet'
import { memo } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'

import {
  iconImage,
  initialZoomLevel,
  maxZoomLevel,
  scrollWheelZoom,
  tileAttribution,
  tileUrl,
} from '../MapSettings'
import { UserMapProps } from '../Types/UserWatchingTypes'

import UserEmergencyAreas from './UserEmergencyAreas'
import UserMarker from './UserMarker'

const UserMap = memo((props: UserMapProps) => {
  Icon.Default.imagePath = iconImage
  const CustomMapContainer = styled(MapContainer)({
    height: props.height,
  })

  return (
    <CustomMapContainer
      center={props.position}
      zoom={initialZoomLevel}
      scrollWheelZoom={scrollWheelZoom}
    >
      <TileLayer
        attribution={tileAttribution}
        url={tileUrl}
        maxZoom={maxZoomLevel}
        maxNativeZoom={maxZoomLevel}
      />
      <UserMarker
        facilityId={props.facilityId}
        mobilityId={props.mobilityId}
        userId={props.userId}
        scheduledEndTime={props.scheduledEndTime}
      />
      <UserEmergencyAreas facilityId={props.facilityId} />
    </CustomMapContainer>
  )
})

export default UserMap
