import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { AlertContext, ErrorAlert } from '../../Components/Alert/AlertProvider'
import BodyContainer from '../../Components/BodyContainer'
import PageTitle from '../../Components/Common/PageTitle'
import SubmitButton from '../../Components/Common/SubmitButton'
import { AppLogger } from '../../Logger/AppLogger'

import {
  incrementActive,
  putPreQuestionnaire,
} from './Repository/QuestionnaireRepository'
import { PreQuestionnaireProps, InputPreQuestionnaire } from './Types/QuestionnaireTypes'

const PreQuestionnaire = (props: PreQuestionnaireProps) => {
  const initValue = {
    userId: props.userInfo.userId,
    q1: props.userInfo.lastName + props.userInfo.firstName,
    q2: '',
    q3: '',
    q4_1: '',
    q4_2: '',
    q5_1: '',
    q5_2: '',
    q5_3: '',
    q6: '',
    q7: '',
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
  } = useForm<InputPreQuestionnaire>({
    defaultValues: initValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const handleCheck = (
    option: string,
    id: 'q4_1',
    event: React.SyntheticEvent,
    notApplicableValue: string
  ) => {
    // 該当idのvalueから文字列を生成
    let values: string[] = getValues(id).split(',')

    // 配列valuesから空文字の削除
    values = Array.from(values).filter((v) => v)

    let newValues: string[] = []

    // 該当なしが押されたかチェック
    if (option === notApplicableValue) {
      if (!values.includes(option)) {
        newValues = [option]
      } else {
        newValues = []
      }
      // 該当なしが押されていない場合
      // 該当なしがvalueに含まれているかチェック
    } else if (!values.includes(notApplicableValue)) {
      // valueにチェックしたoptionが含まれていない場合、newValuesに追加する
      if (!values.includes(option)) {
        newValues = [...(values ?? []), option]
        newValues.sort()
      } else {
        // 含まれていた場合は、newValuesから削除する
        newValues = values.filter((v) => v !== option)
      }
    } else {
      // 該当なしが含まれている場合
      newValues = [option]
    }

    // 該当idのvalueにnewValueをセットする
    setValue(id, newValues.join(','))

    AppLogger.debug(newValues)

    return newValues.join(',')
  }

  const { dispatch } = useContext(AlertContext)

  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<InputPreQuestionnaire> = (
    inputs: InputPreQuestionnaire
  ) => {
    setIsLoading(true)
    AppLogger.debug(inputs)
    putPreQuestionnaire(inputs)
      .then((response) => {
        incrementActive(props.userInfo)
          .then(() => {
            AppLogger.debug('complete pre questionnaire.')
          })
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
          })
          .finally(() => {
            setIsLoading(false)
            props.onClose()
          })
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        props.onClose()
      })
  }

  return (
    <div className="PreQuestionnaire">
      <BodyContainer>
        <Card
          sx={{
            paddingTop: '3vh',
            paddingBottom: '3vh',
            paddingLeft: '3vw',
            paddingRight: '3vw',
            marginTop: '3vh',
          }}
        >
          <PageTitle title="試乗前アンケート" />
          <Stack spacing={5}>
            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q1' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                >
                  1. 名前（カナ）
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q1?.message}
                </FormHelperText>
                <TextField
                  sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                  label="氏名を入力"
                  InputProps={{ style: { fontSize: '1.2rem' } }}
                  InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                  required
                  value={watch('q1')}
                  error={'q1' in errors}
                  {...register('q1', { required: '必須項目です。' })}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q2' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  2. 年齢
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q2?.message}
                </FormHelperText>
                <Controller
                  name="q2"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>〜59歳</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>60〜64歳</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>65〜69歳</Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>70〜74歳</Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>75〜79歳</Typography>
                        }
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>80〜84歳</Typography>
                        }
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>85〜89歳</Typography>
                        }
                      />
                      <FormControlLabel
                        value="7"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>90歳〜</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q3' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  3. 性別
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q3?.message}
                </FormHelperText>
                <Controller
                  name="q3"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>男性</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>女性</Typography>}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>回答しない</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q4_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  4.
                  外出について、週に2回以上おこなっているものをお答えください。（複数回答可）
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q4_1?.message}
                </FormHelperText>
                <FormGroup row>
                  <Controller
                    name="q4_1"
                    control={control}
                    rules={{
                      required: '必須項目です。',
                      onChange: () => {
                        if (!watch('q4_1').split(',').includes('5')) {
                          setValue('q4_2', '')
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>散歩</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('0', 'q4_1', event, '6'))
                          }
                          checked={getValues('q4_1').split(',').includes('0')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>買い物</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('1', 'q4_1', event, '6'))
                          }
                          checked={getValues('q4_1').split(',').includes('1')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>習い事</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('2', 'q4_1', event, '6'))
                          }
                          checked={getValues('q4_1').split(',').includes('2')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>スポーツ</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('3', 'q4_1', event, '6'))
                          }
                          checked={getValues('q4_1').split(',').includes('3')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>通院</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('4', 'q4_1', event, '6'))
                          }
                          checked={getValues('q4_1').split(',').includes('4')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('5', 'q4_1', event, '6'))
                          }
                          checked={getValues('q4_1').split(',').includes('5')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>該当なし</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(handleCheck('6', 'q4_1', event, '6'))
                          }
                          checked={getValues('q4_1').split(',').includes('6')}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>

              {watch('q4_1').split(',').includes('5') ? (
                <FormControl required error={'q4_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「その他」と答えた方へお伺いします。週に2回以上おこなっているものをお答えください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q4_2?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q4_2' in errors}
                    {...register('q4_2', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q5_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  5. 食料品や日用品の買い物に行く際の、主な交通手段を教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q5_1?.message}
                </FormHelperText>
                <Controller
                  name="q5_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q5_1') !== '5') {
                        setValue('q5_2', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            徒歩（シルバーカー・杖なし）
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            徒歩（シルバーカー・杖あり）
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>自転車</Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>自家用車</Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>タクシー</Typography>
                        }
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                        }
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            買い物には行かない
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q5_1') === '5' ? (
                <FormControl required error={'q5_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「その他」と答えた方へお伺いします。主な交通手段を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q5_2?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q5_2' in errors}
                    {...register('q5_2', { required: '必須項目です。' })}
                    // fullWidthが効かない
                    fullWidth
                  />
                </FormControl>
              ) : (
                ''
              )}

              <FormControl required error={'q5_3' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    marginTop: '5vh',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  上記の移動手段を使った場合、片道何分かかりますか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q5_3?.message}
                </FormHelperText>
                <Controller
                  name="q5_3"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>10分以内</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>30分以内</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>60分以内</Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>それ以上</Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>わからない</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q6' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  6.
                  来場前にWeb上でKUPOをご予約された方へ質問です。予約の途中で、どのマニュアルをご覧になりましたか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q6?.message}
                </FormHelperText>
                <Controller
                  name="q6"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            動画を確認した
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            文書を確認した
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            動画と文書の両方を確認した
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q7' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  7. 製品を購入する際に、JISマークの有無を気にしますか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q7?.message}
                </FormHelperText>
                <Controller
                  name="q7"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>はい</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>いいえ</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <div style={{ textAlign: 'center' }}>
              <SubmitButton
                text="回答する"
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
              />
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button
                variant="text"
                sx={{ borderRadius: 10, width: '10em' }}
                onClick={props.onClose}
              >
                キャンセル
              </Button>
            </div>
          </Stack>
        </Card>
      </BodyContainer>
    </div>
  )
}

export default PreQuestionnaire
