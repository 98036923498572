import { useAuthenticator } from '@aws-amplify/ui-react'
import { Dispatch, useContext, useEffect } from 'react'

import { AlertAction, AlertContext, ErrorAlert } from '../Components/Alert/AlertProvider'
import { getPrefectureList } from '../Pages/FacilityManagement/Repository/FacilityRepository'
import { Prefecture } from '../Pages/FacilityManagement/Types/FacilityManagementTypes'
import { getFacilityList } from '../Pages/StaffManagement/Repository/StaffRepository'
import { StaffFacilityRelation } from '../Pages/StaffManagement/Types/StaffManagementTypes'
import { getCurrentUserPoolGroupList } from '../Repository/AuthRepository'

import { FacilityListAction, FacilityListContext } from './FacilityListContext'
import { PrefectureListAction, PrefectureListContext } from './PrefectureListContext'
import { UserGroupAction, UserGroupContext } from './UserGroupContext'
import { UserGroup } from './useUserGroup'

export const useInitializeApp = () => {
  const { facilityListState, facilityListDispatch } = useContext(FacilityListContext)
  const { prefectureListState, prefectureListDispatch } =
    useContext(PrefectureListContext)
  const { userGroupState, userGroupDispatch } = useContext(UserGroupContext)
  const { dispatch } = useContext(AlertContext)
  const { route } = useAuthenticator((context) => [context.route])

  useEffect(() => {
    if (route === 'authenticated') {
      if (!facilityListState.isLoading) fetchFacility(facilityListDispatch, dispatch)
      if (
        !prefectureListState.isLoading &&
        userGroupState.userGroupList.includes('SuzukiAdmin')
      )
        fetchPrefectureList(prefectureListDispatch, dispatch)
      if (!userGroupState.isLoading) fetchUserGroup(userGroupDispatch, dispatch)
    } else if (!userGroupState.isLoading) fetchUserGroup(userGroupDispatch, dispatch)
  }, [route])
}

const fetchFacility = (
  facilityListDispatch: Dispatch<FacilityListAction>,
  alertDispatch: Dispatch<AlertAction>
) => {
  facilityListDispatch({ type: 'FETCH' })
  alertDispatch({ type: 'CLOSE' })

  getFacilityList()
    .then((response: StaffFacilityRelation[]) => {
      facilityListDispatch({ type: 'SUCCESS', result: response })
    })
    .catch((error: Error) => {
      facilityListDispatch({ type: 'FAILD', error })
      ErrorAlert(alertDispatch, error)
    })
}

const fetchUserGroup = (
  userGroupDispatch: Dispatch<UserGroupAction>,
  alertDispatch: Dispatch<AlertAction>
) => {
  userGroupDispatch({ type: 'FETCH' })

  getCurrentUserPoolGroupList()
    .then((response: UserGroup[]) => {
      userGroupDispatch({ type: 'SUCCESS', result: response })
    })
    .catch((error: Error) => {
      userGroupDispatch({ type: 'FAILD', error })
      ErrorAlert(alertDispatch, error)
    })
}

const fetchPrefectureList = (
  prefectureListDispatch: Dispatch<PrefectureListAction>,
  alertDispatch: Dispatch<AlertAction>
) => {
  prefectureListDispatch({ type: 'FETCH' })

  getPrefectureList()
    .then((response: Prefecture[]) => {
      prefectureListDispatch({ type: 'SUCCESS', result: response })
    })
    .catch((error: Error) => {
      prefectureListDispatch({ type: 'FAILD', error })
      ErrorAlert(alertDispatch, error)
    })
}
