import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'

type SubmitButtonProps = {
  isLoading: boolean
  onClick: VoidFunction
  text: string
}

const SubmitButton = (props: SubmitButtonProps) => {
  return (
    <LoadingButton
      variant="contained"
      onClick={props.onClick}
      disabled={props.isLoading}
      loading={props.isLoading}
      sx={{ borderRadius: 10, padding: 1, width: 180 }}
    >
      <Typography variant="body1">{props.text}</Typography>
    </LoadingButton>
  )
}

export default SubmitButton
