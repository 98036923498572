export const validationRules = {
  staffName: {
    required: '担当者名を入力してください。',
    minLength: { value: 2, message: '2文字以上で入力してください。' },
  },
  email: {
    required: 'メールアドレスを入力してください。',
    pattern: {
      value: /^[A-Za-z0-9\w\-._]+@[A-Za-z0-9\w\-._]+\.[A-Za-z]+$/,
      message: '入力形式がメールアドレスではありません。',
    },
  },
  facilityId: {
    required: '施設を選択してください。',
    validate: (value: string) => value !== '' || '施設を選択してください。',
  },
  facilityIdList: {
    required: '施設を選択してください。',
  },
  userGroup: {
    required: '所属グループを選択してください。',
  },
  groupList: {
    required: '所属グループを選択してください。',
  },
  mobilityId: {
    required: '車両IDを入力してください。',
  },
  mobilityName: {
    required: '車両名を入力してください。',
  },
  imei: {
    required: '車両名を入力してください。',
    maxLength: {
      value: 16,
      message: '文字数が多いです。16文字で入力してください。',
    },
    minLength: {
      value: 16,
      message: '文字数が少ないです。16文字で入力してください。',
    },
  },
  areaName: {
    required: 'エリア名を入力してください。',
  },
  lastName: {
    required: '名字を入力してください',
    pattern: {
      value: /^[ァ-ヶーｦ-ﾟ]*$/,
      message: 'カタカナで入力してください。',
    },
  },
  firstName: {
    required: '名前を入力してください。',
    pattern: {
      value: /^[ァ-ヶーｦ-ﾟ]*$/,
      message: 'カタカナで入力してください。',
    },
  },
  reserveDay: {
    required: '予約日を入力してください。',
    validate: (value: Date | null) =>
      !Number.isNaN(value?.getDate()) || '入力形式が間違っています。',
  },
  startTime: {
    required: '開始時間を入力してください。',
  },
  endTime: {
    required: '終了時間を入力してください。',
    validate: (value: Date | null) =>
      !Number.isNaN(value?.getHours()) || '入力形式が間違っています。',
  },
  reserveMobility: {
    required: '車両を選択してください。',
    validate: (value: string) => value !== 'none' || '車両を選択してください。',
  },
  active: {
    required: 'ステータスを選んでください。',
  },
}
