import { Alert, Collapse, AlertTitle, AlertColor } from '@mui/material'

import { useAppAlert } from './AlertProvider'

export type AlertState = {
  isOpen: boolean
  severity: AlertColor
  title: string
  text: string
}

const AppAlert = () => {
  const [openSuccessAlert, openErrorAlert, closeAlert, state] = useAppAlert()

  return (
    <Collapse in={state.isOpen}>
      <Alert
        severity={state.severity}
        onClose={() => {
          closeAlert()
        }}
      >
        <AlertTitle>{state.title}</AlertTitle>
        {state.text}
      </Alert>
    </Collapse>
  )
}

export default AppAlert
