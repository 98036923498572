import { Stack } from '@mui/material'
import { useState } from 'react'

import AppAlert from '../../Components/Alert/AppAlert'
import AddFab from '../../Components/Common/AddFab'
import PageTitle from '../../Components/Common/PageTitle'
import { usePrefectureList } from '../../Context/usePrefectureList'
import { AppLogger } from '../../Logger/AppLogger'

import AddFacilityDialog from './Components/AddFacilityDialog'
import FacilitySearchCard from './Components/FacilitySearchCard'
import FacilitySearchResult from './Components/FacilitySearchResult'
import useSearchFacilityResult from './Hooks/useSearchFacilityResult'

const FacilityManagement = () => {
  AppLogger.debug('render: FacilityManagement page.')
  const [dialogOpen, setDialogOpen] = useState(false)
  const toggleDialog = () => setDialogOpen(!dialogOpen)

  const prefectureListState = usePrefectureList()
  const [searchResult, onSubmit, isLoading] = useSearchFacilityResult()

  return (
    <div className="FacilityManagement">
      <PageTitle title="施設管理画面" />
      <Stack spacing={2}>
        <AppAlert />
        <FacilitySearchCard
          handleSubmit={onSubmit}
          prefectureList={prefectureListState.prefectureList}
          isLoading={isLoading}
        />
        <FacilitySearchResult searchResult={searchResult} />
        <AddFab onclick={toggleDialog} />
        <AddFacilityDialog
          isOpen={dialogOpen}
          prefectureList={prefectureListState.prefectureList}
          onClose={toggleDialog}
        />
      </Stack>
    </div>
  )
}

export default FacilityManagement
