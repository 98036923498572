import { Typography } from '@mui/material'
import L, { LatLngExpression, Marker as leafletMarker } from 'leaflet'
import { useEffect, forwardRef, useRef, MutableRefObject, ReactNode } from 'react'
import { Marker, Popup, useMap } from 'react-leaflet'

import userLocation from '../../../Assets/userLocation.png'
import { useLocationData } from '../Hooks/useUserWatching'
import { UserMarkerProps } from '../Types/UserWatchingTypes'

import 'leaflet-rotatedmarker'

type RotatedMarkerProps = {
  rotationAngle: number
  rotationOrigin: string
  position: LatLngExpression
  children: ReactNode
}

class CustomMarker extends leafletMarker {
  setRotationAngle!: (angle: number) => void

  setRotationOrigin!: (origin: string) => void
}

type ForwardedRef<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null

const RotatedMarker = forwardRef<CustomMarker, RotatedMarkerProps>(
  ({ ...props }, fRef: ForwardedRef<CustomMarker>) => {
    const markerRef = useRef<CustomMarker | null>(null)

    const { rotationAngle } = props
    const { rotationOrigin } = props
    useEffect(() => {
      const marker = markerRef.current
      if (marker) {
        marker.setRotationAngle(rotationAngle)
        marker.setRotationOrigin(rotationOrigin)
      }
    }, [rotationAngle, rotationOrigin])

    return (
      <Marker
        {...props}
        icon={iconUserLocation}
        ref={(ref: CustomMarker) => {
          markerRef.current = ref
          if (typeof fRef === 'function') {
            fRef(ref)
          } else if (fRef) {
            // eslint-disable-next-line no-param-reassign
            fRef.current = ref
          }
        }}
      />
    )
  }
)

const UserMarker = (props: UserMarkerProps) => {
  const locationData = useLocationData()

  const map = useMap()

  useEffect(() => {
    map.fitBounds([[locationData.latitude, locationData.longitude]])
  }, [locationData.latitude, locationData.longitude])

  return (
    <RotatedMarker
      position={[locationData.latitude, locationData.longitude]}
      rotationAngle={locationData.heading || 0}
      rotationOrigin="center"
    >
      <Popup>
        <Typography variant="body1">{`latitude:${locationData.latitude}`}</Typography>
        <Typography variant="body1">{`longitude:${locationData.longitude}`}</Typography>
        <Typography variant="body1">{`accuracy:${locationData.accuracy}`}</Typography>
        <Typography variant="body1">{`timestamp:${locationData.timestamp.toLocaleString()}`}</Typography>
      </Popup>
    </RotatedMarker>
  )
}

const iconUserLocation = new L.Icon({
  iconUrl: userLocation,
  iconSize: [90, 90],
  iconAnchor: [45, 45],
})

export default UserMarker
