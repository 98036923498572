export const ControllerErrorList = [
  { id: 0, value: '異常なし', approach: '異常なし', level: '' },
  {
    id: 1,
    value: '24V電源　過電圧　異常',
    approach:
      '異常（24V電源　過電圧）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 2,
    value: '24V電源　低電圧　異常',
    approach:
      '異常（24V電源　低電圧）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 3,
    value: '外部出力用5V電源　過電圧　異常（プッシュハンドル）',
    approach: '',
    level: '',
  },
  { id: 4, value: '外部出力用5V電源　低電圧　異常', approach: '', level: '' },
  {
    id: 5,
    value: '3.3V電源　過電圧　異常 加速度センサ用',
    approach:
      '異常（3.3V電源　過電圧）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 6,
    value: '3.3V電源　低電圧　異常',
    approach:
      '異常（3.3V電源　低電圧）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 7,
    value: 'リレー駆動　異常',
    approach:
      '異常（リレー駆動異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 8,
    value: 'IMU信号　異常',
    approach:
      '異常（IMU信号異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 9,
    value: 'IMUキャリブレーション未設定',
    approach:
      '異常（IMUキャリブレーション未設定）です。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 10,
    value: 'CAN1通信　バスオフ異常',
    approach:
      '異常が発生しました。(CAN1通信バスオフ異常)電源スイッチをいれなおし、エラーが改善しない場合は、ただちにKUPOの使用をやめて、点検してください。',
    level: 'easy',
  },
  {
    id: 11,
    value: 'CAN1通信　受信異常',
    approach:
      '異常が発生しました。(CAN1通信受信異常)電源スイッチをいれなおし、エラーが改善しない場合は、ただちにKUPOの使用をやめて、点検してください。',
    level: 'easy',
  },
  {
    id: 12,
    value: 'EEPROM　読み出し異常',
    approach:
      '異常（EEPROM読み出し異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 13, value: 'EEPROM　書き込み異常', approach: '対応不要', level: '' },
  { id: 14, value: 'ROM　異常', approach: '', level: '' },
  { id: 15, value: 'RAM　異常', approach: '', level: '' },
  {
    id: 16,
    value: 'A/D変換　異常',
    approach:
      '異常（A/D変換異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 17, value: 'クロック・タイマ　異常', approach: '', level: '' },
  {
    id: 21,
    value: '過充電電圧保護　（異常）過電圧電圧保護',
    approach:
      '異常（過電圧電圧保護）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 22,
    value: '過放電電圧保護　（異常）低電圧電圧保護',
    approach:
      '異常（低電圧電圧保護）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 23,
    value: '充電過電流保護　（異常）過電流保護',
    approach:
      '異常（過電流保護）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 24,
    value: '短絡保護　（異常）',
    approach:
      '異常（短絡保護）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 25,
    value: '高温保護　（異常）過温度保護',
    approach:
      '異常（過温度保護）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 26, value: '低温保護　（異常）', approach: '', level: '' },
  {
    id: 31,
    value: 'モータ温度　高温異常',
    approach:
      '異常（左モータ温度　高温異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 32, value: 'サーミスタ（モータ）　異常', approach: '', level: '' },
  { id: 33, value: 'モータ相間短絡異常', approach: '', level: '' },
  {
    id: 34,
    value: 'モータ回転異常（ホールセンサの異常）',
    approach:
      '異常（左モータ回転異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 35,
    value: 'モータ断線異常',
    approach:
      '異常（左モータ断線異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 36,
    value: 'モータロック　異常',
    approach:
      '車に負担がかかっている状態です。（左モータロック異常）無理な走行をやめるようにしてください。',
    level: 'easy',
  },
  {
    id: 41,
    value: 'モータ温度　高温異常',
    approach:
      '異常（右モータ温度　高温異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 42, value: 'サーミスタ（モータ）　異常', approach: '', level: '' },
  { id: 43, value: 'モータ天絡異常', approach: '', level: '' },
  {
    id: 44,
    value: 'モータ回転異常',
    approach:
      '異常（右モータ回転異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 45,
    value: 'モータ断線異常',
    approach:
      '異常（右モータ断線異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 46,
    value: 'モータロック　異常',
    approach:
      '車に負担がかかっている状態です。（左モータロック異常）無理な走行をやめるようにしてください。',
    level: 'easy',
  },
  {
    id: 51,
    value: '過電流　異常',
    approach:
      '異常（左モータドライバ　過電流異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 52, value: '電流検出センサ値　異常', approach: '', level: '' },
  { id: 53, value: '出力電流　過大異常', approach: '', level: '' },
  {
    id: 54,
    value: '出力回転数　過大異常',
    approach:
      '車に負担がかかっている状態です。（左モータドライバの出力回転数が過大）無理な走行をやめ、電源スイッチを入れ直してください。',
    level: 'easy',
  },
  {
    id: 55,
    value: '逆回転検知　異常',
    approach:
      '車に負担がかかっている状態です。（左モータドライバが逆回転を検知）無理な走行をやめ、電源スイッチを入れ直してください。',
    level: 'easy',
  },
  {
    id: 56,
    value: '電磁ブレーキ　異常',
    approach:
      '異常（左モータドライバ　電磁ブレーキ異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 57,
    value: 'パワーモジュール（FET）　高温異常',
    approach:
      '異常（パワーモジュール（FET）高温異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 58, value: 'サーミスタ（FET）　異常', approach: '', level: '' },
  {
    id: 61,
    value: '過電流　異常',
    approach:
      '異常（右モータドライバ　過電流異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 62, value: '電流検出センサ値　異常', approach: '', level: '' },
  { id: 63, value: '出力トルク　過大異常', approach: '', level: '' },
  {
    id: 64,
    value: '出力回転数　過大異常',
    approach:
      '車に負担がかかっている状態です。（右モータドライバの出力回転数が過大）無理な走行をやめ、電源スイッチを入れ直してください。',
    level: 'hard',
  },
  {
    id: 65,
    value: '逆回転検知　異常',
    approach:
      '車に負担がかかっている状態です。（右モータドライバが逆回転を検知）無理な走行をやめ、電源スイッチを入れ直してください。',
    level: 'hard',
  },
  {
    id: 66,
    value: '電磁ブレーキ　異常',
    approach:
      '異常（右モータドライバ　電磁ブレーキ異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 67,
    value: 'パワーモジュール（FET）　高温異常',
    approach:
      '異常（パワーモジュール（FET）高温異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  { id: 68, value: 'サーミスタ（FET）　異常', approach: '', level: '' },
  {
    id: 71,
    value: 'ジョイステック信号値　異常',
    approach:
      '異常（ジョイステック信号異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 72,
    value: '走行許可スイッチ信号　異常',
    approach:
      '異常（走行許可スイッチ信号異常）が発生しました。電源スイッチを入れ直してください。',
    level: 'hard',
  },
  {
    id: 81,
    value: 'グリップセンサ信号　異常',
    approach:
      '異常（走行許可スイッチ信号異常）が発生しました。電源スイッチを入れ直してください。',
    level: 'easy',
  },
  {
    id: 82,
    value: '電磁ブレーキ強制解除スイッチ信号　異常',
    approach:
      '異常（走行許可スイッチ信号異常）が発生しました。電源スイッチを入れ直してください。',
    level: 'easy',
  },
  {
    id: 91,
    value: 'ロックセンサ　異常',
    approach:
      '異常（ロックセンサ異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 92,
    value: 'フレームセンサ　異常',
    approach:
      '異常（フレームセンサ異常）が発生しました。ただちに使用を中止し、点検をおこなってください。',
    level: 'hard',
  },
  {
    id: 141,
    value: '転倒検知　警告',
    approach: '転倒を検知しました。ただちにKUPOに急行し、ユーザーを保護してください。',
    level: 'hard',
  },
  {
    id: 151,
    value: '充電インターロック１',
    approach:
      '電源スイッチONのまま充電コードが接続されました。電源スイッチを切り、電源プラグをコンセントから抜いてください。',
    level: 'easy',
  },
  {
    id: 152,
    value: '充電インターロック２',
    approach:
      '電源スイッチONのまま充電コードが接続されました。電源スイッチを切り、電源プラグをコンセントから抜いてください。',
    level: 'easy',
  },
  {
    id: 161,
    value: '左モータ　モータ温度　高温警告',
    approach:
      '車に負担がかかっている状態です。（左モータ温度高温警告）安全な場初にKUPOを移動させ、車両を休ませてください。',
    level: 'easy',
  },
  {
    id: 171,
    value: '右モータ　モータ温度　高温警告',
    approach:
      '車に負担がかかっている状態です。（右モータ温度高温警告）安全な場初にKUPOを移動させ、車両を休ませてください。',
    level: 'easy',
  },
  {
    id: 182,
    value: 'パワーモジュール（FET）　高温警告',
    approach:
      '車に負担がかかっている状態です。（パワーモジュール（FET）高温警告）安全な場初にKUPOを移動させ、車両を休ませてください。',
    level: 'easy',
  },
  {
    id: 192,
    value: 'パワーモジュール（FET）　高温警告',
    approach:
      '車に負担がかかっている状態です。（パワーモジュール（FET）高温警告）安全な場初にKUPOを移動させ、車両を休ませてください。',
    level: 'easy',
  },
  {
    id: 211,
    value: 'プッシュモード操作部 音声LSI　警告',
    approach:
      '異常（プッシュモード操作部 音声LSI　警告）が発生しました。電源スイッチをいれなおし、改善しない場合は、ただちにKUPOの使用をやめて、点検してください。',
    level: 'easy',
  },
]
