import { useState, useContext } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import { searchFacilityList } from '../Repository/FacilityRepository'
import { FacilityInfo, SearchFacilityInputs } from '../Types/FacilityManagementTypes'

const useSearchFacilityResult = () => {
  const [searchResult, setSearchResult] = useState<FacilityInfo[]>([])
  const { dispatch } = useContext(AlertContext)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<SearchFacilityInputs> = (
    inputs: SearchFacilityInputs
  ) => {
    setIsLoading(true)
    searchFacilityList(inputs)
      .then((response: FacilityInfo[]) => {
        setSearchResult(response)
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return [searchResult, onSubmit, isLoading] as const
}

export default useSearchFacilityResult
