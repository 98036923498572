import { SubmitHandler } from 'react-hook-form'

export type MobilityInfo = {
  mobilityId: string
  mobilityName: string
  facilityId: string
  available: boolean
  imei: string
  latitude: number
  longitude: number
  soc: number
  timestamp: string
  vehicleState: number
  errorPriority1: number
  errorPriority2: number
  fallDownFlag: boolean
  serverReceptionTime: string
  diff: number
  mobilityType: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  publisher: string
  startedAt: string
}

export type MobilityInfoForDataGrid = MobilityInfo & {
  displayedId: string
  gpsFlag: boolean
}

export const initialMobilityInfo: MobilityInfo = {
  mobilityId: '',
  mobilityName: '',
  facilityId: '',
  available: true,
  imei: '',
  latitude: 0,
  longitude: 0,
  soc: 0,
  timestamp: '',
  vehicleState: 0,
  errorPriority1: 0,
  errorPriority2: 0,
  fallDownFlag: false,
  serverReceptionTime: '',
  diff: 0,
  mobilityType: '',
  createdBy: '',
  createdAt: '',
  updatedBy: '',
  updatedAt: '',
  publisher: '',
  startedAt: '',
}

export type SearchMobilityInputs = {
  facilityId: string
}

export type SearchMobilityCardProps = {
  handleSubmit: SubmitHandler<SearchMobilityInputs>
}

export type SearchMobilityResultProps = {
  searchResult: MobilityInfo[]
}

export type AddMobilityDialogProps = {
  isOpen: boolean
  onClose: VoidFunction
}

export type UpdateMobilityDialogProps = {
  mobilityInfo: MobilityInfo
}

export type DisableMobilityDialogProps = {
  mobilityInfo: MobilityInfo
}

export type CommunicationDeviceInfo = {
  imei: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  available: boolean
}
