import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { showNotification } from '../../../Common/NotificationManager'
import { useFacilityList } from '../../../Context/useFacilityList'
import { AppLogger } from '../../../Logger/AppLogger'
import { AlertContext, ErrorAlert } from '../../Alert/AlertProvider'
import { getActiveUserList, ActiveUserList } from '../AppHeaderRepository'

const useActiveUserList = () => {
  const facilityState = useFacilityList()
  const { dispatch } = useContext(AlertContext)
  const navigate = useNavigate()
  const userAgent = window.navigator.userAgent.toLowerCase()

  useEffect(() => {
    const intervalId = setInterval(() => {
      getActiveUserList(facilityState)
        .then((response: ActiveUserList[]) => {
          if (response.length > 0) {
            const facilityNameList: string[] = []
            let facilityId: string | undefined

            for (let i = 0; i < response.length; i += 1) {
              if (response[i].activeUserList.length >= 1) {
                for (let j = 0; j < response[i].activeUserList.length; j += 1) {
                  if (response[i].activeUserList[j].emergencyFlag === true) {
                    const facilityName = facilityState.facilityList.find(
                      (value) => value.facilityId === response[i].facilityId
                    )?.facilityName

                    if (facilityName) {
                      facilityNameList.push(facilityName)
                      facilityId = facilityState.facilityList.find(
                        (value) => value.facilityId === response[i].facilityId
                      )?.facilityId
                    }
                  }
                }
              }
            }

            if (
              userAgent.indexOf('android') === -1 &&
              facilityNameList.length !== 0 &&
              'Notification' in window
            ) {
              const title = '緊急コールです!!'
              const message = `${facilityNameList.toString()}で緊急コールが呼ばれました!!!`
              Notification.requestPermission()
                .then(() =>
                  showNotification(
                    Notification.permission,
                    title,
                    message,
                    navigate,
                    `/Management/${facilityId || ''}`
                  )
                )
                .catch((error: Error) => {
                  ErrorAlert(dispatch, error)
                })
            }
          }
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })
    }, 10000)
    AppLogger.debug(`subscribe active user list:${intervalId as unknown as string}`)

    return () => {
      AppLogger.debug(`unsubscribe active user list:${intervalId as unknown as string}`)
      clearInterval(intervalId)
    }
  }, [facilityState.facilityList])
}

export default useActiveUserList
