import { useReducer, useMemo } from 'react'

import {
  initialMobilityMonitoringState,
  MobilityMonitoringContext,
  MobilityMonitoringReducer,
} from '../Context/MobilityMonitoringContext'

const MobilityMonitoringProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [mobilityMonitoringState, mobilityMonitoringDispatch] = useReducer(
    MobilityMonitoringReducer,
    initialMobilityMonitoringState
  )
  const mobilityMonitoringValue = useMemo(
    () => ({ mobilityMonitoringState, mobilityMonitoringDispatch }),
    [mobilityMonitoringState]
  )

  return (
    <MobilityMonitoringContext.Provider value={mobilityMonitoringValue}>
      {children}
    </MobilityMonitoringContext.Provider>
  )
}

export default MobilityMonitoringProvider
