import { Typography } from '@mui/material'

type PageTitleProps = {
  title: string
}
const PageTitle = (props: PageTitleProps) => {
  return (
    <Typography variant="h6" padding={1}>
      {props.title}
    </Typography>
  )
}

export default PageTitle
