import { useAuthenticator } from '@aws-amplify/ui-react'
import { AppBar, Toolbar, Box } from '@mui/material'
import { useContext, useEffect } from 'react'

import { HeaderContext } from '../../Context/HeaderContext'
import { useInitializeApp } from '../../Context/useInitializeApp'

import AccountMenuButton from './Account/AccountMenuButton'
import AppLogo from './AppLogo'
import AppMenu from './AppMenu/AppMenu'

const AppHeader = () => {
  const { route } = useAuthenticator((context) => [context.route])
  const { headerState, headerDispatch } = useContext(HeaderContext)

  useInitializeApp()

  useEffect(() => {
    headerDispatch({ type: 'VISIBLE' })
  }, [])

  return (
    <div className="Header">
      {headerState.isVisible ? (
        <AppBar position="static">
          <Toolbar>
            <AppMenu mr={2} />

            <Box sx={{ flexGrow: 1 }}>
              <AppLogo />
            </Box>

            {route === 'authenticated' && <AccountMenuButton />}
          </Toolbar>
        </AppBar>
      ) : (
        <div className="no header" />
      )}
    </div>
  )
}

export default AppHeader
