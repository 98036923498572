import { API } from 'aws-amplify'

import { AppLogger } from '../../../Logger/AppLogger'
import {
  CommunicationDeviceInfo,
  MobilityInfo,
  SearchMobilityInputs,
} from '../Types/MobilityManagementTypes'

export const searchMobility = async (
  inputs: SearchMobilityInputs
): Promise<MobilityInfo[]> => {
  AppLogger.debug('call Api: searchMobility')

  const apiName = 'SuzukiAdmin'
  const path = '/searchMobilityList'
  const myInit = { body: inputs }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: MobilityInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const addMobility = async (mobilityInfo: MobilityInfo): Promise<string> => {
  AppLogger.debug('call Api: addMobility')

  const apiName = 'SuzukiAdmin'
  const path = '/putMobilityInfo'
  const myInit = {
    body: {
      mobilityInfo,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('SUCCESS')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const updateMobility = async (mobilityInfo: MobilityInfo): Promise<string> => {
  AppLogger.debug('call Api: updateMobility')

  const apiName = 'SuzukiAdmin'
  const path = '/updateMobilityInfo'

  const myInit = {
    body: { mobilityInfo },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('SUCCESS')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const disableMobility = async (mobilityInfo: MobilityInfo): Promise<string> => {
  AppLogger.debug('call Api: disableMobility')

  const apiName = 'SuzukiAdmin'
  const path = '/disableMobility'
  const myInit = {
    body: { mobilityInfo },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('SUCCESS')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getCommunicationDeviceInfoList = async (): Promise<
  CommunicationDeviceInfo[]
> => {
  AppLogger.debug('call Api: getCommunicationDeviceInfoList')

  const apiName = 'SuzukiAdmin'
  const path = '/getCommunicationDeviceInfoList'
  const myInit = {}

  return new Promise((resolve, reject) => {
    API.get(apiName, path, myInit)
      .then((response: CommunicationDeviceInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
