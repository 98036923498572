import { Grid, Stack } from '@mui/material'
import { LatLng, LatLngExpression, Map as LeafletMap } from 'leaflet'
import { useMemo, useRef } from 'react'

import AppAlert from '../../Components/Alert/AppAlert'
import AddFab from '../../Components/Common/AddFab'
import PageTitle from '../../Components/Common/PageTitle'
import { AreaInfo } from '../MobilityMonitoring/Types/MobilityMonitoringTypes'

import AddAreaDialog from './Components/AddAreaDialog'
import AreaListWithSearch from './Components/AreaListWithSearch'
import { AreaViews } from './Components/AreaViews'
import DisableAreaDialog from './Components/DisableAreaDialog'
import EditAreaDialog from './Components/EditAreaDialog'
import MapCard from './Components/MapCard'
import useAreaManagement from './Hook/useAreaManagement'
import AreaManagementProvider from './Provider/AreaManagementProvider'

const AreaManagement = () => {
  return (
    <div className="AreaManagemet">
      <AreaManagementProvider>
        <AreaManagementContent />
      </AreaManagementProvider>
    </div>
  )
}

const AreaManagementContent = () => {
  const initialposition: LatLngExpression = [34.762759757497946, 137.63329094144157]

  const { areaManagementState, openAddDialog, openEditDialog, openDisableDialog } =
    useAreaManagement()

  const mapRef = useRef<LeafletMap | null>(null)

  const memoMap = useMemo(() => {
    return (
      <MapCard position={initialposition} mapRef={mapRef}>
        <AreaViews
          isEditable
          onEdit={(targetArea: AreaInfo) => {
            openEditDialog(targetArea, {
              center: mapRef.current?.getCenter() ?? new LatLng(0.0, 0.0),
              zoom: mapRef.current?.getZoom() ?? 1,
            })
          }}
          onDelete={(targetArea: AreaInfo) => {
            openDisableDialog(targetArea)
          }}
        />
      </MapCard>
    )
  }, [])

  return (
    <>
      <PageTitle title="エリア管理画面" />
      <Stack spacing={2}>
        <AppAlert />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={7}>
            {memoMap}
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <AreaListWithSearch />
          </Grid>
          <AddAreaDialog />
          <EditAreaDialog />
          <DisableAreaDialog />
          <AddFab
            disabled={
              areaManagementState.selectedFacilityId === '' ||
              areaManagementState.isFetchingArea
            }
            onclick={() => {
              openAddDialog({
                center: mapRef.current?.getCenter() ?? new LatLng(0.0, 0.0),
                zoom: mapRef.current?.getZoom() ?? 1,
              })
            }}
          />
        </Grid>
      </Stack>
    </>
  )
}

export default AreaManagement
