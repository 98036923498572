import { DataGrid, GridColDef, GridEventListener, jaJP } from '@mui/x-data-grid'
import { useState } from 'react'

import { FacilityInfo, SearchFacilityResultProps } from '../Types/FacilityManagementTypes'

import UpdateFacilityDialog from './UpdateFacilityDialog'

const FacilitySearchResult = (props: SearchFacilityResultProps) => {
  const columns: GridColDef[] = [
    { field: 'facilityId', headerName: '施設ID', width: 200 },
    { field: 'facilityName', headerName: '施設名', width: 200 },
    { field: 'prefCode', headerName: '都道府県ID', width: 200 },
    { field: 'available', headerName: '有効', width: 200 },
  ]

  const [isOpen, setIsOpen] = useState(false)
  const [facilityInfo, setFacilityInfo] = useState<FacilityInfo>({
    facilityId: '',
    facilityName: '',
    prefCode: '',
    available: true,
  })

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    const info = params.row as FacilityInfo
    setFacilityInfo(info)
    setIsOpen(!isOpen)
  }

  const onClose = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <DataGrid
        autoHeight
        rows={props.searchResult}
        columns={columns}
        getRowId={(row: FacilityInfo) => row.facilityId}
        hideFooterSelectedRowCount
        onRowClick={onRowClick}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
      <UpdateFacilityDialog
        isOpen={isOpen}
        onClose={onClose}
        facilityInfo={facilityInfo}
      />
    </>
  )
}

export default FacilitySearchResult
