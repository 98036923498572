import { Stack, Card, MenuItem, TextField, Skeleton } from '@mui/material'
import { useForm } from 'react-hook-form'

import SubmitButton from '../../../Components/Common/SubmitButton'
import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { useFacilityList } from '../../../Context/useFacilityList'
import useUserGroup from '../../../Context/useUserGroup'
import useSearchStaffResult from '../Hooks/useSearchStaffResult'
import { SearchStaffInputs } from '../Types/StaffManagementTypes'

const SearchStaffCard = () => {
  const userGroupList = useUserGroup()
  const facilityState = useFacilityList()
  const [searchState, onSubmit] = useSearchStaffResult()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SearchStaffInputs>({
    defaultValues: {
      facilityId: facilityState.facilityList[0]?.facilityId || '',
      userGroup: 'Staff',
    },
  })

  return (
    <Card sx={{ padding: 5 }}>
      <Stack direction="column" spacing={3} alignItems="center">
        {facilityState.isLoading ? (
          <Skeleton sx={{ height: '15vh' }} />
        ) : (
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center">
            <SelectFacilityForm
              value={watch('facilityId')}
              error={'facilityId' in errors}
              helperText={errors.facilityId?.message}
              register={register('facilityId', validationRules.facilityId)}
            />

            <TextField
              select
              label="グループ"
              value={watch('userGroup')}
              error={'userGroup' in errors}
              helperText={errors.userGroup?.message}
              {...register('userGroup', validationRules.userGroup)}
            >
              {userGroupList.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        )}

        <SubmitButton
          isLoading={searchState.isLoading}
          onClick={handleSubmit(onSubmit)}
          text="検索"
        />
      </Stack>
    </Card>
  )
}

export default SearchStaffCard
