import { AreaInfo } from '../../Pages/MobilityMonitoring/Types/MobilityMonitoringTypes'

// export const areaColor = (areaType: string): string => {
//   switch (areaType) {
//     case 'return':
//       return 'green'
//     case 'danger':
//       return 'red'
//     case 'highlight':
//       return 'blue'
//     default:
//       return 'white'
//   }
// }

export const areaColor = (areaInfo: AreaInfo): string => {
  if (areaInfo.areaType === 'danger' && areaInfo.areaName.includes('侵入禁止')) {
    return 'black'
  }

  if (areaInfo.areaType === 'danger') {
    return 'red'
  }

  if (areaInfo.areaType === 'return') {
    return 'green'
  }

  if (areaInfo.areaType === 'highlight') {
    return 'blue'
  }
  return 'white'
}
