import { useAuthenticator } from '@aws-amplify/ui-react'

import { AppLogger } from '../../Logger/AppLogger'
import AuthenticatedRouter from '../Router/AuthenticatedRouter'
import NotAuthenticatedRouter from '../Router/NotAuthenticatedRouter'

import './AmplifyButtonStyle.css'

const AppAuthenticator = () => {
  AppLogger.debug('render: AppAuthenticator Component.')
  const { route } = useAuthenticator((context) => [context.route])

  if (route === 'authenticated') {
    return <AuthenticatedRouter />
  }
  if (route === 'idle') {
    return <div className="idle" />
  }
  return <NotAuthenticatedRouter />
}

export default AppAuthenticator
