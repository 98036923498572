import { TextField } from '@mui/material'
import { UseFormRegisterReturn } from 'react-hook-form'

type TextFieldFormProps = {
  label: string
  value: string
  error: boolean
  helperText: string | undefined
  register: UseFormRegisterReturn
}

const TextFieldForm = (props: TextFieldFormProps) => {
  return (
    <TextField
      label={props.label}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      {...props.register}
    />
  )
}

export default TextFieldForm
