/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import HomeIcon from '@mui/icons-material/Home'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3'
import { Grid, Stack } from '@mui/material'
import { useContext, useState } from 'react'
import useSound from 'use-sound'

import callSound from '../../Assets/Sounds/call.mp3'
import endSound from '../../Assets/Sounds/end.mp3'
import errorEasySound from '../../Assets/Sounds/errorEasy.mp3'
import errorHardSound from '../../Assets/Sounds/errorHard.mp3'
import startSound from '../../Assets/Sounds/start.mp3'
import {
  differenceTimePerMinutes,
  timestampToDate,
} from '../../Common/TimestampConverter'
import { AlertContext, ErrorAlert } from '../../Components/Alert/AlertProvider'
import ConfirmDialog from '../../Components/Common/ConfirmDialog'
import { AppLogger } from '../../Logger/AppLogger'
import { ControllerErrorList } from '../MobilityMonitoring/Components/ControllerErrorList'
import { incrementActive } from '../Questionnaire/Repository/QuestionnaireRepository'
import { AlignedText } from '../Reservation/ReserveConfirmation'
import { UserInfo } from '../UserManagement/Types/UserManagementTypes'

import CustomRoundedButton from './Components/CustomRoundedButton'
import UserMap from './Components/UserMap'
import useUserWatching from './Hooks/useUserWatching'
import { initialLocation } from './MapSettings'
import {
  checkMobilityInUse,
  setEmergencyFlag,
  setReturnArea,
  setStartEndTime,
} from './Repository/UserWatchingRepository'

type UserWatchingContentProps = {
  userInfo: UserInfo
  nextScreen: VoidFunction
  onClose: VoidFunction
}

const UserWatchingContent = (props: UserWatchingContentProps) => {
  const [returnArea, currentDate, currentMobilityState, addOrientationEvent] =
    useUserWatching({
      userInfo: props.userInfo,
    })

  const [startSoundPlay] = useSound(startSound as string, { interrupt: true })
  const [endSoundPlay] = useSound(endSound as string, { interrupt: true })
  const [callSoundPlay] = useSound(callSound as string, { interrupt: true })
  const [errorEasySoundPlay] = useSound(errorEasySound as string, { interrupt: true })
  const [errorHardSoundPlay] = useSound(errorHardSound as string, { interrupt: true })

  const onEmergency = () => {
    setIsLoading(true)
    setEmergencyFlag(props.userInfo.userId, true)
      .then((response) => {
        callSoundPlay()
        toggleEmergencyDialog()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const { dispatch } = useContext(AlertContext)
  const [watching, setWatching] = useState(false)

  const setDeviceOrientationPermission = () => {
    if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
      ;(DeviceOrientationEvent as any).requestPermission().then((response: string) => {
        if (response === 'granted') {
          addOrientationEvent()
        }
      })
    }
  }

  const onStartWatching = () => {
    setDeviceOrientationPermission()
    setIsLoading(true)
    checkMobilityInUse(props.userInfo)
      .then((inUse) => {
        // 利用中だったら開始しない
        if (inUse) {
          setIsLoading(false)
          toggleInUseDialog()
          return
        }
        // 利用中でなければ開始処理をする
        setStartEndTime('start', props.userInfo.userId)
          .then(() => {
            setWatching(true)
            startSoundPlay()
          })
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
            props.onClose()
          })
          .finally(() => {
            toggleStartDialog()
            setIsLoading(false)
          })
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        setIsLoading(false)
        props.onClose()
      })
  }

  const onStopWatching = () => {
    setIsLoading(true)

    setReturnArea(props.userInfo.userId, returnArea.result)
      .then((response) => {
        AppLogger.debug('Completed sending areaId')
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })

    setStartEndTime('end', props.userInfo.userId)
      .then(() => {
        AppLogger.debug('Completed sending endTime')
        incrementActive(props.userInfo)
          .then(() => {
            setWatching(false)
            endSoundPlay()
            props.nextScreen()
          })
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
        setIsLoading(false)
      })
  }

  const [isLoading, setIsLoading] = useState(false)

  const [startDialogIsOpen, setStartDialogIsOpen] = useState(true)
  const toggleStartDialog = () => {
    setStartDialogIsOpen(!startDialogIsOpen)
  }
  const [stopDialogIsOpen, setStopDialogIsOpen] = useState(false)
  const toggleStopDialog = () => {
    if (returnArea.result !== 0) {
      setStopDialogIsOpen(!stopDialogIsOpen)
    } else {
      toggleReturnDialog()
    }
  }
  const [emergencyDialogIsOpen, setEmergencyDialogIsOpen] = useState(false)
  const toggleEmergencyDialog = () => {
    setEmergencyDialogIsOpen(!emergencyDialogIsOpen)
  }
  const [returnDialogIsOpen, setReturnDialogIsOpen] = useState(false)
  const toggleReturnDialog = () => {
    setReturnDialogIsOpen(!returnDialogIsOpen)
  }
  const [inUseDialogIsOpen, setInUseDialogIsOpen] = useState(false)
  const toggleInUseDialog = () => {
    setInUseDialogIsOpen(!inUseDialogIsOpen)
  }

  const checkError = (): string => {
    if (currentMobilityState.length !== 0) {
      const activeError = ControllerErrorList.find(
        (error) => error.id === currentMobilityState[0].errorPriority1
      )
      if (activeError && !startDialogIsOpen) {
        if (activeError.level === 'hard') errorHardSoundPlay()
        if (activeError.level === 'easy') errorEasySoundPlay()
        return activeError.approach
      }

      return '異常なし'
    }
    return ''
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ zIndex: 0 }}>
        <UserMap
          position={initialLocation}
          height="100vh"
          facilityId={props.userInfo.facilityId}
          mobilityId={props.userInfo.mobilityId}
          userId={props.userInfo.userId}
          scheduledEndTime={props.userInfo.scheduledEndTime}
        />
      </Grid>

      <Stack
        spacing={1}
        sx={{
          padding: 2,
          position: 'fixed',
          right: '1em',
          top: '1em',
          zIndex: 1,
          backgroundColor: 'white',
          opacity: 0.75,
          borderRadius: 3,
        }}
      >
        <AlignedText title="予約番号" text={props.userInfo.userId} />
        <AlignedText
          title="利用者"
          text={`${props.userInfo.lastName} ${props.userInfo.firstName} 様`}
        />
        <AlignedText
          title="返却まで"
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          text={`あと${differenceTimePerMinutes(
            timestampToDate(props.userInfo.scheduledEndTime) || currentDate,
            currentDate
          )}`}
        />
        <AlignedText title="車両エラー" text={checkError()} />
      </Stack>

      <Stack
        spacing={5}
        sx={{ position: 'fixed', right: '1em', bottom: '1em', zIndex: 1 }}
      >
        <CustomRoundedButton
          color="secondary"
          onClick={toggleEmergencyDialog}
          icon={<WifiCalling3Icon fontSize="large" sx={{ mr: '1vw' }} />}
          text="緊急"
        />

        {watching ? (
          <CustomRoundedButton
            color="primary"
            onClick={toggleStopDialog}
            icon={<HomeIcon fontSize="large" sx={{ mr: '1vw' }} />}
            text="返却"
          />
        ) : (
          <CustomRoundedButton
            color="info"
            onClick={toggleStartDialog}
            icon={<PlayArrowIcon fontSize="large" sx={{ mr: '1vw' }} />}
            text="開始"
          />
        )}
      </Stack>
      <ConfirmDialog
        isOpen={startDialogIsOpen}
        onClose={toggleStartDialog}
        onSubmit={onStartWatching}
        title="開始確認"
        text="試乗開始します。"
        isLoading={isLoading}
      />
      <ConfirmDialog
        isOpen={stopDialogIsOpen}
        onClose={toggleStopDialog}
        onSubmit={onStopWatching}
        title="終了確認"
        text="試乗終了して車両を返却します。よろしいですか？"
        isLoading={isLoading}
      />
      <ConfirmDialog
        isOpen={emergencyDialogIsOpen}
        onClose={toggleEmergencyDialog}
        onSubmit={onEmergency}
        title="緊急コール"
        text="対応のため担当者をお呼びします。よろしいですか？"
        isLoading={isLoading}
      />
      <ConfirmDialog
        isOpen={returnDialogIsOpen}
        onClose={toggleReturnDialog}
        onSubmit={toggleReturnDialog}
        title="返却不可"
        text="地図上の返却場所での返却をお願いします。"
        isLoading={isLoading}
      />
      <ConfirmDialog
        isOpen={inUseDialogIsOpen}
        onClose={toggleInUseDialog}
        onSubmit={props.onClose}
        title="開始できません。"
        text="車両は利用中です。"
        isLoading={isLoading}
      />
    </Grid>
  )
}

export default UserWatchingContent
