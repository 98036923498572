import { useReducer, useMemo } from 'react'

import {
  initialUserWatchingState,
  UserWatchingContext,
  UserWatchingReducer,
} from '../Context/UserWatchingContext'

const UserWatchingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userWatchingState, userWatchingDispatch] = useReducer(
    UserWatchingReducer,
    initialUserWatchingState
  )
  const value = useMemo(
    () => ({ userWatchingState, userWatchingDispatch }),
    [userWatchingState]
  )

  return (
    <UserWatchingContext.Provider value={value}>{children}</UserWatchingContext.Provider>
  )
}

export default UserWatchingProvider
