import { Typography, Button } from '@mui/material'
import { ReactElement, JSXElementConstructor } from 'react'

type CustomRoundedButtonProps = {
  color: 'inherit' | 'info' | 'primary' | 'secondary' | 'success' | 'error' | 'warning'
  onClick: React.MouseEventHandler<HTMLButtonElement>
  icon: ReactElement<unknown, string | JSXElementConstructor<unknown>>
  text: string
}
const CustomRoundedButton = (props: CustomRoundedButtonProps) => {
  return (
    <Button
      variant="contained"
      color={props.color}
      onClick={props.onClick}
      sx={{ borderRadius: 10 }}
    >
      {props.icon}
      <Typography variant="h3">{props.text}</Typography>
    </Button>
  )
}

export default CustomRoundedButton
