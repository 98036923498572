import { Analytics } from 'aws-amplify'

import { AppLogger } from './Logger/AppLogger'

// ユーザ情報の更新
export const updateAppEndpoint = (email: string) => {
  Analytics.updateEndpoint({
    channelType: 'EMAIL',
    address: email,
    userId: email,
    attributes: {},
    metrics: {},
    userAttributes: {},
  })
    .then(() => {
      AppLogger.debug('updateEndpoint was successful')
    })
    .catch(() => {
      AppLogger.debug('updateEndpoint failed')
    })
}

// ページビューの記録
export const recordPageView = () => {
  AppLogger.debug('pageView tracking...')

  Analytics.autoTrack('pageView', {
    enable: true,
    type: 'SPA',
    getUrl: () => {
      return window.location.origin + window.location.pathname
    },
  })
}

export const EventType = {
  open_portal_page: 'open_portal_page',
  open_reservation_page: 'open_reservation_page',
  click_reservation: 'click_reservation',
} as const

type EventType = typeof EventType[keyof typeof EventType]

// イベントの記録
export const recordEvent = (eventName: EventType) => {
  AppLogger.debug(`record event: ${eventName}`)
  Analytics.record({
    name: eventName,
    attributes: {}, // 任意の文字列の記録
    metrics: {}, // 任意の数値の記録
    immediate: true,
  })
}
