import { Button, Stack, Tooltip, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import PageTitle from '../../Components/Common/PageTitle'

const ReservationUpdateComplete = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const onClick = () => {
    navigate('/')
  }
  return (
    <Stack sx={{ alignItems: 'center', paddingX: 3 }} spacing={2}>
      <PageTitle title="予約変更完了" />
      <Typography variant="h6">
        予約番号:{urlParams.reservationNumber?.split('-')[1] || ''}
      </Typography>
      <Typography>上記の予約番号を、当日受付にお伝えください。</Typography>
      <Typography>
        登録したメールアドレス宛てに予約変更確認メールを送信しましたのでご確認ください。
      </Typography>
      <Tooltip title="ポータル画面へ戻る">
        <Button onClick={onClick}>閉じる</Button>
      </Tooltip>
    </Stack>
  )
}

export default ReservationUpdateComplete
