import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { showNotification } from '../../../Common/NotificationManager'
import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
// import { AppLogger } from '../../../Logger/AppLogger'
import {
  MobilityInfo,
  MobilityInfoForDataGrid,
} from '../../MobilityManagement/Types/MobilityManagementTypes'
import { UserInfo } from '../../UserManagement/Types/UserManagementTypes'
import { MobilityMonitoringContext } from '../Context/MobilityMonitoringContext'
import {
  getMobilityInfoList,
  // getUserDataList,
  // checkFallDown,
  getAreaInfoByFacilityId,
} from '../Repository/MobilityDataRepository'
import { AreaInfo } from '../Types/MobilityMonitoringTypes'

const useMobilityMonitoring = () => {
  const { mobilityMonitoringState, mobilityMonitoringDispatch } = useContext(
    MobilityMonitoringContext
  )
  const { dispatch } = useContext(AlertContext)

  const userAgent = window.navigator.userAgent.toLowerCase()

  const [isLoading, setIsLoading] = useState(false)

  const setMonitorInterval = (facilityId: string) => {
    mobilityMonitoringDispatch({ type: 'FETCH' })
    dispatch({ type: 'CLOSE' })

    setIsLoading(true)

    getMobilityInfoList(facilityId)
      .then((response: MobilityInfo[]) => {
        checkErrorNotification(response)

        // gpsFlagを追加する処理
        mobilityMonitoringDispatch({
          type: 'MOBILITY',
          result: addPropertiesForMonitoring(
            response,
            mobilityMonitoringState.mobilityInfoList
          ),
        })
        setIsLoading(false)
      })
      .catch((error: Error) => {
        mobilityMonitoringDispatch({ type: 'FAILD', error })
        ErrorAlert(dispatch, error)
        setIsLoading(false)
      })

    // getUserDataList(facilityId)
    //   .then((response: UserInfo[]) => {
    //     checkDangerousAreaNotification(response)
    //     mobilityMonitoringDispatch({ type: 'USER', result: response })
    //   })
    //   .catch((error: Error) => {
    //     mobilityMonitoringDispatch({ type: 'FAILD', error })
    //     ErrorAlert(dispatch, error)
    //   })

    getAreaInfoByFacilityId(facilityId)
      .then((response: AreaInfo[]) => {
        mobilityMonitoringDispatch({ type: 'EMERGENCY', result: response })
      })
      .catch((error: Error) => {
        mobilityMonitoringDispatch({ type: 'FAILD', error })
        ErrorAlert(dispatch, error)
      })

    // checkFallDown(mobilityMonitoringState.mobilityInfoList)
    //   .then((response) => {
    //     AppLogger.debug(response)
    //   })
    //   .catch((error: Error) => {
    //     ErrorAlert(dispatch, error)
    //   })
  }

  const checkUser = (mobilityId: string): UserInfo | undefined => {
    const activeStateUsing = 1
    const activeUserInfo = mobilityMonitoringState.userDataList.find(
      (userInfo: UserInfo) =>
        userInfo.mobilityId === mobilityId && userInfo.active === activeStateUsing
    )

    return activeUserInfo
  }

  const navigate = useNavigate()

  const checkErrorNotification = (mobilityList: MobilityInfo[]) => {
    if (userAgent.indexOf('android') !== -1) return
    if (!('Notification' in window)) return
    for (const mobilityInfo of mobilityList) {
      if (mobilityInfo.errorPriority1 > 0) {
        const title = 'エラー発生！！'
        const message = `${mobilityInfo.mobilityName}で車両異常が発生しています。`
        Notification.requestPermission()
          .then(() =>
            showNotification(
              Notification.permission,
              title,
              message,
              navigate,
              `/Management/${mobilityInfo.facilityId}`
            )
          )
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
          })
      }
    }
  }

  const checkDangerousAreaNotification = (userList: UserInfo[]) => {
    if (userAgent.indexOf('android') !== -1) return
    if (!('Notification' in window)) return
    for (const userInfo of userList) {
      if (userInfo.dangerousAreaFlag) {
        const title = '危険エリア侵入アラート！！'
        const message = `${userInfo.lastName}さんが危険エリアに侵入しています。`
        Notification.requestPermission()
          .then(() =>
            showNotification(
              Notification.permission,
              title,
              message,
              navigate,
              `/Management/${userInfo.facilityId}`
            )
          )
          .catch((error: Error) => {
            ErrorAlert(dispatch, error)
          })
      }
    }
  }

  const selectMoblity = (mobilityInfo: MobilityInfo) => {
    mobilityMonitoringDispatch({ type: 'SELECT', mobilityInfo })
  }

  return [
    mobilityMonitoringState,
    setMonitorInterval,
    checkUser,
    selectMoblity,
    isLoading,
  ] as const
}

const addPropertiesForMonitoring = (
  mobilityInfoList: MobilityInfo[],
  currentMobilityInfoList: MobilityInfo[]
): MobilityInfoForDataGrid[] => {
  const tempMobilityInfoList = [...mobilityInfoList].sort((a, b) =>
    a.mobilityName < b.mobilityName ? -1 : 1
  )
  const newMobilityInfoList: MobilityInfoForDataGrid[] = []

  for (const [index, mobilityInfo] of Object.entries(tempMobilityInfoList)) {
    const gpsFlag = isEnableGps(currentMobilityInfoList, mobilityInfo)
    const displayedId = String(Number(index) + 1)

    newMobilityInfoList.push({
      ...mobilityInfo,
      gpsFlag,
      displayedId,
    })
  }

  return newMobilityInfoList
}

export default useMobilityMonitoring

const isEnableGps = (
  currentMobilityInfoList: MobilityInfo[],
  mobilityInfo: MobilityInfo
) => {
  const currentMobilityInfo = currentMobilityInfoList.find(
    (v) => v.mobilityId === mobilityInfo.mobilityId
  )

  if (currentMobilityInfo === undefined) {
    return false
  }

  if (
    currentMobilityInfo.timestamp !== mobilityInfo.timestamp &&
    currentMobilityInfo.latitude === mobilityInfo.latitude &&
    currentMobilityInfo.longitude === mobilityInfo.longitude
  ) {
    return true
  }

  return false
}
