import { useReducer, useMemo } from 'react'

import {
  AreaManagementContext,
  AreaManagementReucer,
  initialAreaManagementState,
} from '../Context/AreaManagementContext'

const AreaManagementProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [areaManagementState, areaManagementDispatch] = useReducer(
    AreaManagementReucer,
    initialAreaManagementState
  )

  const areaManagementValue = useMemo(
    () => ({
      areaManagementState,
      areaManagementDispatch,
    }),
    [areaManagementState]
  )

  return (
    <AreaManagementContext.Provider value={areaManagementValue}>
      {children}
    </AreaManagementContext.Provider>
  )
}

export default AreaManagementProvider
