import { Stack, Card, Skeleton } from '@mui/material'
import { useForm } from 'react-hook-form'

import SubmitButton from '../../../Components/Common/SubmitButton'
import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { useFacilityList } from '../../../Context/useFacilityList'
import { AppLogger } from '../../../Logger/AppLogger'
import useSearchMobility from '../Hooks/useSearchMobility'
import { SearchMobilityInputs } from '../Types/MobilityManagementTypes'

const SearchMobilityCard = () => {
  AppLogger.debug('render: SearchMobilityCard Component.')

  const facilityState = useFacilityList()
  const [searhMobilityState, onSubmit] = useSearchMobility()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SearchMobilityInputs>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: { facilityId: facilityState.facilityList[0]?.facilityId || '' },
  })

  return (
    <Card sx={{ padding: 5 }}>
      {facilityState.isLoading ? (
        <Skeleton sx={{ height: '15vh' }} />
      ) : (
        <Stack direction="column" spacing={3} alignItems="center">
          <SelectFacilityForm
            value={watch('facilityId')}
            error={'facilityId' in errors}
            helperText={errors.facilityId?.message}
            register={register('facilityId', validationRules.facilityId)}
          />

          <SubmitButton
            isLoading={searhMobilityState.isLoading}
            onClick={handleSubmit(onSubmit)}
            text="検索"
          />
        </Stack>
      )}
    </Card>
  )
}

export default SearchMobilityCard
