import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import AddFab from '../../../Components/Common/AddFab'
import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import SelectImeiForm from '../../../Components/Form/SelectImeiForm'
import TextFieldForm from '../../../Components/Form/TextFieldForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { useFacilityList } from '../../../Context/useFacilityList'
import { AppLogger } from '../../../Logger/AppLogger'
import useMobilityManagement from '../Hooks/useMobilityManagement'
import useSearchMobility from '../Hooks/useSearchMobility'
import { initialMobilityInfo, MobilityInfo } from '../Types/MobilityManagementTypes'

const AddMobilityDialog = () => {
  AppLogger.debug('render: AddMobilityDialog Component.')

  const [, , fetch] = useSearchMobility()
  const [
    mobilityManagementState,
    onAdd,
    ,
    ,
    toggleAddDialog,
    ,
    ,
    stopFetch,
    fetchCommunicationDeviceList,
  ] = useMobilityManagement()
  const facilityState = useFacilityList()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<MobilityInfo>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...initialMobilityInfo,
      facilityId: facilityState.facilityList[0]?.facilityId || '',
      available: true,
      mobilityType: 'kupo',
    },
  })

  useEffect(() => {
    if (mobilityManagementState.fetch) {
      stopFetch()
      fetch()
    }
  }, [fetch, mobilityManagementState.fetch, stopFetch])

  return (
    <>
      <Dialog open={mobilityManagementState.addDialog} maxWidth="md">
        <DialogTitle>車両追加</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ padding: 1 }}>
            <TextFieldForm
              label="車両ID"
              value={watch('mobilityId')}
              error={'mobilityId' in errors}
              helperText={errors.mobilityId?.message}
              register={register('mobilityId', validationRules.mobilityId)}
            />
            <TextField
              label="車両名"
              value={watch('mobilityName')}
              error={'mobilityName' in errors}
              helperText={errors.mobilityName?.message}
              {...register('mobilityName', validationRules.mobilityName)}
            />
            {/* <TextField
              label="IMEI"
              value={watch('imei')}
              error={'imei' in errors}
              helperText={errors.imei?.message}
              {...register('imei', validationRules.imei)}
            /> */}
            <SelectImeiForm
              value={watch('imei')}
              error={'imei' in errors}
              helperText={errors.facilityId?.message}
              register={register('imei', validationRules.imei)}
            />
            <SelectFacilityForm
              value={watch('facilityId')}
              error={'facilityId' in errors}
              helperText={errors.facilityId?.message}
              register={register('facilityId', validationRules.facilityId)}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={toggleAddDialog}>
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSubmit(onAdd)}
            disabled={mobilityManagementState.isLoading}
            loading={mobilityManagementState.isLoading}
          >
            OK
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <AddFab
        onclick={() => {
          toggleAddDialog()
          fetchCommunicationDeviceList()
        }}
      />
    </>
  )
}

export default AddMobilityDialog
