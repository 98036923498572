import { createContext, Dispatch } from 'react'

import { StaffFacilityRelation } from '../Pages/StaffManagement/Types/StaffManagementTypes'

export type FacilityListState = {
  facilityList: StaffFacilityRelation[]
  isLoading: boolean
  error: Error | null
}

export const initialFacilityListState: FacilityListState = {
  facilityList: [],
  isLoading: false,
  error: null,
}

export type FacilityListAction =
  | { type: 'FETCH' }
  | { type: 'SUCCESS'; result: StaffFacilityRelation[] }
  | { type: 'FAILD'; error: Error }

export const FacilityListContext = createContext(
  {} as {
    facilityListState: FacilityListState
    facilityListDispatch: Dispatch<FacilityListAction>
  }
)

export const FacilityListReducer = (
  state: FacilityListState,
  action: FacilityListAction
): FacilityListState => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, isLoading: true }
    case 'SUCCESS':
      return { ...state, isLoading: false, facilityList: action.result }
    case 'FAILD':
      return { ...state, isLoading: false, error: action.error }
    default:
      return state
  }
}
