// 20220101T112233123456 => 2022/01/01 11:22:33 or 2022/01/01 11:22
export const convertTimestampForDisplay = (
  timestamp: string,
  includeSecond: boolean
): string => {
  const timestampString = `${timestamp.slice(0, 4)}/${timestamp.slice(
    4,
    6
  )}/${timestamp.slice(6, 8)} ${timestamp.slice(9, 11)}:${timestamp.slice(11, 13)}`

  if (includeSecond) return `${timestampString}:${timestamp.slice(13, 15)}`
  return timestampString
}

// Date => 11:22
export const getFormatTime = (dt: Date | null) => {
  if (dt !== null) {
    const h = dt.getHours()
    const m = dt.getMinutes()
    let fH = ''
    let fM = ''

    if (h < 10) {
      fH = `0${String(h)}`
    } else {
      fH = String(h)
    }

    if (m < 10) {
      fM = `0${String(m)}`
    } else {
      fM = String(m)
    }

    const fMS = `${fH}:${fM}`
    return fMS
  }
  return ''
}

// 20220101T112233123456 =>  Date
export const timestampToDate = (timestamp: string | undefined): Date | null => {
  if (timestamp) {
    const date = timestamp.split('T')[0]
    const time = timestamp.split('T')[1]

    return new Date(
      Number(date.slice(0, 4)),
      Number(date.slice(4, 6)) - 1,
      Number(date.slice(6, 8)),
      Number(time.slice(0, 2)),
      Number(time.slice(2, 4)),
      Number(time.slice(4, 6))
    )
  }
  return null
}

// Date => 20220101T112233123456
export const dateToTimestamp = (date: Date): string => {
  return `${
    String(date.getFullYear()) +
    `0${String(date.getMonth() + 1)}`.slice(-2) +
    `0${String(date.getDate())}`.slice(-2)
  }T${`0${String(date.getHours())}`.slice(-2)}${`0${String(date.getMinutes())}`.slice(
    -2
  )}${`0${String(date.getSeconds())}`.slice(-2)}${String(date.getMilliseconds())}`
}

// goal - current => 11時間22分
export const differenceTimePerMinutes = (goal: Date, current: Date): string => {
  const diffMilliseconds = goal.getTime() - current.getTime()
  const diffMinutes = diffMilliseconds / 1000 / 60

  if (diffMinutes <= 0) return '0分'

  const diffHours = Math.trunc(diffMinutes / 60)
  const diffHoursString = diffHours === 0 ? '' : `${diffHours}時間`

  const diffMinutesString = `${Math.trunc(diffMinutes % 60)}分`

  return diffHoursString + diffMinutesString
}

// Date => "20220901"
export const getDateString = (date: Date): string => {
  return (
    String(date.getFullYear()) +
    `0${String(date.getMonth() + 1)}`.slice(-2) +
    `0${String(date.getDate())}`.slice(-2)
  )
}

export const getTimestampString = (date: Date, time: Date): string => {
  return `${getDateString(date)}T${`0${String(time.getHours())}`.slice(-2)}${`0${String(
    time.getMinutes()
  )}`.slice(-2)}00000`
}
