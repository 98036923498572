import { useContext } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { AlertContext } from '../../../Components/Alert/AlertProvider'
import { StaffSearchAction } from '../Action/StaffSearchAction'
import { SearchStaffContext } from '../Context/StaffSearchContext'
import { SearchStaffInputs } from '../Types/StaffManagementTypes'

const useSearchStaffResult = () => {
  const { dispatch } = useContext(AlertContext)
  const { searchStaffState, searchStaffDispatch } = useContext(SearchStaffContext)

  const onSubmit: SubmitHandler<SearchStaffInputs> = (
    searchInputs: SearchStaffInputs
  ) => {
    StaffSearchAction(searchStaffDispatch, searchInputs, dispatch)
  }

  const fetchResult = () => {
    StaffSearchAction(searchStaffDispatch, searchStaffState.searchInputs, dispatch)
  }

  return [searchStaffState, onSubmit, fetchResult] as const
}

export default useSearchStaffResult
