import { useState, useEffect, useContext } from 'react'
import { Polygon } from 'react-leaflet'

import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import { areaColor } from '../../../Components/Map/Area'
import {
  AreaInfo,
  // EmergencyAreaData
} from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import {
  getCurrentUserAreaInfo,
  // getCurrentUserEmergencyArea
} from '../Repository/UserWatchingRepository'

export type UserEmergencyAreasProps = {
  facilityId: string
}

const UserEmergencyAreas = (props: UserEmergencyAreasProps) => {
  // const [emergencyAreaData, setEmergencyAreaData] = useState<EmergencyAreaData[]>([])
  const [areaData, setAreaData] = useState<AreaInfo[]>([])
  const { dispatch } = useContext(AlertContext)

  // useEffect(() => {
  //   getCurrentUserEmergencyArea(props.facilityId)
  //     .then((response: EmergencyAreaData[]) => {
  //       setEmergencyAreaData(response)
  //     })
  //     .catch((error: Error) => {
  //       ErrorAlert(dispatch, error)
  //     })
  // }, [])

  useEffect(() => {
    getCurrentUserAreaInfo(props.facilityId)
      .then((response: AreaInfo[]) => {
        setAreaData(response)
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
  }, [])

  return (
    <>
      {/* {emergencyAreaData.map((data, index) => (
        <Polygon
          pathOptions={data.areaColor}
          positions={data.areaCoordinate}
          key={index}
        />
      ))} */}
      {areaData.map((data, index) => (
        <Polygon
          // pathOptions={{ color: areaColor(data.areaType) }}
          pathOptions={{ color: areaColor(data) }}
          positions={data.coordinateList}
          key={index}
        />
      ))}
    </>
  )
}

export default UserEmergencyAreas
