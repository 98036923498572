import { AlertColor } from '@mui/material'
import Axios from 'axios'
import {
  useReducer,
  useMemo,
  createContext,
  Dispatch,
  useContext,
  useEffect,
} from 'react'

import { AlertState } from './AppAlert'

export type AlertAction =
  | { type: 'OPEN'; severity: AlertColor; title: string; text: string }
  | { type: 'CLOSE' }

export const useAppAlert = () => {
  const { state, dispatch } = useContext(AlertContext)

  const openSuccessAlert = (text: string) => {
    dispatch({
      type: 'OPEN',
      severity: 'success',
      title: 'Success',
      text,
    })
  }

  const openErrorAlert = (error: Error) => {
    if (Axios.isAxiosError(error) && error.response) {
      const responseData: Error = error.response.data as Error
      dispatch({
        type: 'OPEN',
        severity: 'error',
        title: error.name,
        text: responseData.message,
      })
    } else {
      dispatch({
        type: 'OPEN',
        severity: 'error',
        title: error.name,
        text: error.message,
      })
    }
  }

  const closeAlert = () => {
    dispatch({ type: 'CLOSE' })
  }

  useEffect(() => {
    closeAlert()
  }, [])

  return [openSuccessAlert, openErrorAlert, closeAlert, state] as const
}

export const SuccessAlert = (dispatch: Dispatch<AlertAction>, text: string) => {
  dispatch({
    type: 'OPEN',
    severity: 'success',
    title: 'Success',
    text,
  })
}

export const ErrorAlert = (dispatch: Dispatch<AlertAction>, error: Error) => {
  if (Axios.isAxiosError(error) && error.response) {
    const responseData: Error = error.response.data as Error
    dispatch({
      type: 'OPEN',
      severity: 'error',
      title: error.name,
      text: responseData.message,
    })
  } else {
    dispatch({
      type: 'OPEN',
      severity: 'error',
      title: error.name,
      text: error.message,
    })
  }
}

const AlertReducer = (state: AlertState, action: AlertAction): AlertState => {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        isOpen: true,
        severity: action.severity,
        title: action.title,
        text: action.text,
      }
    case 'CLOSE':
      return { ...state, isOpen: false, severity: 'info', title: '', text: '' }
    default:
      return state
  }
}

export const AlertContext = createContext(
  {} as {
    state: AlertState
    dispatch: Dispatch<AlertAction>
  }
)

const initialAlertState: AlertState = {
  isOpen: false,
  severity: 'info',
  title: '',
  text: '',
}

const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(AlertReducer, initialAlertState)
  const value = useMemo(() => ({ state, dispatch }), [state])

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

export default AlertProvider
