import { Skeleton } from '@mui/material'
import { GridColDef, DataGrid, GridEventListener, jaJP } from '@mui/x-data-grid'
import { useState } from 'react'

import useSearchStaffResult from '../Hooks/useSearchStaffResult'
import { StaffInfo } from '../Types/StaffManagementTypes'

import UpdateStaffDialog from './UpdateStaffDialog'

const SearchStaffResult = () => {
  const columns: GridColDef[] = [
    { field: 'staffId', headerName: '担当者ID', width: 200 },
    { field: 'staffName', headerName: '担当者名', width: 200 },
    { field: 'email', headerName: 'メールアドレス', width: 200 },
  ]

  const [searchState] = useSearchStaffResult()
  const [isOpen, setIsOpen] = useState(false)
  const [staffInfo, setStaffInfo] = useState<StaffInfo>({
    staffId: '',
    staffName: '',
    email: '',
  })

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    const info = params.row as StaffInfo
    setStaffInfo(info)
    setIsOpen(!isOpen)
  }

  const onClose = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      {searchState.isLoading ? (
        <Skeleton height="25vh" />
      ) : (
        <DataGrid
          autoHeight
          rows={searchState.searchResult}
          columns={columns}
          getRowId={(row: StaffInfo) => row.staffId}
          hideFooterSelectedRowCount
          onRowClick={onRowClick}
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
      )}

      <UpdateStaffDialog isOpen={isOpen} onClose={onClose} staffInfo={staffInfo} />
    </>
  )
}

export default SearchStaffResult
