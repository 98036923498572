import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

import { AppLogger } from '../../../Logger/AppLogger'
import useMobilityManagement from '../Hooks/useMobilityManagement'
import {
  DisableMobilityDialogProps,
  MobilityInfo,
} from '../Types/MobilityManagementTypes'

const DisableMobilityDialog = (props: DisableMobilityDialogProps) => {
  AppLogger.debug('render: DisableMobilityDialog Component.')

  const [mobilityManagementState, , , onDisable, , , toggleDisableialog, ,] =
    useMobilityManagement()

  const onDisableSubmit = () => {
    const mobilityInfo: MobilityInfo = {
      ...props.mobilityInfo,
      // facilityId: '',
      available: false,
    }
    onDisable(mobilityInfo)
    toggleDisableialog()
  }

  return (
    <Dialog
      open={mobilityManagementState.disableDialog}
      onClose={toggleDisableialog}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        削除確認
      </DialogTitle>
      <DialogContent>
        <DialogContentText>本当に削除してもよろしいですか？</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={toggleDisableialog} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          onClick={onDisableSubmit}
          color="primary"
          disabled={mobilityManagementState.isLoading}
          loading={mobilityManagementState.isLoading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DisableMobilityDialog
