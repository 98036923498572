import { LatLngExpression } from 'leaflet'
import { useContext, useEffect, useState } from 'react'
import useInterval from 'use-interval'
import useSound from 'use-sound'

import dangerousAreaSound from '../../../Assets/Sounds/dangerArea.mp3'
import endTimeAnnouncementSound from '../../../Assets/Sounds/endTimeAnnouncementSound.mp3'
import { dateToTimestamp, timestampToDate } from '../../../Common/TimestampConverter'
import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import { AppLogger } from '../../../Logger/AppLogger'
import { MobilityInfo } from '../../MobilityManagement/Types/MobilityManagementTypes'
import { AreaJudgement } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import { UserInfo } from '../../UserManagement/Types/UserManagementTypes'
import { UserWatchingContext } from '../Context/UserWatchingContext'
import {
  MobileData,
  putMobileDataDangerousAreaDetection,
  getReturnAreaJudgement,
  getCurrentUserMobilityInfo,
} from '../Repository/UserWatchingRepository'
import { LocationData } from '../Types/UserWatchingTypes'

type useUserWatchingProps = {
  userInfo: UserInfo
}

export const initialWatchingLocation: LatLngExpression = [
  34.762759757497946, 137.63329094144157,
]
const useUserWatching = (props: useUserWatchingProps) => {
  const { userWatchingState, userWatchingDispatch } = useContext(UserWatchingContext)

  const geolocationOption = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
  }
  useEffect(() => {
    // 位置情報の取得
    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      geolocationOption
    )
    const watchId = navigator.geolocation.watchPosition(
      successCallback,
      errorCallback,
      geolocationOption
    )

    return () => {
      navigator.geolocation.clearWatch(watchId)
    }
  }, [])

  const successCallback: PositionCallback = (position) => {
    const newLocationData: LocationData = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      speed: position.coords.speed,
      timestamp: new Date(position.timestamp),
      altitude: position.coords.altitude,
      accuracy: position.coords.accuracy,
      altitudeAccuracy: position.coords.altitudeAccuracy,
      heading: position.coords.heading || userWatchingState.heading,
    }

    userWatchingDispatch({ type: 'SET_LOCATION', locationData: newLocationData })
  }

  const { dispatch } = useContext(AlertContext)
  const errorCallback: PositionErrorCallback = (error) => {
    dispatch({ type: 'OPEN', title: 'Error', text: error.message, severity: 'error' })
  }

  // 端末の向き情報の取得
  const addOrientationEvent = () => {
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', orientationEvent)
    }
  }

  const orientationEvent = (e: DeviceOrientationEvent) => {
    const heading = e.alpha ? Math.trunc(-(e.alpha - 90)) : null

    if (!userWatchingState.heading) userWatchingDispatch({ type: 'SET_HEADING', heading })
    else if (Math.trunc(userWatchingState.heading) !== heading)
      userWatchingDispatch({ type: 'SET_HEADING', heading })
  }

  const [dangerousAreaSoundPlay] = useSound(dangerousAreaSound as string)
  const [endTimeAnnouncementSoundPlay] = useSound(endTimeAnnouncementSound as string)

  const [areaJudgement, setAreaJudgement] = useState<AreaJudgement[]>([])
  const [announcementFlag, setAnnouncementFlag] = useState<boolean>(false)

  useInterval(() => {
    if (
      userWatchingState.locationData.latitude !== initialWatchingLocation[0] &&
      userWatchingState.locationData.longitude !== initialWatchingLocation[1]
    ) {
      const mobileData: MobileData = {
        userId: props.userInfo.userId,
        facilityId: props.userInfo.facilityId,
        mobilityId: props.userInfo.mobilityId,
        latitude: userWatchingState.locationData.latitude,
        longitude: userWatchingState.locationData.longitude,
        accuracy: userWatchingState.locationData.accuracy,
        timestamp: dateToTimestamp(new Date()),
      }

      putMobileDataDangerousAreaDetection(mobileData)
        .then((response: AreaJudgement[]) => {
          setAreaJudgement(response)
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })

      if (areaJudgement.length !== 0 && areaJudgement[0].result !== 0) {
        dangerousAreaSoundPlay()
      }

      getReturnAreaJudgement(mobileData)
        .then((response: AreaJudgement[]) => {
          if (response.length !== 0)
            userWatchingDispatch({ type: 'SET_RETURN_AREA', returnArea: response[0] })
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })

      const endDate = timestampToDate(props.userInfo.scheduledEndTime)

      if (endDate) {
        const endTime = endDate.getTime()
        const now = Date.now()

        const diffMin = Math.abs(now - endTime) / (60 * 1000)

        if (diffMin <= 10 && !announcementFlag) {
          const flag = announcementFlag
          setAnnouncementFlag(!flag)
          endTimeAnnouncementSoundPlay()
          AppLogger.debug('Announced!!')
        }
      }
    }
  }, 10000)

  const [currentDate, setCurrentDate] = useState(new Date())
  const [currentMobilityState, setCurrentMobilityState] = useState<MobilityInfo[]>([])

  useInterval(
    () => {
      setCurrentDate(new Date())
    },
    60000,
    true
  )

  useInterval(
    () => {
      getCurrentUserMobilityInfo(props.userInfo.mobilityId)
        .then((response: MobilityInfo[]) => {
          setCurrentMobilityState(response)
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })
    },
    8000,
    true
  )

  return [
    userWatchingState.returnAreaJudgement,
    currentDate,
    currentMobilityState,
    addOrientationEvent,
  ] as const
}

export default useUserWatching

export const useLocationData = () => {
  const { userWatchingState } = useContext(UserWatchingContext)
  return userWatchingState.locationData
}
