/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from '@mui/material'
import L, { Marker as leafletMarker } from 'leaflet'
import { useContext, useEffect, useRef } from 'react'
import { Marker, Popup, useMap } from 'react-leaflet'

import {
  convertTimestampForDisplay,
  timestampToDate,
} from '../../../Common/TimestampConverter'
import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import { AppLogger } from '../../../Logger/AppLogger'
import { MobilityInfo } from '../../MobilityManagement/Types/MobilityManagementTypes'
import { setEmergencyFlag } from '../../UserWatching/Repository/UserWatchingRepository'
import useMobilityMonitoring from '../Hooks/useMobilityMonitoring'

import { ControllerErrorList } from './ControllerErrorList'

import './MarkerStyle.css'

const MapMarkers = () => {
  const map = useMap()
  const [mobilityMonitoringState] = useMobilityMonitoring()

  useEffect(() => {
    const positions: [number, number][] = []

    // eslint-disable-next-line no-restricted-syntax
    for (const mobility of mobilityMonitoringState.mobilityInfoList) {
      if (mobility.latitude) positions.push([mobility.latitude, mobility.longitude])
    }
    if (positions.length !== 0) {
      map.fitBounds(positions)
    }
  }, [mobilityMonitoringState.mobilityInfoList.length])

  return (
    <>
      {mobilityMonitoringState.mobilityInfoList.map((mobility) => {
        return (
          <MapMarker
            key={mobility.mobilityId}
            id={mobility.displayedId}
            mobility={mobility}
            popUpIsOpen={
              mobilityMonitoringState.selectedMobiliytOnList?.mobilityId ===
              mobility.mobilityId
            }
          />
        )
      })}
    </>
  )
}

export default MapMarkers

export const getMobilityMode = (state: number): string => {
  if (state < 10) return '始動'
  if (state === 10) return '変形'
  if (state < 20) return '低SOC'
  if (state < 40) return 'プッシュモード'
  if (state < 50) return 'ドライブモード'
  if (state < 50) return 'ドライブモード'
  if (state < 60) return 'オートスリープ'
  if (state < 70) return '電磁ブレーキ強制解除'
  if (state < 80) return '故障'
  if (state < 90) return '充電'
  return 'メンテナンスモード'
}

type MapMarkerProps = {
  id: string
  mobility: MobilityInfo
  popUpIsOpen: boolean
}

const markerStyle = (mobility: MobilityInfo): string => {
  if (mobility.soc == null) {
    return 'circle-marker circle-marker-blue'
  }

  if (mobility.soc > 60) {
    return 'circle-marker circle-marker-blue'
  }

  if (mobility.soc > 30) {
    return 'circle-marker circle-marker-orange'
  }

  return 'circle-marker circle-marker-red'
}

export const checkLocationDataCondition = (timestamp: string): boolean => {
  console.log(`timestamp: ${timestamp}`)
  if (timestamp == null) {
    // nullかundefinedの場合falseを返却
    return false
  }

  const now = new Date()
  const latestTimestamp = timestampToDate(timestamp)

  if (latestTimestamp === null) {
    return false
  }

  const timeDiffInMinutes = (now.getTime() - latestTimestamp.getTime()) / (1000 * 60)
  console.log(timeDiffInMinutes)

  if (timeDiffInMinutes < 10) {
    return true
  }

  return false
}

export const calculateUsageTime = (startedAt: string): string => {
  console.log(`startedAt: ${startedAt}`)
  if (startedAt == null) {
    // nullかundefinedの場合、文字列'-'を返却
    return '-'
  }

  const now = new Date()
  const startedAtDate = timestampToDate(startedAt)

  if (startedAtDate === null) {
    // nullの場合、文字列'-'を返却
    return '-'
  }

  const timeDiffInMinutes = Math.floor(
    (now.getTime() - startedAtDate.getTime()) / (1000 * 60)
  )
  console.log(timeDiffInMinutes)

  if (timeDiffInMinutes > 60) {
    return `${Math.floor(timeDiffInMinutes / 60)}時間${timeDiffInMinutes % 60}分`
  }
  return `${timeDiffInMinutes}分`
}

export const MapMarker = (props: MapMarkerProps) => {
  const markerRef = useRef<leafletMarker | null>(null)

  useEffect(() => {
    if (props.popUpIsOpen) {
      const marker = markerRef.current
      marker?.openPopup()
    }
  }, [props.popUpIsOpen])

  const [, , checkUser] = useMobilityMonitoring()
  const selectedUser = checkUser(props.mobility.mobilityId)

  const checkErrorForDisplay = (): string => {
    if (props.mobility.fallDownFlag) return '転倒中'

    if (selectedUser) {
      if (selectedUser.emergencyFlag) return '呼び出し中'
      if (selectedUser.dangerousAreaFlag) return '危険エリア侵入'
    }

    const activeError = ControllerErrorList.find(
      (error) => error.id === props.mobility.errorPriority1
    )
    if (activeError) return activeError.approach

    return '異常なし'
  }

  const { dispatch } = useContext(AlertContext)

  const stopEmergencyCall = () => {
    if (selectedUser)
      setEmergencyFlag(selectedUser?.userId, false)
        .then(() => {
          AppLogger.debug('stop call.')
        })
        .catch((error: Error) => {
          ErrorAlert(dispatch, error)
        })
  }

  return (
    <>
      {checkErrorForDisplay() !== '異常なし' && props.mobility.latitude ? (
        <Marker
          position={[props.mobility.latitude, props.mobility.longitude]}
          icon={L.divIcon({
            html: '<div></div>',
            className: 'error_marker',
            iconSize: [60, 60],
          })}
        />
      ) : (
        <></>
      )}

      {props.mobility.latitude ? (
        <Marker
          ref={markerRef}
          position={[props.mobility.latitude, props.mobility.longitude]}
          icon={L.divIcon({
            html: `<div>${props.id}</div>`,
            className: markerStyle(props.mobility),
            iconSize: [32, 32],
          })}
        >
          <Popup>
            <Typography variant="h6">
              <strong>{props.mobility.mobilityName}</strong>
            </Typography>
            {/* <Typography variant="body2">
              車両モード：{getMobilityMode(props.mobility.vehicleState)}
            </Typography> */}

            {/* <Typography variant="body2">
              車両エラー：
              {checkErrorForDisplay()}{' '}
              {selectedUser?.emergencyFlag ? (
                <Button variant="contained" size="small" onClick={stopEmergencyCall}>
                  停止
                </Button>
              ) : (
                <></>
              )}
            </Typography> */}

            {/* <Typography variant="body2">
              利用者：{(selectedUser?.lastName || '') + (selectedUser?.firstName || '')}
            </Typography> */}
            {/* <Typography variant="body2">
              利用ステータス：
              {getActiveName(selectedUser?.active || -2)}
            </Typography> */}
            <Typography variant="body2">
              <strong>
                位置情報：
                {checkLocationDataCondition(props.mobility.timestamp)
                  ? ''
                  : '位置ズレあり'}
              </strong>
            </Typography>
            <Typography variant="body2">
              <strong>
                利用時間：
                {calculateUsageTime(props.mobility.startedAt)}
              </strong>
            </Typography>
            <Typography variant="body2">
              データ発生時間：
              {props.mobility.timestamp
                ? convertTimestampForDisplay(props.mobility.timestamp, true)
                : ''}
            </Typography>
            {/* <Typography variant="body2">
              サーバ受信時間：
              {props.mobility.serverReceptionTime
                ? convertTimestampForDisplay(props.mobility.serverReceptionTime, true)
                : ''}
            </Typography> */}
            {/* <Typography variant="body2">
              通信遅延時間：{props.mobility.diff ? `${props.mobility.diff}秒` : ''}
            </Typography> */}
            <Typography variant="body2">
              送信元：
              {props.mobility.publisher == null ? '-' : `${props.mobility.publisher}`}
            </Typography>
            <Typography variant="body2">
              充電残量：{props.mobility.soc == null ? '-' : `${props.mobility.soc} %`}
            </Typography>
          </Popup>
        </Marker>
      ) : (
        <></>
      )}
    </>
  )
}
