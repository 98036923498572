import { createContext, Dispatch } from 'react'

import {
  CommunicationDeviceInfo,
  initialMobilityInfo,
  MobilityInfo,
} from '../Types/MobilityManagementTypes'

export type MobilityManagementAction =
  | { type: 'ADD'; inputs: MobilityInfo }
  | { type: 'UPDATE'; inputs: MobilityInfo }
  | { type: 'DISABLE'; inputs: MobilityInfo }
  | { type: 'SUCCESS' }
  | { type: 'FAILD'; error: Error }
  | { type: 'TOGGLE_ADD_DIALOG' }
  | { type: 'TOGGLE_UPDATE_DIALOG' }
  | { type: 'TOGGLE_DISABLE_DIALOG' }
  | { type: 'STOP_FETCH' }
  | { type: 'FETCH_DEVICE' } // IMEIのリストを取得
  | { type: 'FETCH_DEVICE_SUCCESS'; deviceList: CommunicationDeviceInfo[] } // IMEIのリストを取得完了後

export type MobilityManagementState = {
  inputs: MobilityInfo
  isLoading: boolean
  error: Error | null
  addDialog: boolean
  updateDialog: boolean
  disableDialog: boolean
  fetch: boolean
  deviceList: CommunicationDeviceInfo[]
}

export const initialMobilityManagementState: MobilityManagementState = {
  inputs: initialMobilityInfo,
  isLoading: false,
  error: null,
  addDialog: false,
  updateDialog: false,
  disableDialog: false,
  fetch: false,
  deviceList: [],
}

export const MobilityManagementContext = createContext(
  {} as {
    mobilityManagementState: MobilityManagementState
    mobilityManagementDispatch: Dispatch<MobilityManagementAction>
  }
)

export const MobilityManagementReducer = (
  state: MobilityManagementState,
  action: MobilityManagementAction
): MobilityManagementState => {
  switch (action.type) {
    case 'ADD':
      return { ...state, isLoading: true, inputs: action.inputs }
    case 'UPDATE':
      return { ...state, isLoading: true, inputs: action.inputs }
    case 'DISABLE':
      return { ...state, isLoading: true, inputs: action.inputs }
    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        addDialog: false,
        updateDialog: false,
        disableDialog: false,
        fetch: true,
      }
    case 'FAILD':
      return { ...state, isLoading: false, error: action.error }
    case 'TOGGLE_ADD_DIALOG':
      return { ...state, addDialog: !state.addDialog }
    case 'TOGGLE_UPDATE_DIALOG':
      return { ...state, updateDialog: !state.updateDialog }
    case 'TOGGLE_DISABLE_DIALOG':
      return { ...state, disableDialog: !state.disableDialog }
    case 'STOP_FETCH':
      return { ...state, fetch: false }
    case 'FETCH_DEVICE':
      return { ...state, isLoading: true }
    case 'FETCH_DEVICE_SUCCESS':
      return { ...state, isLoading: false, deviceList: action.deviceList }
    default:
      return state
  }
}
