import { createContext, Dispatch } from 'react'

import { UserGroup } from './useUserGroup'

export type UserGroupState = {
  userGroupList: UserGroup[]
  isLoading: boolean
  error: Error | null
}

export const initialUserGroupState: UserGroupState = {
  userGroupList: [],
  isLoading: false,
  error: null,
}

export type UserGroupAction =
  | { type: 'FETCH' }
  | { type: 'SUCCESS'; result: UserGroup[] }
  | { type: 'FAILD'; error: Error }

export const UserGroupContext = createContext(
  {} as {
    userGroupState: UserGroupState
    userGroupDispatch: Dispatch<UserGroupAction>
  }
)

export const UserGroupReducer = (
  state: UserGroupState,
  action: UserGroupAction
): UserGroupState => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, isLoading: true }
    case 'SUCCESS':
      return { ...state, isLoading: false, userGroupList: action.result }
    case 'FAILD':
      return { ...state, isLoading: false, error: action.error }
    default:
      return state
  }
}
