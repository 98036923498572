import { Typography } from '@mui/material'
import { useContext } from 'react'

import { HeaderContext } from '../../Context/HeaderContext'

const AppFooter = () => {
  const { headerState } = useContext(HeaderContext)

  if (!headerState.isVisible) return <div className="no footer" />

  return (
    <Typography
      variant="caption"
      sx={{
        textAlign: 'center',
        width: '100%',
        position: 'fixed',
        bottom: 0,
      }}
    >
      &copy; Suzuki Motor Corporation All Rights Reserved.
    </Typography>
  )
}

export default AppFooter
