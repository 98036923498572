import { CircularProgress, LinearProgress, Stack } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, jaJP } from '@mui/x-data-grid'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import SelectFacilityForm from '../../../Components/Form/SelectFacilityForm'
import { validationRules } from '../../../Components/Form/ValidationRules'
import { useFacilityList } from '../../../Context/useFacilityList'
import { AreaInfo } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import useAreaManagement from '../Hook/useAreaManagement'
import { AreaSearchInputs } from '../Types/AreaManagement'

const AreaListWithSearch = () => {
  const { fetchArea, selectFacility } = useAreaManagement()

  const facilityState = useFacilityList()
  const {
    register,
    formState: { errors },
    watch,
    getValues,
  } = useForm<AreaSearchInputs>({
    defaultValues: { facilityId: facilityState.facilityList[0]?.facilityId || '' },
  })

  useEffect(() => {
    const selectedFacilityId = getValues().facilityId

    selectFacility(selectedFacilityId)
    if (selectedFacilityId !== '') {
      fetchArea(selectedFacilityId)
    }
  }, [getValues().facilityId])

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        {facilityState.isLoading ? (
          <CircularProgress />
        ) : (
          <SelectFacilityForm
            value={watch('facilityId')}
            error={'facilityId' in errors}
            helperText={errors.facilityId?.message}
            register={register('facilityId', validationRules.facilityId)}
          />
        )}
      </Stack>

      <AreaList />
    </Stack>
  )
}

const AreaList = () => {
  const { areaManagementState, selectArea } = useAreaManagement()

  const columns: GridColDef[] = [
    { field: 'areaId', headerName: 'ID', width: 100 },
    { field: 'areaName', headerName: 'エリア名', flex: 1 },
    { field: 'areaType', headerName: 'タイプ', width: 160 },
  ]

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    const area = params.row as AreaInfo
    selectArea(area)
  }

  return (
    <DataGrid
      autoHeight
      pageSize={8}
      hideFooterSelectedRowCount
      rows={areaManagementState.areaInfoList}
      columns={columns}
      getRowId={(row: AreaInfo) => row.areaId}
      onRowClick={onRowClick}
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      components={{
        LoadingOverlay: LinearProgress,
      }}
      loading={areaManagementState.isFetchingArea}
    />
  )
}

export default AreaListWithSearch
