import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'

import usePutFacility from '../Hooks/usePutFacility'
import {
  AddFacilityDialogProps,
  FacilityInfo,
  Prefecture,
} from '../Types/FacilityManagementTypes'

const AddFacilityDialog = (props: AddFacilityDialogProps) => {
  const shizuoka = '22'
  const { register, handleSubmit, watch } = useForm<FacilityInfo>({
    defaultValues: {
      facilityId: '',
      facilityName: '',
      prefCode: shizuoka,
      available: true,
    },
  })

  const onSubmit = usePutFacility(props.onClose)

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>施設追加</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: 1 }}>
          <TextField
            label="施設ID"
            helperText="施設IDを入力してください"
            {...register('facilityId', { required: true })}
          />

          <TextField
            label="施設名"
            helperText="施設名を入力してください"
            {...register('facilityName', { required: true })}
          />

          <TextField
            select
            label="都道府県"
            helperText="都道府県を選択してください"
            value={watch('prefCode')}
            {...register('prefCode', {})}
          >
            {props.prefectureList.map((prefecture: Prefecture) => {
              return (
                <MenuItem value={prefecture.prefCode} key={prefecture.prefCode}>
                  {prefecture.prefecture}
                </MenuItem>
              )
            })}
          </TextField>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          CANCEL
        </Button>
        <Button color="primary" onClick={handleSubmit(onSubmit)} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddFacilityDialog
