import { ThemeProvider } from '@emotion/react'
import { BrowserRouter } from 'react-router-dom'

import AlertProvider from '../Components/Alert/AlertProvider'
import AppTheme from '../Theme/AppTheme'

import FacilityListProvider from './FacilityListProvider'
import HeaderProvider from './HeaderProvider'
import PrefectureListProvider from './PrefectureListProvider'
import UserGroupProvider from './UserGroupProvider'

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={AppTheme}>
      <BrowserRouter>
        <HeaderProvider>
          <AlertProvider>
            <UserGroupProvider>
              <FacilityListProvider>
                <PrefectureListProvider>{children}</PrefectureListProvider>
              </FacilityListProvider>
            </UserGroupProvider>
          </AlertProvider>
        </HeaderProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default AppProvider
