import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useContext } from 'react'

import {
  AlertContext,
  ErrorAlert,
  SuccessAlert,
} from '../../../Components/Alert/AlertProvider'
import { disableFacilityInfo } from '../Repository/FacilityRepository'
import { DisableFacilityDialogProps } from '../Types/FacilityManagementTypes'

const DisableFacilityDialog = (props: DisableFacilityDialogProps) => {
  const { dispatch } = useContext(AlertContext)

  const onSubmit = () => {
    dispatch({ type: 'CLOSE' })

    disableFacilityInfo(props.facilityInfo)
      .then((response) => {
        SuccessAlert(dispatch, response)
        props.onClose()
        props.onUpdateClose()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>削除確認</DialogTitle>
      <DialogContent>
        <DialogContentText>本当に削除してもよろしいですか？</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisableFacilityDialog
