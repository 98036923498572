import { useContext } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { AlertContext, ErrorAlert } from '../../../Components/Alert/AlertProvider'
import { SearchMobilityContext } from '../Context/SearchMobilityContext'
import { searchMobility } from '../Repository/MobilityRepository'
import { MobilityInfo, SearchMobilityInputs } from '../Types/MobilityManagementTypes'

const useSearchMobility = () => {
  const { searchMobilityState, searchMobilityDispatch } =
    useContext(SearchMobilityContext)

  const { dispatch } = useContext(AlertContext)

  const SearchMobility = (searchInputs: SearchMobilityInputs) => {
    dispatch({ type: 'CLOSE' })
    searchMobilityDispatch({ type: 'SEARCH', searchInputs })

    searchMobility(searchInputs)
      .then((searchResult: MobilityInfo[]) => {
        searchMobilityDispatch({ type: 'SUCCESS', result: searchResult })
      })
      .catch((error: Error) => {
        searchMobilityDispatch({ type: 'FAILD', error })
        ErrorAlert(dispatch, error)
      })
  }

  const onSubmit: SubmitHandler<SearchMobilityInputs> = (
    inputs: SearchMobilityInputs
  ) => {
    SearchMobility(inputs)
  }

  const fetch = () => {
    SearchMobility(searchMobilityState.searchInputs)
  }

  return [searchMobilityState, onSubmit, fetch] as const
}

export default useSearchMobility
