import { Stack } from '@mui/material'

import AppAlert from '../../Components/Alert/AppAlert'
import PageTitle from '../../Components/Common/PageTitle'
import { AppLogger } from '../../Logger/AppLogger'

import AddMobilityDialog from './Components/AddMobilityDialog'
import SearchMobilityCard from './Components/SearchMobilityCard'
import SearchMobilityResult from './Components/SearchMobilityResult'
import MobilityManagementProvider from './Provider/MobilityManagementProvider'

const MobilityManagement = () => {
  AppLogger.debug('render: MobilityManagement page.')

  return (
    <div className="MobilityManagement">
      <MobilityManagementProvider>
        <PageTitle title="車両管理画面" />
        <Stack spacing={2}>
          <AppAlert />
          <SearchMobilityCard />
          <SearchMobilityResult />
        </Stack>
        <AddMobilityDialog />
      </MobilityManagementProvider>
    </div>
  )
}

export default MobilityManagement
