import { createContext, Dispatch } from 'react'

import { AreaInfo } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import { AreaVertex, MapViewSetting } from '../Types/AreaManagement'

export type AreaManagementState = {
  selectedFacilityId: string
  areaInfoList: AreaInfo[]
  selectedArea: AreaInfo | null
  isFetchingArea: boolean
  isOpenAddDialog: boolean
  isOpenEditDialog: boolean
  isOpenDisableDialog: boolean
  isProcessingCommand: boolean
  editingArea: AreaVertex[]
  editingMapSetting: MapViewSetting
}

export const initialAreaManagementState: AreaManagementState = {
  selectedFacilityId: '',
  areaInfoList: [],
  selectedArea: null,
  isFetchingArea: false,
  isOpenAddDialog: false,
  isOpenEditDialog: false,
  isOpenDisableDialog: false,
  isProcessingCommand: false,
  editingArea: [],
  editingMapSetting: { center: [0.0, 0.0], zoom: 1 },
}

export type AreaManagementAction =
  | { type: 'FETCH_AREA_PROCESSING' }
  | { type: 'FETCH_AREA_FINISHED'; result: AreaInfo[] }
  | { type: 'SELECT_AREA'; area: AreaInfo | null }
  | { type: 'SELECT_FACILITY'; facilityId: string }
  | { type: 'OPEN_ADD_DIALOG' }
  | { type: 'CLOSE_ADD_DIALOG' }
  | { type: 'OPEN_EDIT_DIALOG' }
  | { type: 'CLOSE_EDIT_DIALOG' }
  | { type: 'OPEN_DISABLE_DIALOG' }
  | { type: 'CLOSE_DISABLE_DIALOG' }
  | { type: 'COMMAND_PROCESSING' }
  | { type: 'COMMAND_FINISHED' }
  | { type: 'ADD_EDITING_AREA_VERTEX'; vertex: AreaVertex }
  | { type: 'UPDATE_EDITING_AREA_VERTEX'; index: number; vertex: AreaVertex }
  | { type: 'DELETE_EDITING_AREA_VERTEX'; index: number }
  | { type: 'UPDATE_EDITING_AREA_VERTEXES'; vertexes: AreaVertex[] }
  | { type: 'UPDATE_EDTING_MAP_SETTING'; setting: MapViewSetting }

export const AreaManagementContext = createContext(
  {} as {
    areaManagementState: AreaManagementState
    areaManagementDispatch: Dispatch<AreaManagementAction>
  }
)

export const AreaManagementReucer = (
  state: AreaManagementState,
  action: AreaManagementAction
): AreaManagementState => {
  switch (action.type) {
    case 'FETCH_AREA_PROCESSING':
      return { ...state, isFetchingArea: true, areaInfoList: [] }
    case 'FETCH_AREA_FINISHED':
      return { ...state, isFetchingArea: false, areaInfoList: action.result }
    case 'SELECT_AREA':
      return { ...state, selectedArea: action.area }
    case 'SELECT_FACILITY':
      return { ...state, selectedFacilityId: action.facilityId }
    case 'OPEN_ADD_DIALOG':
      return { ...state, isOpenAddDialog: true }
    case 'CLOSE_ADD_DIALOG':
      return { ...state, isOpenAddDialog: false }
    case 'OPEN_EDIT_DIALOG':
      return { ...state, isOpenEditDialog: true }
    case 'CLOSE_EDIT_DIALOG':
      return { ...state, isOpenEditDialog: false }
    case 'OPEN_DISABLE_DIALOG':
      return { ...state, isOpenDisableDialog: true }
    case 'CLOSE_DISABLE_DIALOG':
      return { ...state, isOpenDisableDialog: false }
    case 'COMMAND_PROCESSING':
      return { ...state, isProcessingCommand: true }
    case 'COMMAND_FINISHED':
      return { ...state, isProcessingCommand: false }
    case 'ADD_EDITING_AREA_VERTEX': {
      // ジオンフェンスの領域内外判定ようなシビアな判定ではないため、longtude→x座標、latitude→y座標にみたてて計算
      // シビアに判定したい場合は平面直角座標系へ変換する必要アリ

      const currentArea = [...state.editingArea]
      const pointA = [action.vertex.longitude, action.vertex.latitude]

      let nearbyPointIndex = 0
      let minDistance = Number.MAX_SAFE_INTEGER

      for (let i = 0; i < currentArea.length; i += 1) {
        const pointP = [currentArea[i].longitude, currentArea[i].latitude]

        const pointQ =
          i !== currentArea.length - 1
            ? [currentArea[i + 1].longitude, currentArea[i + 1].latitude]
            : [currentArea[0].longitude, currentArea[0].latitude]

        // 計算に必要なベクトルを導出
        const vecPQ = [pointQ[0] - pointP[0], pointQ[1] - pointP[1]]
        const vecPA = [pointA[0] - pointP[0], pointA[1] - pointP[1]]
        const vecQA = [pointA[0] - pointQ[0], pointA[1] - pointQ[1]]

        // 追加したい点が線分上にあるかを判定
        // PQベクトルとPAベクトルの内積が負ではない かつ PQベクトルとQAベクトルの内積が正でない
        if (
          !(vecPQ[0] * vecPA[0] + vecPQ[1] * vecPA[1] < 0) &&
          !(vecPQ[0] * vecQA[0] + vecPQ[1] * vecQA[1] > 0)
        ) {
          // ベクトルの外積公式から点と線分間の距離を導出
          const distance =
            Math.abs(vecPQ[0] * vecPA[1] - vecPQ[1] * vecPA[0]) /
            Math.sqrt(vecPQ[0] ** 2 + vecPQ[1] ** 2)

          // 最初に距離が計算されたとき
          if (minDistance > distance) {
            minDistance = distance
            nearbyPointIndex = i
          }
        }
      }

      // spliceメソッドは削除した配列の要素を返す
      const newArea = [...currentArea]
      newArea.splice(nearbyPointIndex + 1, 0, action.vertex)

      // return { ...state, editingArea: [...state.editingArea, action.vertex] }
      return { ...state, editingArea: newArea }
    }
    case 'UPDATE_EDITING_AREA_VERTEX': {
      const newArea = [...state.editingArea]
      newArea[action.index] = { ...action.vertex }
      return { ...state, editingArea: newArea }
    }
    case 'DELETE_EDITING_AREA_VERTEX':
      return {
        ...state,
        editingArea: [
          ...state.editingArea.slice(0, action.index),
          ...state.editingArea.slice(action.index + 1),
        ],
      }
    case 'UPDATE_EDITING_AREA_VERTEXES':
      return { ...state, editingArea: action.vertexes }
    case 'UPDATE_EDTING_MAP_SETTING':
      return { ...state, editingMapSetting: action.setting }
    default:
      return state
  }
}
