import DeleteIcon from '@mui/icons-material/DeleteForever'
import { Stack, Typography, IconButton } from '@mui/material'
import { LatLng, Marker as LeafletMarker } from 'leaflet'
import { useRef, useMemo, useEffect } from 'react'
import { Marker, Popup } from 'react-leaflet'

import { AreaVertex } from '../Types/AreaManagement'

type AreaVertexMarkerProps = {
  index: number
  position: LatLng
  onDrag: (index: number, vertex: AreaVertex) => void
  onDelete: (index: number) => void
}
const AreaVertexMarker = (props: AreaVertexMarkerProps) => {
  const markerRef = useRef<LeafletMarker>(null)
  const eventHandlers = useMemo(
    () => ({
      drag() {
        const marker = markerRef.current
        if (marker != null) {
          props.onDrag(props.index, {
            latitude: marker.getLatLng().lat,
            longitude: marker.getLatLng().lng,
          })
        }
      },
    }),
    []
  )

  useEffect(() => {
    // マークされた最初にポップアップを表示させる
    markerRef?.current?.openPopup()
  }, [])

  return (
    <Marker
      draggable
      eventHandlers={eventHandlers}
      position={props.position}
      ref={markerRef}
    >
      <Popup>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="caption">{`地点:${props.index + 1}`}</Typography>
          <IconButton aria-label="delete" onClick={() => props.onDelete(props.index)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Popup>
    </Marker>
  )
}

export default AreaVertexMarker
