import { MenuItem, TextField } from '@mui/material'
import { UseFormRegisterReturn } from 'react-hook-form'

import { useFacilityList } from '../../Context/useFacilityList'

type SelectFacilityFormProps = {
  value: string
  error: boolean
  helperText: string | undefined
  register: UseFormRegisterReturn
  disabled?: boolean
}
const SelectFacilityForm = (props: SelectFacilityFormProps) => {
  const facilityState = useFacilityList()

  return (
    <TextField
      select
      label="施設"
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      {...props.register}
      disabled={props.disabled}
    >
      {facilityState.facilityList.map((facility) => (
        <MenuItem key={facility.facilityId} value={facility.facilityId}>
          {facility.facilityName}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectFacilityForm
