import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import mainImage from '../../Assets/mainImage.png'
import { AlertContext, ErrorAlert } from '../../Components/Alert/AlertProvider'
import BodyContainer from '../../Components/BodyContainer'
import PageTitle from '../../Components/Common/PageTitle'
import SubmitButton from '../../Components/Common/SubmitButton'
import { AppLogger } from '../../Logger/AppLogger'

import {
  incrementActive,
  putPostQuestionnaire,
} from './Repository/QuestionnaireRepository'
import { QuestionnaireProps, InputQuestionnaire } from './Types/QuestionnaireTypes'

const Questionnaire = (props: QuestionnaireProps) => {
  const initValue = {
    userId: props.userInfo.userId,
    q1: '',
    q2: '',
    q3: '',
    q4_1: '',
    q4_2: '',
    q4_3: '',
    q5_1: '',
    q5_2: '',
    q6_1: '',
    q6_2: '',
    q7: '',
    q8: '',
    q9: '',
    q10: '',
    q11: '',
    q12: '',
    q13_1: '',
    q13_2: '',
    q13_3: '',
    q14_1: '',
    q14_2: '',
    q15_1: '',
    q15_2: '',
    q15_3: '',
    q15_4: '',
    q16: '',
    q17_1: '',
    q17_2: '',
    q18_1: '',
    q18_2: '',
    q19: '',
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
  } = useForm<InputQuestionnaire>({
    defaultValues: initValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const handleCheck = (
    option: string,
    id: 'q14_2' | 'q15_2',

    event: React.SyntheticEvent
  ) => {
    let values: string[] = getValues(id).split(',')
    values = Array.from(values).filter((v) => v)

    let newValues: string[] = []
    if ((event.target as HTMLInputElement).checked) {
      newValues = [...(values ?? []), option]
      newValues.sort()
    } else {
      newValues = values.filter((v) => v !== option)
    }
    setValue(id, newValues.join(','))

    return newValues.join(',')
  }

  const handleCheckNotApplicable = (
    option: string,
    id: 'q5_1' | 'q11',
    event: React.SyntheticEvent,
    notApplicableValue: string
  ) => {
    // 該当idのvalueから文字列を生成
    let values: string[] = getValues(id).split(',')

    // 配列valuesから空文字の削除
    values = Array.from(values).filter((v) => v)

    let newValues: string[] = []

    // 該当なしが押されたかチェック
    if (option === notApplicableValue) {
      if (!values.includes(option)) {
        newValues = [option]
      } else {
        newValues = []
      }
      // 該当なしが押されていない場合
      // 該当なしがvalueに含まれているかチェック
    } else if (!values.includes(notApplicableValue)) {
      // valueにチェックしたoptionが含まれていない場合、newValuesに追加する
      if (!values.includes(option)) {
        newValues = [...(values ?? []), option]
        newValues.sort()
      } else {
        // 含まれていた場合は、newValuesから削除する
        newValues = values.filter((v) => v !== option)
      }
    } else {
      // 該当なしが含まれている場合
      newValues = [option]
    }

    // 該当idのvalueにnewValueをセットする
    setValue(id, newValues.join(','))

    AppLogger.debug(newValues)

    return newValues.join(',')
  }

  const { dispatch } = useContext(AlertContext)

  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<InputQuestionnaire> = (inputs: InputQuestionnaire) => {
    setIsLoading(true)
    AppLogger.debug(inputs)
    putPostQuestionnaire(inputs)
      .then((response) => {
        AppLogger.debug('Completed sending PostQuestionnaire')
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })

    incrementActive(props.userInfo)
      .then((response) => {
        props.onClose()
      })
      .catch((error: Error) => {
        ErrorAlert(dispatch, error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="Questionnaire">
      <BodyContainer>
        <Card
          sx={{
            paddingTop: '3vh',
            paddingBottom: '3vh',
            paddingLeft: '3vw',
            paddingRight: '3vw',
            marginTop: '3vh',
          }}
        >
          <PageTitle title="試乗後アンケート" />
          <Stack spacing={5}>
            <Card
              sx={{
                padding: '1vh',
                paddingLeft: '3vw',
                paddingRight: '3vw',
                fontSize: '1.5em',
              }}
            >
              <FormControl required error={'q1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  1. KUPOご乗車について、総合満足度を教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q1?.message}
                </FormHelperText>
                <Controller
                  name="q1"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        // label="とても良かった"
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            とても良かった
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        // label="良かった"
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>良かった</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        // label="どちらでもない"
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            どちらでもない
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        // label="悪かった"
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>悪かった</Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        // label="とても悪かった"
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            とても悪かった
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q2' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  2. KUPOのモードで気に入ったものを教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q2?.message}
                </FormHelperText>
                <Controller
                  name="q2"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>歩行モード</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>走行モード</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            どちらのモードも気に入った
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            どちらのモードもいまいちだった
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q3' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  3. 日常生活の中で、杖をご利用されていますか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q3?.message}
                </FormHelperText>
                <Controller
                  name="q3"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>はい</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>いいえ</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q4_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  4.
                  日常生活で「歩く」ことについて、何か気になることや困りごとはありますか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q4_1?.message}
                </FormHelperText>
                <Controller
                  name="q4_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q4_1') === '3') {
                        setValue('q4_2', '')
                        setValue('q4_3', '')
                      } else if (watch('q4_1') === '0' || watch('q4_1') === '1') {
                        setValue('q4_2', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            長時間歩くと疲れる
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            歩くと膝が痛む
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            困りごとはない
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q4_1') === '2' ? (
                <FormControl required error={'q4_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「その他」と答えた方へお伺いします。気になることや困りごとを教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q4_2?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q4_2' in errors}
                    {...register('q4_2', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}

              {watch('q4_1') === '0' || watch('q4_1') === '1' || watch('q4_1') === '2' ? (
                <FormControl required error={'q4_3' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記の気になることはや困りごとは、KUPOを利用することで改善されそうですか。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q4_3?.message}
                  </FormHelperText>
                  <Controller
                    name="q4_3"
                    control={control}
                    rules={{
                      required: '必須項目です。',
                    }}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>はい</Typography>
                          }
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>いいえ</Typography>
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q5_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  5.
                  もしKUPOをご購入いただいた場合、あなたの生活の中でKUPOを使って一番やりたいことはなんですか。（複数回答可）
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q5_1?.message}
                </FormHelperText>
                <FormGroup row>
                  <Controller
                    name="q5_1"
                    control={control}
                    rules={{
                      required: '必須項目です。',
                      onChange: () => {
                        if (!watch('q5_1').split(',').includes('5')) {
                          setValue('q5_2', '')
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>散歩</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('0', 'q5_1', event, '6')
                            )
                          }
                          checked={getValues('q5_1').split(',').includes('0')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              日々の買い物
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('1', 'q5_1', event, '6')
                            )
                          }
                          checked={getValues('q5_1').split(',').includes('1')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>習い事</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('2', 'q5_1', event, '6')
                            )
                          }
                          checked={getValues('q5_1').split(',').includes('2')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>旅行</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('3', 'q5_1', event, '6')
                            )
                          }
                          checked={getValues('q5_1').split(',').includes('3')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>通院</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('4', 'q5_1', event, '6')
                            )
                          }
                          checked={getValues('q5_1').split(',').includes('4')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('5', 'q5_1', event, '6')
                            )
                          }
                          checked={getValues('q5_1').split(',').includes('5')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>該当なし</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('6', 'q5_1', event, '6')
                            )
                          }
                          checked={getValues('q5_1').split(',').includes('6')}
                        />
                      </>
                    )}
                  />
                </FormGroup>

                {watch('q5_1').split(',').includes('5') ? (
                  <FormControl required error={'q5_2' in errors} fullWidth>
                    <FormLabel
                      sx={{
                        color: 'black',
                        marginTop: '5vh',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      上記で「その他」と答えた方へお伺いします。KUPOを使って一番やりたいことをお答えください。
                    </FormLabel>
                    <FormHelperText sx={{ fontSize: '1.1rem' }}>
                      {errors.q5_2?.message}
                    </FormHelperText>
                    <TextField
                      sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                      label="理由を入力"
                      InputProps={{ style: { fontSize: '1.2rem' } }}
                      InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                      required
                      defaultValue=""
                      error={'q5_2' in errors}
                      {...register('q5_2', { required: '必須項目です。' })}
                    />
                  </FormControl>
                ) : (
                  ''
                )}
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q6_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  6.
                  KUPOの購入についてお伺いします。KUPOを「買える/買いたい」と思える金額はいくらでしょうか。大体の金額で構いません。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q6_1?.message}
                </FormHelperText>
                <Controller
                  name="q6_1"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>20万以下</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>21〜25万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>26〜30万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>31〜35万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>36〜45万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>46〜50万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>51万以上</Typography>
                        }
                      />
                      <FormControlLabel
                        value="7"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            価格を問わず、買いたいとは思わない
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              <FormControl required error={'q6_2' in errors} fullWidth>
                <FormLabel
                  sx={{
                    color: 'black',
                    marginTop: '5vh',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  KUPOを「高くて買えない/買えない」と思う価格はいくらでしょうか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q6_2?.message}
                </FormHelperText>
                <Controller
                  name="q6_2"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>20万以下</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>21〜25万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>26〜30万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>31〜35万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>36〜45万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>46〜50万</Typography>
                        }
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>51万以上</Typography>
                        }
                      />
                      <FormControlLabel
                        value="7"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            価格を問わず、買いたいとは思わない
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q7' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  7.
                  フラワーパークでKUPOを「レンタルしても良い・レンタルを検討したい」と思う1時間あたりの価格はいくらでしょうか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q7?.message}
                </FormHelperText>
                <Controller
                  name="q7"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>300円以下</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>400円</Typography>}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>500円</Typography>}
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>600円</Typography>}
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>700円以上</Typography>
                        }
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            価格を問わず、レンタルしたいとは思わない
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q8' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  8. 歩数を記録するサービスついて教えてください。
                </FormLabel>

                <div style={{ textAlign: 'center' }}>
                  <img
                    src={mainImage}
                    alt="mainImage"
                    style={{ width: '100%', maxWidth: '50em', marginTop: '0.5vh' }}
                  />
                </div>

                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q8?.message}
                </FormHelperText>
                <Controller
                  name="q8"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            あると嬉しい
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            なくても良い
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q9' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  9.
                  自分の移動した距離・経路を知ることができるサービスについて教えてください。
                </FormLabel>

                <div style={{ textAlign: 'center' }}>
                  <img
                    src={mainImage}
                    alt="mainImage"
                    style={{ width: '100%', maxWidth: '50em', marginTop: '0.5vh' }}
                  />
                </div>

                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q9?.message}
                </FormHelperText>
                <Controller
                  name="q9"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            あると嬉しい
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            なくても良い
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q10' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  10.
                  KUPOの現在の移動状況を自宅や外出先から確認できるサービスについて教えてください。
                </FormLabel>

                <div style={{ textAlign: 'center' }}>
                  <img
                    src={mainImage}
                    alt="mainImage"
                    style={{ width: '100%', maxWidth: '50em', marginTop: '0.5vh' }}
                  />
                </div>

                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q10?.message}
                </FormHelperText>
                <Controller
                  name="q10"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            あると嬉しい
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            なくても良い
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q11' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  11.
                  KUPOのサービスのうち「予約」・「マニュアル」・「試乗手続き」は全て電子提供しています。この中で、利用しづらかったものがあれば教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q11?.message}
                </FormHelperText>
                <FormGroup row>
                  <Controller
                    name="q11"
                    control={control}
                    rules={{ required: '必須項目です。' }}
                    render={({ field, fieldState }) => (
                      <>
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>予約</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('0', 'q11', event, '3')
                            )
                          }
                          checked={getValues('q11').split(',').includes('0')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              マニュアル
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('1', 'q11', event, '3')
                            )
                          }
                          checked={getValues('q11').split(',').includes('1')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              試乗手続き
                            </Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('2', 'q11', event, '3')
                            )
                          }
                          checked={getValues('q11').split(',').includes('2')}
                        />
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>該当なし</Typography>
                          }
                          onChange={(event) =>
                            field.onChange(
                              handleCheckNotApplicable('3', 'q11', event, '3')
                            )
                          }
                          checked={getValues('q11').split(',').includes('3')}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q12' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  12.
                  KUPOやセニアカーなどのモビリティを利用した際の、位置情報や経路を、自分や家族が確認できると便利だと思いますか。リアルタイムでも後からでも確認できるものとします。
                </FormLabel>

                <div style={{ textAlign: 'center' }}>
                  <img
                    src={mainImage}
                    alt="mainImage"
                    style={{ width: '100%', maxWidth: '50em', marginTop: '0.5vh' }}
                  />
                </div>

                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q12?.message}
                </FormHelperText>
                <Controller
                  name="q12"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>はい</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>いいえ</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q13_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  13. KUPOの緊急通報機能は役に立ちましたか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q13_1?.message}
                </FormHelperText>
                <Controller
                  name="q13_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q13_1') !== '1') {
                        setValue('q13_2', '')
                        setValue('q13_3', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>はい</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>いいえ</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            利用しなかった
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q13_1') === '1' ? (
                // 上の設問のvalueが1(いいえ)の時に表示する
                <FormControl required error={'q13_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「いいえ」と答えた方へお伺いします。その理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q13_2?.message}
                  </FormHelperText>
                  <Controller
                    name="q13_2"
                    control={control}
                    rules={{
                      required: '必須項目です。',
                      onChange: () => {
                        if (watch('q13_2') !== '3') {
                          setValue('q13_3', '')
                        }
                      },
                    }}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              操作が難しかった
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              呼んでもすぐに係員が来なかった
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              間違って押してしまった
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              ) : (
                ''
              )}

              {watch('q13_2') === '3' ? (
                <FormControl required error={'q13_3' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「その他」と答えた方へお伺いします。理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q13_3?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q13_3' in errors}
                    {...register('q13_3', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q14_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  14.
                  園内を周遊するにあたり、スマホのアナウンスはどのような機能が役に立ちましたか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q14_1?.message}
                </FormHelperText>
                <Controller
                  name="q14_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q14_1') !== '5') {
                        setValue('q14_2', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>安全確認</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>ルート指示</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            役には立たなかった
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            聞いていなかった
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            聞こえなかった
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q14_1') === '5' ? (
                <FormControl required error={'q14_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「その他」と答えた方へお伺いします。どのような機能が役立ったか教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q14_2?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q14_2' in errors}
                    {...register('q14_2', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q15_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  15. 受付以外でKUPOを返却できるサービスについて教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q15_1?.message}
                </FormHelperText>
                <Controller
                  name="q15_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q15_1') === '1') {
                        setValue('q15_2', '')
                        setValue('q15_3', '')
                      } else if (watch('q15_1') === '2') {
                        setValue('q15_2', '')
                        setValue('q15_3', '')
                        setValue('q15_4', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>良かった</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>悪かった</Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            使わなかった
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q15_1') === '1' ? (
                <FormControl required error={'q15_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    途中返却システムの使用感について「悪かった」とご回答いただいた方へお伺いします。その理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q15_2?.message}
                  </FormHelperText>
                  <FormGroup row>
                    <Controller
                      name="q15_2"
                      control={control}
                      rules={{ required: '必須項目です。' }}
                      render={({ field, fieldState }) => (
                        <>
                          <FormControlLabel
                            {...field}
                            control={<Checkbox />}
                            label={
                              <Typography sx={{ fontSize: '1.2rem' }}>
                                返却場所が分からない
                              </Typography>
                            }
                            onChange={(event) =>
                              field.onChange(handleCheck('0', 'q15_2', event))
                            }
                          />
                          <FormControlLabel
                            {...field}
                            control={<Checkbox />}
                            label={
                              <Typography sx={{ fontSize: '1.2rem' }}>
                                返却場所までが遠い
                              </Typography>
                            }
                            onChange={(event) =>
                              field.onChange(handleCheck('1', 'q15_2', event))
                            }
                          />
                          <FormControlLabel
                            {...field}
                            control={<Checkbox />}
                            label={
                              <Typography sx={{ fontSize: '1.2rem' }}>
                                返却ボタンが使いづらい
                              </Typography>
                            }
                            onChange={(event) =>
                              field.onChange(handleCheck('2', 'q15_2', event))
                            }
                          />
                          <FormControlLabel
                            {...field}
                            control={<Checkbox />}
                            label={
                              <Typography sx={{ fontSize: '1.2rem' }}>その他</Typography>
                            }
                            onChange={(event) =>
                              field.onChange(handleCheck('3', 'q15_2', event))
                            }
                          />
                        </>
                      )}
                    />
                  </FormGroup>
                </FormControl>
              ) : (
                ''
              )}

              {watch('q15_2').split(',').includes('3') ? (
                <FormControl required error={'q15_3' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「その他」と答えた方へお伺いします。理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q15_3?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q15_3' in errors}
                    {...register('q15_3', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}

              {watch('q15_1') === '0' || watch('q15_1') === '1' ? (
                <FormControl required error={'q15_4' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    途中返却された方にお伺いします。返却理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q15_4?.message}
                  </FormHelperText>
                  <Controller
                    name="q15_4"
                    control={control}
                    rules={{ required: '必須項目です。' }}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>
                              KUPOに不満があった
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <Typography sx={{ fontSize: '1.2rem' }}>それ以外</Typography>
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q16' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  16. タブレットでの地図表示サービスについて教えてください。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q16?.message}
                </FormHelperText>
                <Controller
                  name="q16"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            あると嬉しい
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            なくても良い
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q17_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  17.
                  歩行モードのKUPOの大きさについて教えてください。今回利用してみて、あるいはあなたの日常生活から見て、どう感じますか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q17_1?.message}
                </FormHelperText>
                <Controller
                  name="q17_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q17_1') === '1') {
                        setValue('q17_2', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>大きい</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            ちょうどよい
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>小さい</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q17_1') === '0' || watch('q17_1') === '2' ? (
                // 上の設問のvalueが1(大きい) or 2(小さい)の時に表示する
                <FormControl required error={'q17_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「大きい」または「小さい」とご回答いただいた方へお伺いします。その理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q17_2?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q17_2' in errors}
                    {...register('q17_2', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q18_1' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  18.
                  走行モードのKUPOの大きさについて教えてください。今回利用してみて、あるいはあなたの日常生活から見て、どう感じますか。
                </FormLabel>
                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q18_1?.message}
                </FormHelperText>
                <Controller
                  name="q18_1"
                  control={control}
                  rules={{
                    required: '必須項目です。',
                    onChange: () => {
                      if (watch('q18_1') === '1') {
                        setValue('q18_2', '')
                      }
                    },
                  }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>大きい</Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>
                            ちょうどよい
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>小さい</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>

              {watch('q18_1') === '0' || watch('q18_1') === '2' ? (
                // 上の設問のvalueが1(大きい) or 2(小さい)の時に表示する
                <FormControl required error={'q18_2' in errors} fullWidth>
                  <FormLabel
                    sx={{
                      color: 'black',
                      marginTop: '5vh',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    上記で「大きい」または「小さい」とご回答いただいた方へお伺いします。その理由を教えてください。
                  </FormLabel>
                  <FormHelperText sx={{ fontSize: '1.1rem' }}>
                    {errors.q18_2?.message}
                  </FormHelperText>
                  <TextField
                    sx={{ marginTop: '0.5vh', maxWidth: '100%', width: '100%' }}
                    label="理由を入力"
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    required
                    defaultValue=""
                    error={'q18_2' in errors}
                    {...register('q18_2', { required: '必須項目です。' })}
                  />
                </FormControl>
              ) : (
                ''
              )}
            </Card>

            <Card sx={{ padding: '1vh', paddingLeft: '3vw', paddingRight: '3vw' }}>
              <FormControl required error={'q19' in errors} fullWidth>
                <FormLabel
                  sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 'bold' }}
                >
                  19.
                  あなたの家の周り（行動範囲内）に、フラワーパークの坂よりも急な坂はありますか。
                </FormLabel>

                <div style={{ textAlign: 'center' }}>
                  <img
                    src={mainImage}
                    alt="mainImage"
                    style={{ width: '100%', maxWidth: '50em', marginTop: '0.5vh' }}
                  />
                </div>

                <FormHelperText sx={{ fontSize: '1.1rem' }}>
                  {errors.q19?.message}
                </FormHelperText>
                <Controller
                  name="q19"
                  control={control}
                  rules={{ required: '必須項目です。' }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>ある</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={<Typography sx={{ fontSize: '1.2rem' }}>ない</Typography>}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: '1.2rem' }}>わからない</Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Card>

            <div style={{ textAlign: 'center' }}>
              <SubmitButton
                text="回答する"
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
              />
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button
                variant="text"
                sx={{ borderRadius: 10, width: '20em' }}
                onClick={props.onClose}
              >
                キャンセル
              </Button>
            </div>
          </Stack>
        </Card>
      </BodyContainer>
    </div>
  )
}

export default Questionnaire
