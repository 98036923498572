import { API } from 'aws-amplify'

import { ActiveUserList } from '../../../Components/Header/AppHeaderRepository'
import { AppLogger } from '../../../Logger/AppLogger'
import { MobilityInfo } from '../../MobilityManagement/Types/MobilityManagementTypes'
import { UserInfo } from '../../UserManagement/Types/UserManagementTypes'
import { AreaInfo, EmergencyAreaData } from '../Types/MobilityMonitoringTypes'

export const getEmergencyArea = async (
  facilityId: string
): Promise<EmergencyAreaData[]> => {
  AppLogger.debug('call Api: getEmergencyArea')

  const apiName = 'Staff'
  const path = '/getEmergencyAreaData'

  const myInit = {
    body: {
      facilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: EmergencyAreaData[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

// TODO: AreaRepo側を使うようにした方が良い。
export const getAreaInfoByFacilityId = async (
  facilityId: string
): Promise<AreaInfo[]> => {
  AppLogger.debug('call Api: getEmergencyArea')

  const apiName = 'Staff'
  const path = '/getAreaInfoByFacilityId'

  const myInit = {
    body: {
      facilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: AreaInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getMobilityInfoList = async (
  facilityId: string
): Promise<MobilityInfo[]> => {
  AppLogger.debug('call Api: getMobilityInfoList')
  const apiName = 'Staff'
  const path = '/getMobilityInfoList'

  const myInit = {
    body: {
      facilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: MobilityInfo[]) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getUserDataList = async (facilityId: string): Promise<UserInfo[]> => {
  AppLogger.debug('call Api: getUserDataList')
  const apiName = 'Staff'
  const path = '/getActiveUserList'

  const myInit = {
    body: {
      facilityIdList: [facilityId],
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: ActiveUserList[]) => {
        resolve(
          response.find((activeUserList) => activeUserList.facilityId === facilityId)
            ?.activeUserList || []
        )
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const checkFallDown = async (mobilityList: MobilityInfo[]): Promise<string> => {
  AppLogger.debug('call Api: checkFallDown')
  const apiName = 'Staff'
  const path = '/checkFallDown'

  const myInit = {
    body: {
      mobilityList,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('success')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
