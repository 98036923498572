import PageTitle from '../../Components/Common/PageTitle'

const AppAnalytics = () => {
  return (
    <div className="Analytics">
      <PageTitle title="分析画面" />
    </div>
  )
}

export default AppAnalytics
