import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { LatLngExpression } from 'leaflet'

import PageTitle from '../../Components/Common/PageTitle'
import { AppLogger } from '../../Logger/AppLogger'

import AreaJudgeResult from './Components/AreaJudgeResult'
import Map from './Components/Map'
import SearchMobilityMonitoring from './Components/SearchMobilityMonitoring'
import MobilityMonitoringProvider from './Provider/MobilityMonitoringProvider'

const MobilityMonitoring = () => {
  AppLogger.debug('render: MobilityMonitoring page.')

  const initialposition: LatLngExpression = [34.762759757497946, 137.63329094144157]

  const theme = useTheme()
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'))

  const mapHeight = isMobileSize ? '50vh' : '81vh'

  return (
    <div className="MobilityMonitoring">
      <MobilityMonitoringProvider>
        <PageTitle title="見守り画面" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={7}>
            <Map position={initialposition} height={mapHeight} />
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <SearchMobilityMonitoring />
            <AreaJudgeResult />
          </Grid>
        </Grid>
      </MobilityMonitoringProvider>
    </div>
  )
}

export default MobilityMonitoring
