import { API } from 'aws-amplify'

import { UserGroup } from '../../../Context/useUserGroup'
import { AppLogger } from '../../../Logger/AppLogger'
import { getAuthorizationToken } from '../../../Repository/AuthRepository'
import {
  SearchStaffInputs,
  StaffInfo,
  StaffRegistrationInputs,
  StaffFacilityRelation,
  StaffUpdateInputs,
} from '../Types/StaffManagementTypes'

export const searchStaff = async (
  searchInputs: SearchStaffInputs
): Promise<StaffInfo[]> => {
  AppLogger.debug('call Api: searchStaff')

  const apiName = 'Admin'
  const path = '/searchStaffList'
  const myInit = { body: searchInputs }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: StaffInfo[]) => {
        // 権限単位でフィルタをかける
        searchStaffByUserGroup(searchInputs.userGroup)
          .then((userNameList) => {
            const filterdResponse = response.filter((userInfo) => {
              for (const userName of userNameList) {
                if (userInfo.staffId === userName.Username) return true
              }
              return false
            })

            resolve(filterdResponse)
          })
          .catch((error: Error) => {
            reject(error)
          })
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const searchStaffByUserGroup = async (
  groupname: UserGroup
): Promise<{ Username: string }[]> => {
  const apiName = 'AdminQueries'
  const path = '/listUsersInGroup'
  const myInit = {
    queryStringParameters: {
      groupname,
    },
    headers: {
      Authorization: await getAuthorizationToken(),
    },
  }

  return new Promise((resolve, reject) => {
    API.get(apiName, path, myInit)
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const userList = response.Users as { Username: string }[]
        resolve(userList)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const addStaff = async (
  inputs: StaffRegistrationInputs,
  facilityList: StaffFacilityRelation[]
): Promise<string> => {
  AppLogger.debug('call Api: addStaff')

  const staffFacilityRelationList = []

  for (const facilityId of inputs.facilityIdList) {
    const staffFacilityRelationData = {
      PutRequest: {
        Item: {
          staffId: '',
          facilityId: '',
          facilityName: '',
          available: true,
        },
      },
    }

    staffFacilityRelationData.PutRequest.Item.facilityId = facilityId
    const facilityName = facilityList.find(
      (value) => value.facilityId === facilityId
    )?.facilityName

    if (typeof facilityName === 'string') {
      staffFacilityRelationData.PutRequest.Item.facilityName = facilityName
    }

    staffFacilityRelationList.push(staffFacilityRelationData)
  }

  const apiName = 'AdminQueries'
  const path = '/createUser'
  const myInit = {
    body: {
      username: inputs.email,
      userAttributes: [
        { Name: 'email', Value: inputs.email },
        { Name: 'email_verified', Value: 'true' },
      ],
      userInfo: {
        staffId: '',
        staffName: inputs.staffName,
        email: inputs.email,
        available: true,
      },
      staffFacilityRelation: staffFacilityRelationList,
      groupList: inputs.groupList,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAuthorizationToken(),
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('SUCCESS')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const disableStaff = async (staffInfo: StaffInfo): Promise<string> => {
  AppLogger.debug('call Api: disableStaff')

  const apiName = 'AdminQueries'
  const path = '/disableStaff'
  const myInit = {
    body: {
      disableStaffInfo: {
        staffId: staffInfo.staffId,
        staffName: staffInfo.staffName,
        email: staffInfo.email,
        available: false,
      },
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAuthorizationToken(),
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('SUCCESS')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const updateStaff = (
  inputs: StaffUpdateInputs,
  facilityList: StaffFacilityRelation[]
): Promise<string> => {
  AppLogger.debug('call Api: updateStaff')

  const requestFacilityList = facilityList.filter((data) => data.available === true)

  // eslint-disable-next-line prefer-const
  let staffFacilityRelationDataList = []

  // eslint-disable-next-line no-restricted-syntax
  for (const requestFacility of requestFacilityList) {
    // eslint-disable-next-line prefer-const
    let staffFacilityRelationData = {
      PutRequest: {
        Item: {
          staffId: inputs.staffId,
          facilityId: '',
          facilityName: '',
          available: true,
        },
      },
    }

    // 施設IDが更新対象の施設リストに含まれているかチェック
    // 含まれている場合はavailable=true
    if (inputs.facilityIdList.includes(requestFacility.facilityId)) {
      staffFacilityRelationData.PutRequest.Item.available = true
    } else {
      staffFacilityRelationData.PutRequest.Item.available = false
    }

    // 施設情報を更新用データに代入
    staffFacilityRelationData.PutRequest.Item.facilityId = requestFacility.facilityId
    // eslint-disable-next-line prefer-destructuring
    const facilityName = requestFacility.facilityName

    if (typeof facilityName === 'string') {
      staffFacilityRelationData.PutRequest.Item.facilityName = facilityName
    }

    staffFacilityRelationDataList.push(staffFacilityRelationData)
  }

  const apiName = 'Admin'
  const path = '/updateStaff'
  const myInit = {
    body: {
      staffInfo: {
        staffId: inputs.staffId,
        staffName: inputs.staffName,
        email: inputs.email,
        available: true,
      },
      staffFacilityRelationList: staffFacilityRelationDataList,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('SUCCESS')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const getFacilityList = (): Promise<StaffFacilityRelation[]> => {
  AppLogger.debug('call Api: getFacilityList')

  const apiName = 'Staff'
  const path = '/getFacilityList'

  return new Promise((resolve, reject) => {
    API.get(apiName, path, {})
      .then((response: StaffFacilityRelation[]) => {
        AppLogger.debug('resolve Api: getFacilityList')
        resolve(response)
      })
      .catch((error: Error) => {
        AppLogger.debug('reject Api: getFacilityList')
        reject(error)
      })
  })
}

export const getStaffInfo = (): Promise<StaffInfo> => {
  AppLogger.debug('call Api: getStaffInfo')

  const apiName = 'Staff'
  const path = '/getStaffInfo'

  return new Promise((resolve, reject) => {
    API.get(apiName, path, {})
      .then((response: StaffInfo) => {
        AppLogger.debug('resolve Api: getStaffInfo')
        resolve(response)
      })
      .catch((error: Error) => {
        AppLogger.debug('reject Api: getStaffInfo')
        reject(error)
      })
  })
}

export const updateStaffInfo = (staffInfo: StaffInfo): Promise<StaffInfo> => {
  AppLogger.debug('call Api: updateStaffInfo')

  const apiName = 'Staff'
  const path = '/updateStaffInfo'
  const myInit = {
    body: {
      staffInfo,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: StaffInfo) => {
        AppLogger.debug('resolve Api: updateStaffInfo')
        resolve(response)
      })
      .catch((error: Error) => {
        AppLogger.debug('reject Api: updateStaffInfo')
        reject(error)
      })
  })
}
