import { LatLngTuple } from 'leaflet'
import { useContext } from 'react'

import { useAppAlert } from '../../../Components/Alert/AlertProvider'
import { AreaInfo } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'
import { AreaManagementContext } from '../Context/AreaManagementContext'
import {
  putAreaInfo as putAreaViaRepo,
  disableAreaInfo as disableAreaInfoViaRepo,
  getAreaInfoByFacilityId,
} from '../Repository/AreaRepository'
import { AreaVertex, MapViewSetting } from '../Types/AreaManagement'

const useAreaManagement = () => {
  const { areaManagementState, areaManagementDispatch } =
    useContext(AreaManagementContext)

  const [openSuccessAlert, openErrorAlert, closeAlert] = useAppAlert()

  const fetchArea = (facilityId: string) => {
    selectArea(null)

    void (async () => {
      areaManagementDispatch({ type: 'FETCH_AREA_PROCESSING' })
      const fetchedList = await getAreaInfoByFacilityId(facilityId)

      areaManagementDispatch({ type: 'FETCH_AREA_FINISHED', result: fetchedList })
    })()
  }

  const selectArea = (area: AreaInfo | null) => {
    areaManagementDispatch({ type: 'SELECT_AREA', area })
  }

  const selectFacility = (facilityId: string) => {
    areaManagementDispatch({ type: 'SELECT_FACILITY', facilityId })
  }

  const openAddDialog = (mapSetting: MapViewSetting) => {
    areaManagementDispatch({ type: 'OPEN_ADD_DIALOG' })
    areaManagementDispatch({ type: 'SELECT_AREA', area: null })
    areaManagementDispatch({ type: 'UPDATE_EDTING_MAP_SETTING', setting: mapSetting })
    areaManagementDispatch({ type: 'UPDATE_EDITING_AREA_VERTEXES', vertexes: [] })
  }

  const closeAddDialog = () => {
    areaManagementDispatch({ type: 'CLOSE_ADD_DIALOG' })
  }

  const openEditDialog = (targetArea: AreaInfo, mapSetting: MapViewSetting) => {
    const targetVertexes: AreaVertex[] = targetArea.coordinateList.map((x) => {
      const [lat, lng] = x as LatLngTuple
      return { latitude: lat, longitude: lng }
    })

    areaManagementDispatch({ type: 'OPEN_EDIT_DIALOG' })
    areaManagementDispatch({ type: 'SELECT_AREA', area: targetArea })
    areaManagementDispatch({ type: 'UPDATE_EDTING_MAP_SETTING', setting: mapSetting })
    areaManagementDispatch({
      type: 'UPDATE_EDITING_AREA_VERTEXES',
      vertexes: targetVertexes,
    })
  }

  const closeEditDialog = () => {
    areaManagementDispatch({ type: 'CLOSE_EDIT_DIALOG' })
  }

  const openDisableDialog = (targetArea: AreaInfo) => {
    areaManagementDispatch({ type: 'OPEN_DISABLE_DIALOG' })
    areaManagementDispatch({ type: 'SELECT_AREA', area: targetArea })
  }

  const closeDisableDialog = () => {
    areaManagementDispatch({ type: 'CLOSE_DISABLE_DIALOG' })
  }

  const addArea = (area: AreaInfo) => {
    putArea(area, 'エリアを登録しました')
  }

  const updateArea = (area: AreaInfo) => {
    putArea(area, 'エリアを更新しました')
  }

  const putArea = (area: AreaInfo, successMessage: string) => {
    closeAlert()
    areaManagementDispatch({ type: 'COMMAND_PROCESSING' })

    putAreaViaRepo(area)
      .then((putData) => {
        fetchArea(putData.facilityId)
        selectArea(putData)
        closeAddDialog()
        closeEditDialog()
        areaManagementDispatch({ type: 'COMMAND_FINISHED' })
        openSuccessAlert(successMessage)
      })
      .catch((error) => {
        openErrorAlert(error as Error)
        closeAddDialog()
        closeEditDialog()
        areaManagementDispatch({ type: 'COMMAND_FINISHED' })
      })
  }

  const disableArea = (area: AreaInfo) => {
    areaManagementDispatch({ type: 'COMMAND_PROCESSING' })

    disableAreaInfoViaRepo(area)
      .then((response) => {
        fetchArea(area.facilityId)
        closeDisableDialog()
        areaManagementDispatch({ type: 'COMMAND_FINISHED' })
        openSuccessAlert('エリアを削除しました')
      })
      .catch((error) => {
        openErrorAlert(error as Error)
        closeDisableDialog()
        areaManagementDispatch({ type: 'COMMAND_FINISHED' })
      })
  }

  const addVertex = (vertex: AreaVertex) => {
    areaManagementDispatch({ type: 'ADD_EDITING_AREA_VERTEX', vertex })
  }

  const updateVertex = (index: number, vertex: AreaVertex) => {
    areaManagementDispatch({ type: 'UPDATE_EDITING_AREA_VERTEX', index, vertex })
  }

  const deleteVertex = (index: number) => {
    areaManagementDispatch({ type: 'DELETE_EDITING_AREA_VERTEX', index })
  }

  return {
    areaManagementState,
    fetchArea,
    selectArea,
    selectFacility,
    openAddDialog,
    closeAddDialog,
    openEditDialog,
    closeEditDialog,
    openDisableDialog,
    closeDisableDialog,
    addArea,
    updateArea,
    disableArea,
    addVertex,
    updateVertex,
    deleteVertex,
  } as const
}

export default useAreaManagement
