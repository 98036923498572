import { API } from 'aws-amplify'

import { AppLogger } from '../../../Logger/AppLogger'
import { AreaInfo } from '../../MobilityMonitoring/Types/MobilityMonitoringTypes'

export const getAreaInfoByFacilityId = async (
  facilityId: string
): Promise<AreaInfo[]> => {
  AppLogger.debug('call Api: getAreaInfoByFacilityId')

  const apiName = 'Staff'
  const path = '/getAreaInfoByFacilityId'

  const myInit = {
    body: {
      facilityId,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: (AreaInfo & { updatedAt: string })[]) => {
        const listWithVersion = response.map((x) => {
          return { ...x, previousUpdatedAtForDbVersion: x.updatedAt }
        })

        resolve(listWithVersion)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const putAreaInfo = async (areaInfo: AreaInfo): Promise<AreaInfo> => {
  AppLogger.debug('call Api: putAreaInfo')

  const apiName = 'Staff'
  const path = '/putAreaInfo'
  const myInit = {
    body: {
      areaInfo,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response: AreaInfo) => {
        resolve(response)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}

export const disableAreaInfo = async (areaInfo: AreaInfo): Promise<string> => {
  AppLogger.debug('call Api: disableAreaInfo')

  const apiName = 'Staff'
  const path = '/disableAreaInfo'
  const myInit = {
    body: {
      areaInfo,
    },
  }

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        resolve('SUCCESS')
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
