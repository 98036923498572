import { NavigateFunction } from 'react-router-dom'

import { AppLogger } from '../Logger/AppLogger'

export const showNotification = (
  permission: NotificationPermission,
  title: string,
  message: string,
  navigate: NavigateFunction,
  url: string
) => {
  if (permission !== 'granted') return

  AppLogger.debug('send notification.')
  const notification = new Notification(title, {
    body: message,
  })

  notification.onclick = () => {
    window.focus()
    navigate(url)
  }
  setTimeout(() => {
    notification.close()
  }, 5000)
}
