import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { KeyboardEventHandler, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { recordEvent, EventType } from '../../AppTrack'
import {
  convertTimestampForDisplay,
  getDateString,
  getFormatTime,
  getTimestampString,
  timestampToDate,
} from '../../Common/TimestampConverter'
import { useAppAlert } from '../../Components/Alert/AlertProvider'
import AppAlert from '../../Components/Alert/AppAlert'
import ConfirmDialog from '../../Components/Common/ConfirmDialog'
import PageTitle from '../../Components/Common/PageTitle'
import { validationRules } from '../../Components/Form/ValidationRules'
import { AppLogger } from '../../Logger/AppLogger'
import { getActiveName } from '../UserManagement/Components/activeStatusList'

import {
  getReservationInfo,
  cancelReservation,
  checkReservation,
  updateReservation,
} from './ReservationRepository'
import {
  ReservableMobility,
  ReserveConfirmationInfo,
  ReserveResult,
  UpdateReservationInputs,
} from './ReservationTypes'

const ReserveConfirmation = () => {
  const urlParams = useParams()
  const userId = urlParams.reservationNumber || ''
  const facilityId = urlParams.facilityId || ''
  const mobilityId = urlParams.mobilityId || ''
  const [reserveConfirmation, setReserveConfirmation] =
    useState<ReserveConfirmationInfo | null>(null)

  const [openSuccessAlert, openErrorAlert, closeAlert] = useAppAlert()

  const [activeStep, setActiveStep] = useState(0)
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const dateStep = 0
  const mobilityStep = 1

  const minReserveDay = new Date()
  minReserveDay.setDate(minReserveDay.getDate() + 1)

  const [mobilityList, setMobilityList] = useState<ReservableMobility[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const checkUpdatable = (startTime: string): boolean => {
    const date = timestampToDate(startTime)
    const today = new Date()

    if (date) {
      return date.getDate() <= today.getDate()
    }

    return false
  }

  const handleNext = async () => {
    const errorList = []

    if (activeStep === dateStep) {
      errorList.push(await trigger('reserveDay'))
      errorList.push(await trigger('startTime'))
      errorList.push(await trigger('endTime'))

      if (!errorList.includes(false)) {
        setMobilityList([])
        setValue('reserveMobility', 'none')
        setLoading(true)

        if (reserveConfirmation) {
          checkReservation(
            getDateString(watch('reserveDay') || new Date()),
            getTimestampString(
              watch('reserveDay') || new Date(),
              watch('startTime') || new Date()
            ),
            getTimestampString(
              watch('reserveDay') || new Date(),
              watch('endTime') || new Date()
            ),
            // watch('facilityId')
            reserveConfirmation?.facilityId
          )
            .then((response) => {
              setMobilityList(response)
            })
            .catch((error: Error) => {
              openErrorAlert(error)
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }
    }

    if (activeStep === mobilityStep) {
      errorList.push(await trigger('reserveMobility'))
    }

    if (!errorList.includes(false)) setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm<UpdateReservationInputs>({
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      // facilityId: 'FlowerPark',
      reservationNumber: '',
      reserveMobility: 'none',
      reserveDay: minReserveDay,
      startTime: new Date(0, 0, 0, 9, 0),
      endTime: new Date(0, 0, 0, 11, 0),
    },
  })

  useEffect(() => {
    getReservationInfo(userId, facilityId, mobilityId)
      .then((response) => {
        setReserveConfirmation(response)
      })
      .catch((error: Error) => {
        openErrorAlert(error)
      })
  }, [])

  const navigate = useNavigate()
  const toPortal = () => {
    navigate('/')
  }

  const [isLoading, setIsLoading] = useState(false)

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const toggleDialog = () => {
    setDialogIsOpen(!dialogIsOpen)
  }

  const [reservationChangeDialogIsOpen, setReservationChangeDialogIsOpen] =
    useState(false)
  const toggleReservationChangeDialog = () => {
    setReservationChangeDialogIsOpen(!reservationChangeDialogIsOpen)
  }

  const onCancel = () => {
    setIsLoading(true)
    cancelReservation(userId, facilityId, mobilityId)
      .then((response) => {
        openSuccessAlert(
          `予約(${response.reservationNumber})をキャンセルしました。この画面はこのまま閉じてください。`
        )
        setIsLoading(false)
        toggleDialog()
      })
      .catch((error: Error) => {
        openErrorAlert(error)
        setIsLoading(false)
        toggleDialog()
      })
  }

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
  }

  const steps = [
    {
      number: 0,
      label: '日程の選択',
      optional: `${watch('reserveDay')?.toLocaleDateString() || ''} ${
        getFormatTime(watch('startTime')) || ''
      } ~ ${getFormatTime(watch('endTime')) || ''}`,
      content: (
        <Stack direction="column" sx={{ width: '40vw' }} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="利用日"
              value={watch('reserveDay')}
              inputFormat="yyyy/MM/dd"
              mask="____/__/__"
              {...register('reserveDay', validationRules.reserveDay)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'reserveDay' in errors}
                  helperText={errors.reserveDay?.message}
                  onKeyDown={onKeyDown}
                />
              )}
              onChange={(newValue) => {
                setValue('reserveDay', newValue)
              }}
              disablePast
              minDate={minReserveDay}
              maxDate={new Date(2023, 3 - 1, 31)}
              // shouldDisableDate={(day: Date): boolean => {
              //   return day.getDate() === new Date(2022, 8 - 1, 31).getDate()
              // }}
            />

            <TimePicker
              label="開始時間"
              value={watch('startTime')}
              {...register('startTime', {
                ...validationRules.startTime,
                validate: (value: Date | null) => {
                  if (Number.isNaN(value?.getHours())) {
                    return '入力形式が間違っています。'
                  }

                  const startHours = value?.getHours() || 0
                  const endHours = watch('endTime')?.getHours() || 0
                  const startMinutes = value?.getMinutes() || 0
                  const endMinutes = watch('endTime')?.getMinutes() || 0

                  const result =
                    startHours < endHours ||
                    (startHours === endHours && startMinutes < endMinutes)

                  if (!result)
                    return '開始時間は終了時間より30分以上早く設定してください。'

                  const selectYear = watch('reserveDay')?.getFullYear() || 0
                  const selectMonth = watch('reserveDay')?.getMonth() || 0
                  const selectDay = watch('reserveDay')?.getDate() || 0
                  const reserveDate = new Date(
                    selectYear,
                    selectMonth,
                    selectDay,
                    startHours,
                    startMinutes
                  )
                  const now = new Date()

                  AppLogger.debug(reserveDate)
                  AppLogger.debug(reserveDate.getTime())

                  if (reserveDate.getTime() < now.getTime())
                    return '過去の日時は指定できません。'

                  return true
                },
              })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'startTime' in errors}
                  helperText={errors.startTime?.message}
                  onKeyDown={onKeyDown}
                />
              )}
              onChange={(newValue) => {
                setValue('startTime', newValue)
              }}
              minTime={new Date(0, 0, 0, 9)}
              maxTime={new Date(0, 0, 0, 16, 30)}
              minutesStep={30}
              ampm={false}
            />

            <TimePicker
              disableMaskedInput
              label="終了時間"
              value={watch('endTime')}
              {...register('endTime', validationRules.endTime)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={'endTime' in errors}
                  helperText={errors.endTime?.message}
                  onKeyDown={onKeyDown}
                />
              )}
              onChange={(newValue) => {
                setValue('endTime', newValue)
              }}
              minTime={new Date(0, 0, 0, 9, 30)}
              maxTime={new Date(0, 0, 0, 17)}
              minutesStep={30}
              ampm={false}
            />
          </LocalizationProvider>
          <Typography variant="subtitle2">※30分単位で指定してください。</Typography>
        </Stack>
      ),
    },
    {
      number: 1,
      label: '車両の選択',
      optional: mobilityList.find((mobility) => {
        return mobility.mobilityId === watch('reserveMobility')
      })?.mobilityName,
      content:
        // eslint-disable-next-line no-nested-ternary
        loading ? (
          <div />
        ) : mobilityList.length !== 0 ? (
          <TextField
            select
            label="車両"
            sx={{ width: '30vw' }}
            value={watch('reserveMobility')}
            error={'reserveMobility' in errors}
            helperText={errors.reserveMobility?.message}
            {...register('reserveMobility', validationRules.reserveMobility)}
          >
            {mobilityList.map((mobility) => (
              <MenuItem key={mobility.mobilityId} value={mobility.mobilityId}>
                {mobility.mobilityName}
              </MenuItem>
            ))}
            <MenuItem
              key="none"
              value="none"
              sx={{ display: mobilityList.length !== 0 ? 'none' : 'initial' }}
            >
              車両を選択してください
            </MenuItem>
          </TextField>
        ) : (
          <Typography sx={{ color: 'red' }}>
            予約可能な車両がありません。日程の選択からやり直してください。
          </Typography>
        ),
    },
    {
      number: 2,
      label: '最終確認',
      optional: '',
      content: (
        <>
          <Typography>内容をご確認のうえ、予約完了ボタンを押してください。</Typography>
          <Typography>
            <br />
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>予約車両</span>：{' '}
            {
              mobilityList.find((mobility) => {
                return mobility.mobilityId === watch('reserveMobility')
              })?.mobilityName
            }
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>予約日</span>：{' '}
            {watch('reserveDay')?.toLocaleDateString('ja')}
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>開始時間</span>：{' '}
            {getFormatTime(watch('startTime'))}
          </Typography>
          <Typography>
            <span style={{ display: 'inline-block', width: '1em' }} />
            <span style={{ display: 'inline-block', width: '5em' }}>終了時間</span>：{' '}
            {getFormatTime(watch('endTime'))}
          </Typography>
          <br />
        </>
      ),
    },
  ]

  const onSubmit: SubmitHandler<UpdateReservationInputs> = (
    inputs: UpdateReservationInputs
  ) => {
    closeAlert()
    setLoading(true)
    recordEvent(EventType.click_reservation)
    if (reserveConfirmation) {
      const addReservationNumberInputs = inputs
      addReservationNumberInputs.reservationNumber =
        reserveConfirmation?.reservationNumber
      AppLogger.debug(addReservationNumberInputs)
      updateReservation(addReservationNumberInputs)
        .then((response: ReserveResult) => {
          if (response.reservationNumber === '') {
            openErrorAlert(Error('予約期間が重複しています。'))
          } else {
            navigate(`/ReservationUpdateComplete/${response.reservationNumber}`)
          }
        })
        .catch((error: Error) => {
          openErrorAlert(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <div className="ReserveConfirmation">
      <PageTitle title="予約確認" />
      <AppAlert />

      <Stack
        spacing={6}
        sx={{
          alignItems: 'center',
        }}
      >
        <Card sx={{ paddingY: 3, paddingX: 2 }}>
          {reserveConfirmation ? (
            <>
              <AlignedText
                title="予約番号"
                text={reserveConfirmation.reservationNumber.split('-')[1] || ''}
              />
              <AlignedText title="施設" text={reserveConfirmation.facilityName} />
              <AlignedText
                title="開始時間"
                text={convertTimestampForDisplay(
                  reserveConfirmation.scheduledStartTime,
                  false
                )}
              />
              <AlignedText
                title="終了時間"
                text={convertTimestampForDisplay(
                  reserveConfirmation.scheduledEndTime,
                  false
                )}
              />
              <AlignedText
                title="ステータス"
                text={getActiveName(reserveConfirmation.active)}
              />
            </>
          ) : (
            <Typography>予約なし</Typography>
          )}
        </Card>
        <Button
          variant="contained"
          sx={{ borderRadius: 10, width: '18em' }}
          onClick={toPortal}
        >
          <Typography variant="h6">ホームへ戻る</Typography>
        </Button>

        {reserveConfirmation ? (
          <>
            <Button
              sx={{ borderRadius: 10, width: '18em' }}
              onClick={toggleReservationChangeDialog}
              disabled={checkUpdatable(reserveConfirmation.scheduledEndTime)}
            >
              <Typography variant="h6">予約時間の変更</Typography>
            </Button>

            <Button sx={{ borderRadius: 10, width: '18em' }} onClick={toggleDialog}>
              <Typography variant="h6">予約をキャンセル</Typography>
            </Button>
          </>
        ) : (
          ''
        )}
      </Stack>

      <ConfirmDialog
        isOpen={dialogIsOpen}
        onClose={toggleDialog}
        onSubmit={onCancel}
        title="キャンセル確認"
        text="予約をキャンセルします。この操作は取り消しできません。よろしいですか？"
        isLoading={isLoading}
      />

      <Dialog
        open={reservationChangeDialogIsOpen}
        onClose={toggleReservationChangeDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>予約時間の変更</DialogTitle>
        <DialogContent>
          <Box sx={{ padding: 3 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.number}>
                  <StepLabel optional={activeStep > step.number ? step.optional : ''}>
                    <Typography>{step.label}</Typography>
                  </StepLabel>
                  <StepContent TransitionProps={{ unmountOnExit: false }}>
                    {step.content}
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <LoadingButton
                          loading={loading}
                          variant="contained"
                          onClick={
                            index === steps.length - 1
                              ? handleSubmit(onSubmit)
                              : handleNext
                          }
                          sx={{ mt: 1, mr: 1 }}
                          disabled={
                            !!(loading || (activeStep === 2 && mobilityList.length === 0))
                          }
                        >
                          {index === steps.length - 1 ? '予約変更' : '次へ'}
                        </LoadingButton>
                        <Button
                          disabled={index === 0 || loading}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          戻る
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogContent>

        <DialogActions sx={{ marginBottom: 1, marginX: 1 }}>
          <Stack direction="row" spacing={2}>
            <Button color="secondary" onClick={toggleReservationChangeDialog}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type alignedTextProps = {
  title: string
  text: string
}
export const AlignedText = (props: alignedTextProps) => {
  return (
    <Typography variant="h6">
      <span style={{ display: 'inline-block', width: '6em' }}>{props.title}</span>:{' '}
      {props.text}
    </Typography>
  )
}

export default ReserveConfirmation
